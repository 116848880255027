import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function ClientModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(null);

  const doCreate = async () => {
    if (!client.client_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "고객명을 입력하세요.",
      });
      return;
    }

    if (!client.phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "연락처를 입력하세요.",
      });
      return;
    }

    if (!client.email) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "이메일을 입력하세요.",
      });
      return;
    }

    if (!client.address) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "주소를 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "고객 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/client", client);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "고객이 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "고객 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!client.client_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "고객명을 입력하세요.",
      });
      return;
    }

    if (!client.phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "연락처를 입력하세요.",
      });
      return;
    }

    if (!client.email) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "이메일을 입력하세요.",
      });
      return;
    }

    if (!client.address) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "주소를 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "고객 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/client/${client.client_id}`, {
          client: client.client,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "고객이 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "고객 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    } else {
      setClient({
        client_type: "개인",
        client_name: "",
        phone_number: "",
        email: "",
        address: "",
        notes: "",
      });
    }
    btnRef.current.click();
  }, [props.client]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#clientModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="clientModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">고객</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {client && (
              <div className="modal-body">
                {/* select - 개인, 법인, 기관 */}
                <div className="mb-3">
                  <label htmlFor="client" className="form-label required">
                    고객 구분
                  </label>
                  <select
                    className="form-select"
                    id="client"
                    value={client.client_type}
                    onChange={(e) => {
                      setClient({
                        ...client,
                        client_type: e.target.value,
                      });
                    }}
                  >
                    <option value="개인">개인</option>
                    <option value="법인">법인</option>
                    <option value="기관">기관</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="client_name" className="form-label required">
                    고객명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="client_name"
                    value={client.client_name}
                    onChange={(e) => {
                      setClient({
                        ...client,
                        client_name: e.target.value,
                      });
                    }}
                    placeholder="고객명을 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone_number" className="form-label required">
                    연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone_number"
                    value={client.phone_number}
                    onChange={(e) => {
                      setClient({
                        ...client,
                        phone_number: e.target.value,
                      });
                    }}
                    placeholder="연락처를 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    이메일
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={client.email}
                    onChange={(e) => {
                      setClient({
                        ...client,
                        email: e.target.value,
                      });
                    }}
                    placeholder="이메일을 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="client_address" className="form-label">
                    주소
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="client_address"
                    value={client.address}
                    onChange={(e) => {
                      setClient({
                        ...client,
                        address: e.target.value,
                      });
                    }}
                    placeholder="주소를 입력하세요."
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="note" className="form-label">
                    비고
                  </label>
                  <textarea
                    className="form-control"
                    id="note"
                    value={client.notes}
                    onChange={(e) => {
                      setClient({
                        ...client,
                        notes: e.target.value,
                      });
                    }}
                    placeholder="비고를 입력하세요."
                  ></textarea>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (client && client.client_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {client && client.client_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientModal;
