import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
// import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function Contact() {
  const navigate = useNavigate();
  // const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const sendEmail = async () => {
    if (
      contact.name === "" ||
      contact.email === "" ||
      contact.phone === "" ||
      contact.message === ""
    ) {
      Swal.fire("Error", "모든 필드를 입력해주세요.", "error");
      return;
    }
    setLoading(true);
    const res = await $post("/api/contact/email", contact);
    setLoading(false);
    if (res.status === 200) {
      Swal.fire(
        "Success",
        "문의가 접수되었습니다. 빠른 시일내에 답변드리겠습니다.",
        "success"
      );
    } else {
      Swal.fire("Error", "문의 접수에 실패했습니다.", "error");
    }
  };
  return (
    <section className="container pt-3 mt-2">
      <div className="row">
        {/* Contact links */}
        <div className="col-xl-4 col-lg-5 pb-4 pb-sm-5 mt-5 mb-2 mb-sm-0">
          <div className="pe-lg-4 pe-xl-0">
            <div className="d-flex align-items-start pb-3 mb-sm-1 mb-md-3 mt-5">
              <div className="bg-light text-primary rounded-circle flex-shrink-0 fs-3 lh-1 p-3">
                <i className="bx bx-envelope" />
              </div>
              <div className="ps-3 ps-sm-4">
                <h2 className="h4 pb-1 mb-2">
                  건축주와 함께 나만의 인생주택을 만들어갑니다.
                </h2>
                <p className="mb-2">
                  정직한 주택 건축을 약속합니다. 전국 최초로 도입한 '100% 건축주
                  직영 시스템'으로 설계, 디자인부터 시공에 이르기까지 모든
                  과정을 건축주가 직접 참여합니다.
                </p>
                <p className="mb-2">
                  내인생에 한번 뿐인 건축을 준비하는 예비 건축주님들에게
                  인생주택이 든든한 동반자가 되겠습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Contact form */}
        <div className="col-xl-6 col-lg-7 offset-xl-2">
          <div className="card border-light shadow-lg py-3 p-sm-4 p-md-5">
            <div className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block" />
            <div className="card-body position-relative zindex-2">
              <h2 className="card-title pb-3 mb-4">건축 상담 문의하기</h2>
              <div className="row g-4 needs-validation" noValidate="">
                <div className="col-12">
                  <label htmlFor="fn" className="form-label fs-base">
                    이름
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="fn"
                    required=""
                    value={contact.name}
                    onChange={(e) =>
                      setContact({ ...contact, name: e.target.value })
                    }
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="email" className="form-label fs-base">
                    이메일
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    id="email"
                    required=""
                    value={contact.email}
                    onChange={(e) =>
                      setContact({ ...contact, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="email" className="form-label fs-base">
                    연락처
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="text"
                    required=""
                    value={contact.phone}
                    onChange={(e) =>
                      setContact({ ...contact, phone: e.target.value })
                    }
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="email" className="form-label fs-base">
                    문의내용
                  </label>
                  <textarea
                    className="form-control form-control-lg"
                    id="text"
                    rows={7}
                    required=""
                    value={contact.message}
                    onChange={(e) =>
                      setContact({ ...contact, message: e.target.value })
                    }
                  />
                </div>

                <div className="col-12 pt-2 pt-sm-3">
                  <button
                    type="button"
                    className="btn btn-lg btn-primary w-100 w-sm-auto"
                    onClick={sendEmail}
                  >
                    <i className="fa-solid fa-paper-plane me-2" />
                    보내기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
