import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import MasterCostSidebar from "../component/MasterCostSidebar";
import CostSubcategoryModal from "../component/CostSubcategoryModal";

function CostSubcategory() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [selectedCostCategoryId, setSelectedCostCategoryId] = useState(0);
  // const [searchText, setSearchText] = useState("");
  // const [pageLimit, setPageLimit] = useState(10);
  // const [pageNo, setPageNo] = useState(1);
  // const [totalPage, setTotalPage] = useState(1);
  // const [pageRange, setPageRange] = useState([]);
  // const [lastPage, setLastPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [activeCostCategory, setActiveCostCategory] = useState(null);
  const [activeCostSubcategory, setActiveCostSubcategory] = useState(null);
  const [showSubModal, setShowSubModal] = useState(false);

  const deleteSubcategory = async (categoryId) => {
    Swal.fire({
      title: "삭제 확인",
      text: "정말 삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(
          `/api/masterdata/cost-subcategory/${categoryId}`
        );
        if (res.status === 200) {
          if (res.data.status === 400) {
            Swal.fire("Error", res.data.message, "error");
            return;
          } else {
            getCostSubcategoryList();
          }
        } else {
          Swal.fire("Error", "삭제에 실패했습니다.", "error");
        }
      }
    });
  };

  const getCostCategoryList = async () => {
    const res = await $get(`/api/masterdata/cost-category/all`);
    if (res.status === 200) {
      setCategoryList(res.data);
      setSelectedCostCategoryId(res.data[0].cost_category_id);
    }
  };

  const getCostSubcategoryList = async () => {
    const res = await $get(
      `/api/masterdata/cost-subcategory/${selectedCostCategoryId}`
    );
    if (res.status === 200) {
      setSubcategoryList(res.data);
    }
  };

  useEffect(() => {
    if (selectedCostCategoryId === 0) {
      return;
    }
    getCostSubcategoryList();
  }, [selectedCostCategoryId]);

  useEffect(() => {
    getCostCategoryList();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <MasterCostSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">하위 비용 항목 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <select
                className="form-select"
                value={selectedCostCategoryId}
                onChange={(e) => {
                  setSelectedCostCategoryId(e.target.value);
                }}
              >
                {categoryList.map((category, index) => (
                  <option key={index} value={category.cost_category_id}>
                    {category.cost_category_name}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  const category = categoryList.find(
                    (category) =>
                      category.cost_category_id ===
                      parseInt(selectedCostCategoryId)
                  );
                  setActiveCostCategory(category);
                  setActiveCostSubcategory(null);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                비용 항목 분류 추가
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-hover align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">비용 항목 분류</th>
                    {/* <th className="border-top-0">등록인</th> */}
                    <th className="border-top-0">등록일</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {subcategoryList.map((category, index) => (
                    <tr key={index}>
                      <td>{category.cost_subcategory_name}</td>
                      {/* <td>{category.user}</td> */}
                      <td>{$dateTimezone(category.create_datetime)}</td>
                      <td>
                        {/* 수정 */}
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary me-2"
                          onClick={() => {
                            setActiveCostSubcategory(category);
                            setShowModal(true);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        {/* 삭제 */}
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => {
                            deleteSubcategory(category.cost_subcategory_id);
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <CostSubcategoryModal
          onClose={() => {
            setShowModal(false);
          }}
          onList={() => {
            getCostSubcategoryList();
            setActiveCostSubcategory(null);
            setShowModal(false);
          }}
          costCategory={activeCostCategory}
          costSubcategory={activeCostSubcategory}
        />
      )}
    </section>
  );
}

export default CostSubcategory;
