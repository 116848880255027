import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function ContactModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const sendEmail = async () => {
    if (
      contact.name === "" ||
      contact.email === "" ||
      contact.phone === "" ||
      contact.message === ""
    ) {
      Swal.fire("Error", "모든 필드를 입력해주세요.", "error");
      return;
    }
    setLoading(true);
    const res = await $post("/api/contact/email", {
      ...contact,
      message: props.quoteNo
        ? `<br>[견적 번호: ${props.quoteNo}]<br>${contact.message}`
        : contact.message,
    });
    setLoading(false);
    if (res.status === 200) {
      Swal.fire(
        "Success",
        "문의가 접수되었습니다. 빠른 시일내에 답변드리겠습니다.",
        "success"
      );
      props.onClose();
      btnCloseRef.current.click();
    } else {
      Swal.fire("Error", "문의 접수에 실패했습니다.", "error");
    }
  };

  useEffect(() => {
    if (props.user) {
      setContact({
        ...contact,
        name: props.user.name,
        email: props.user.email,
        phone: props.user.phone,
      });
      btnRef.current.click();
    }
  }, [props.user]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#userModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="userModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">건축 상담 문의하기</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row g-4 needs-validation" noValidate="">
                <div className="col-12">
                  <label htmlFor="fn" className="form-label fs-base">
                    이름
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="fn"
                    required=""
                    value={contact.name}
                    onChange={(e) =>
                      setContact({ ...contact, name: e.target.value })
                    }
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="email" className="form-label fs-base">
                    이메일
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    id="email"
                    required=""
                    value={contact.email}
                    onChange={(e) =>
                      setContact({ ...contact, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="email" className="form-label fs-base">
                    연락처
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="text"
                    required=""
                    value={contact.phone}
                    onChange={(e) =>
                      setContact({ ...contact, phone: e.target.value })
                    }
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="email" className="form-label fs-base">
                    문의내용
                  </label>
                  <textarea
                    className="form-control form-control-lg"
                    id="text"
                    rows={7}
                    required=""
                    value={contact.message}
                    onChange={(e) =>
                      setContact({ ...contact, message: e.target.value })
                    }
                  />
                </div>

                <div className="col-12 pt-2 pt-sm-3">
                  <button
                    type="button"
                    className="btn btn-lg btn-primary w-100 w-sm-auto"
                    onClick={sendEmail}
                  >
                    <i className="fa-solid fa-paper-plane me-2" />
                    보내기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactModal;
