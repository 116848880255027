import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $upload,
  $put,
  $download,
  $convertNumberFormat,
  $toDate,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";

function ProjectInfo() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isInfoEditMode, setIsInfoEditMode] = useState(false);
  const [isClientEditMode, setIsClientEditMode] = useState(false);
  const [isHouseEditMode, setIsHouseEditMode] = useState(false);
  const [houseStyleList, setHouseStyleList] = useState([]);
  const [houseTypeList, setHouseTypeList] = useState([]);
  const [houseSiteList, setHouseSiteList] = useState([]);
  const [houseOptionList, setHouseOptionList] = useState([]);
  const [houseFuncList, setHouseFuncList] = useState([]);
  const [houseExteriorList, setHouseExteriorList] = useState([]);
  const [houseRoofList, setHouseRooftList] = useState([]);

  const [project, setProject] = useState({
    project_name: "",
    project_address: "",
    project_budget: "",
    estimated_start_date: "",
    estimated_end_date: "",
    notes: "",
    client_id: "",
    house_style_id: "",
    house_type_id: "",
    house_site_id: "",
    house_option_id: "",
    house_func_id: "",
    house_exterior_id: "",
    house_roof_id: "",
    house_floor: "",
    house_site_size: "",
    house_size: "",
    total_floor_area: "",
    building_to_land_ratio: "",
    floor_area_ratio: "",
  });

  const [contactList, setContactList] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const contractFileRef = useRef(null);

  const saveProjectInfo = async () => {
    Swal.fire({
      title: "저장하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "완료",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $put(`/api/project/${project.project_id}`, {
          project_name: project.project_name,
          project_status: project.project_status,
          project_address: project.project_address,
          project_budget: project.project_budget,
          estimated_start_date: $toDate(project.estimated_start_date),
          estimated_end_date: $toDate(project.estimated_end_date),
          actual_cost: project.actual_cost || null,
          actual_start_date: $toDate(project.actual_start_date) || null,
          actual_end_date: $toDate(project.actual_end_date) || null,
          notes: project.notes,
        });
        if (res.status === 200) {
          setIsInfoEditMode(false);
          Swal.fire("Success", "프로젝트 정보가 저장되었습니다.", "success");
        } else {
          Swal.fire("Error", "프로젝트 정보를 저장하지 못했습니다.", "error");
        }
      }
    });
  };

  const saveClientContact = async () => {
    Swal.fire({
      title: "저장하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "완료",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $post(`/api/project/contact/${project.project_id}`, {
          contactList: contactList.map((contact) => [
            project.project_id,
            project.client_id,
            contact.contact_name,
            contact.contact_phone_number,
            contact.notes,
          ]),
        });
        if (res.status === 200) {
          setIsClientEditMode(false);
          Swal.fire("Success", "연락처 정보가 저장되었습니다.", "success");
        } else {
          Swal.fire("Error", "연락처 정보를 저장하지 못했습니다.", "error");
        }
        setLoading(false);
      }
    });
  };

  const saveHouseInfo = async () => {
    // 프로젝트를 저장하고 프로젝트 생성 상태를 완료로 변경하시겠습니까?
    Swal.fire({
      title: "저장하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "완료",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $put(`/api/project/${project.project_id}`, {
          house_style_id: project.house_style_id,
          house_type_id: project.house_type_id,
          house_site_id: project.house_site_id,
          house_option_id: project.house_option_id,
          house_func_id: project.house_func_id,
          house_exterior_id: project.house_exterior_id,
          house_roof_id: project.house_roof_id,
          house_floor: project.house_floor,
          house_site_size: project.house_site_size,
          house_size: project.house_size,
          total_floor_area: project.total_floor_area,
          building_to_land_ratio: project.building_to_land_ratio,
          floor_area_ratio: project.floor_area_ratio,
        });
        if (res.status === 200) {
          setIsHouseEditMode(false);
          Swal.fire("Success", "저장되었습니다.", "success");
        } else {
          Swal.fire("Error", "저장에 실패했습니다.", "error");
        }
        setLoading(false);
      }
    });
  };

  const getProject = async (projectId) => {
    setLoading(true);
    const res = await $get(`/api/project/${projectId}`);
    if (res.status === 200) {
      setProject(res.data);
      if (res.data.client_id) {
        const res2 = await $get(`/api/client/${res.data.client_id}`);
        if (res2.status === 200) {
          setClientInfo(res2.data);
          const res3 = await $get(
            `/api/project/contact/${projectId}/${res.data.client_id}`
          );
          if (res3.status === 200) {
            setContactList(res3.data);
          }
        }
      }
    }
    setLoading(false);
  };

  const getHouseStyleList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-style");
    if (res.status === 200) {
      setHouseStyleList(res.data);
    }
    setLoading(false);
  };

  const getHouseTypeList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-type");
    if (res.status === 200) {
      setHouseTypeList(res.data);
    }
    setLoading(false);
  };

  const getHouseSiteList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-site");
    if (res.status === 200) {
      setHouseSiteList(res.data);
    }
    setLoading(false);
  };

  const getHouseOptionList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-option");
    if (res.status === 200) {
      setHouseOptionList(res.data);
    }
    setLoading(false);
  };

  const getHouseFuncList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-func");
    if (res.status === 200) {
      setHouseFuncList(res.data);
    }
    setLoading(false);
  };

  const getHouseExteriorList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-exterior");
    if (res.status === 200) {
      setHouseExteriorList(res.data);
    }
    setLoading(false);
  };

  const getRoofingMaterialList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-roof");
    if (res.status === 200) {
      setHouseRooftList(res.data);
    }
    setLoading(false);
  };

  const downloadFile = async (filePath, fileOriginalName) => {
    const fileName = filePath.split("/").pop();
    const res = await $download(`/api/document/file/${fileName}`);
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileOriginalName);
      document.body.appendChild(link);
      link.click();
    }
  };

  const uploadContractFile = async (contractFile) => {
    const res = await $upload("/api/project/contract", contractFile, {
      project_id: projectId,
    });

    if (res.status === 200) {
      Swal.fire("Success", "계약서가 업로드 되었습니다.", "success");
      getProject(projectId);
    }
  };

  const deleteContractFile = async () => {
    Swal.fire({
      title: "삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $delete(`/api/project/contract/${projectId}`);
        if (res.status === 200) {
          Swal.fire("Success", "계약서가 삭제되었습니다.", "success");
          getProject(projectId);
        }
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getHouseStyleList();
        getHouseTypeList();
        getHouseSiteList();
        getHouseOptionList();
        getHouseFuncList();
        getHouseExteriorList();
        getRoofingMaterialList();

        getProject(projectId);
      }
    });
  }, [projectId]);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-5">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          {project && project.project_id && (
            <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
              <h1 className="h2 pt-xl-1 pb-3">프로젝트 정보</h1>
              {/* Basic info */}
              <h2 className="h5 text-primary mb-4">기본 정보</h2>
              <div
                className="needs-validation border-bottom pb-3 pb-lg-4"
                noValidate=""
              >
                <div className="row pb-2">
                  <div className="col-sm-12 mb-4">
                    <label
                      htmlFor="project-name"
                      className="form-label fs-base"
                    >
                      프로젝트명
                    </label>
                    <input
                      type="text"
                      id="project-name"
                      className="form-control form-control-lg"
                      value={project.project_name}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          project_name: e.target.value,
                        });
                      }}
                      disabled={!isInfoEditMode}
                    />
                  </div>
                  <div className="col-sm-12 mb-4">
                    <label
                      htmlFor="project-name"
                      className="form-label fs-base"
                    >
                      프로젝트 상태
                    </label>
                    {/* select - 진행중,  준공완료, 최종승인, 등록완료*/}
                    <select
                      id="project-status"
                      className="form-select form-select-lg"
                      value={project.project_status}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          project_status: e.target.value,
                        });
                      }}
                      disabled={!isInfoEditMode}
                    >
                      <option value="등록완료">등록완료</option>
                      <option value="진행중">진행중</option>
                      <option value="준공완료">준공완료</option>
                      <option value="최종승인">최종승인</option>
                    </select>
                  </div>
                  {/* {project.project_status === "준공완료" && (
                    <div className="col-sm-12 mb-4 d-grid">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          // 최종 승인 요청
                        }}
                      >
                        최종 승인 요청
                      </button>
                    </div>
                  )} */}
                  <div className="col-sm-12 mb-4">
                    <label
                      htmlFor="project-address"
                      className="form-label fs-base"
                    >
                      대지위치
                    </label>
                    <input
                      type="text"
                      id="project-address"
                      className="form-control form-control-lg"
                      value={project.project_address}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          project_address: e.target.value,
                        });
                      }}
                      disabled={!isInfoEditMode}
                    />
                  </div>

                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="project-budget"
                      className="form-label fs-base"
                    >
                      전체 예산(원)
                    </label>
                    <input
                      type="text"
                      id="project-budget"
                      className="form-control form-control-lg"
                      value={$convertNumberFormat(project.project_budget)}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          project_budget: $convertNumberFormat(e.target.value),
                        });
                      }}
                      disabled={!isInfoEditMode}
                    />
                  </div>
                  {/* <div className="col-sm-6 mb-4">
                  <label
                    htmlFor="estimated-cost"
                    className="form-label fs-base"
                  >
                    추정 총 비용
                  </label>
                  <input
                    type="text"
                    id="estimated-cost"
                    className="form-control form-control-lg"
                    required=""
                  />
                </div> */}
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="actual-cost" className="form-label fs-base">
                      실제 총 비용
                    </label>
                    <input
                      type="text"
                      id="actual-cost"
                      className="form-control form-control-lg"
                      value={project.actual_cost}
                      onChange={(e) => {
                        setProject({ ...project, actual_cost: e.target.value });
                      }}
                      disabled={!isInfoEditMode}
                    />
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="project-duration"
                      className="form-label fs-base"
                    >
                      공사기간
                    </label>
                    {/* from date to date */}
                    <div className="input-group">
                      <input
                        type="date"
                        id="estimated-start-date"
                        className="form-control form-control-lg"
                        value={$toDate(project.estimated_start_date)}
                        onClick={(e) => {
                          setProject({
                            ...project,
                            estimated_start_date: e.target.value,
                          });
                        }}
                        disabled={!isInfoEditMode}
                      />
                      <span className="input-group-text">~</span>
                      <input
                        type="date"
                        id="estimated-end-date"
                        className="form-control form-control-lg"
                        value={$toDate(project.estimated_end_date)}
                        onClick={(e) => {
                          setProject({
                            ...project,
                            estimated_end_date: e.target.value,
                          });
                        }}
                        disabled={!isInfoEditMode}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="project-duration"
                      className="form-label fs-base"
                    >
                      실제 공사기간
                    </label>
                    {/* from date to date */}
                    <div className="input-group">
                      <input
                        type="date"
                        id="actual-start-date"
                        className="form-control form-control-lg"
                        value={$toDate(project.actual_start_date)}
                        onChange={(e) => {
                          setProject({
                            ...project,
                            actual_start_date: e.target.value,
                          });
                        }}
                        disabled={!isInfoEditMode}
                      />
                      <span className="input-group-text">~</span>
                      <input
                        type="date"
                        id="actual-end-date"
                        className="form-control form-control-lg"
                        value={$toDate(project.actual_end_date)}
                        onChange={(e) => {
                          setProject({
                            ...project,
                            actual_end_date: e.target.value,
                          });
                        }}
                        disabled={!isInfoEditMode}
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-4">
                    <label htmlFor="bio" className="form-label fs-base">
                      건축주 요구사항{" "}
                      <small className="text-muted">(optional)</small>
                    </label>
                    <textarea
                      id="bio"
                      className="form-control form-control-lg"
                      rows={4}
                      placeholder="고객 요구사항을 입력하세요."
                      value={project.notes}
                      onChange={(e) => {
                        setProject({ ...project, notes: e.target.value });
                      }}
                      disabled={!isInfoEditMode}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label htmlFor="bio" className="form-label fs-base">
                      계약서
                    </label>
                    <div className="d-flex align-items-center gap-2">
                      {project.contract_file_name ? (
                        <ul className="list-unstyled">
                          <li>
                            <a
                              onClick={() => {
                                downloadFile(
                                  project.contract_file_path,
                                  project.contract_file_name
                                );
                              }}
                            >
                              <i className="fa fa-solid fa-file-pdf me-2"></i>
                              {project.contract_file_name}
                            </a>
                            {/* 삭제 */}
                            <button
                              type="button"
                              className="btn btn-sm btn-danger ms-2"
                              onClick={() => {
                                deleteContractFile();
                              }}
                            >
                              <i className="fa fa-solid fa-trash"></i>
                            </button>
                          </li>
                        </ul>
                      ) : (
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            contractFileRef.current.click();
                          }}
                        >
                          <i className="fa fa-solid fa-file-pdf me-2"></i>
                          계약서 업로드
                        </button>
                      )}
                      <input
                        className="form-control"
                        type="file"
                        ref={contractFileRef}
                        style={{ display: "none" }}
                        accept=".pdf"
                        onChange={(e) => {
                          uploadContractFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  {isInfoEditMode ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          saveProjectInfo();
                        }}
                      >
                        저장
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setIsInfoEditMode(false);
                          getProject(projectId);
                        }}
                      >
                        취소
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setIsInfoEditMode(true);
                      }}
                    >
                      수정
                    </button>
                  )}
                </div>
              </div>
              {/* Address */}
              <h2 className="h5 text-primary pt-1 pt-lg-3 my-4">고객 정보</h2>
              <div
                className="needs-validation border-bottom pb-2 pb-lg-4"
                noValidate=""
              >
                <div className="row pb-2">
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="customer-name"
                      className="form-label fs-base"
                    >
                      이름
                    </label>
                    <input
                      type="text"
                      id="customer-name"
                      className="form-control form-control-lg"
                      value={clientInfo?.client_name}
                      disabled
                    />
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="customer-email"
                      className="form-label fs-base"
                    >
                      이메일
                    </label>
                    <input
                      type="email"
                      id="customer-email"
                      className="form-control form-control-lg"
                      value={clientInfo?.email}
                      disabled
                    />
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="customer-phone"
                      className="form-label fs-base"
                    >
                      연락처
                    </label>
                    <input
                      type="text"
                      id="customer-phone"
                      className="form-control form-control-lg"
                      value={clientInfo?.phone_number}
                      disabled
                    />
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="customer-phone"
                      className="form-label fs-base"
                    >
                      고객 유형
                    </label>
                    <input
                      type="text"
                      id="customer-phone"
                      className="form-control form-control-lg"
                      value={clientInfo?.client_type}
                      disabled
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <label className="form-label fs-base">추가 연락처</label>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        disabled={!isClientEditMode}
                        onClick={() => {
                          setContactList([
                            ...contactList,
                            {
                              contact_name: "",
                              contact_phone_number: "",
                              notes: "",
                            },
                          ]);
                        }}
                      >
                        <i className="fa fa-solid fa-plus me-2"></i>
                        추가
                      </button>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>이름</th>
                            <th>연락처</th>
                            <th>비고</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {contactList.map((contact, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={contact.contact_name}
                                  onChange={(e) => {
                                    const newContactList = [...contactList];
                                    newContactList[index].contact_name =
                                      e.target.value;
                                    setContactList(newContactList);
                                  }}
                                  disabled={!isClientEditMode}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={contact.contact_phone_number}
                                  onChange={(e) => {
                                    const newContactList = [...contactList];
                                    newContactList[index].contact_phone_number =
                                      e.target.value;
                                    setContactList(newContactList);
                                  }}
                                  disabled={!isClientEditMode}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={contact.notes}
                                  onChange={(e) => {
                                    const newContactList = [...contactList];
                                    newContactList[index].notes =
                                      e.target.value;
                                    setContactList(newContactList);
                                  }}
                                  disabled={!isClientEditMode}
                                />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  disabled={!isClientEditMode}
                                >
                                  <i className="fa fa-solid fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="d-flex mb-3">
                  {isClientEditMode ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          saveClientContact();
                        }}
                      >
                        저장
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setIsClientEditMode(false);
                        }}
                      >
                        취소
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setIsClientEditMode(true);
                      }}
                    >
                      수정
                    </button>
                  )}
                </div>
              </div>
              <h2 className="h5 text-primary pt-1 pt-lg-3 my-4">건축 정보</h2>
              <div
                className="needs-validation border-bottom pb-2 pb-lg-4"
                noValidate=""
              >
                <div className="row pb-2">
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="house-style" className="form-label fs-base">
                      건축 스타일
                    </label>
                    <select
                      id="house-style"
                      className="form-select form-select-lg"
                      value={project.house_style_id}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          house_style_id: e.target.value,
                        });
                      }}
                      disabled={!isHouseEditMode}
                    >
                      <option value="">선택하세요</option>
                      {houseStyleList.map((style, index) => (
                        <option
                          key={style.house_style_id}
                          value={style.house_style_id}
                        >
                          {style.house_style}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="house-type" className="form-label fs-base">
                      주택 형태
                    </label>
                    <select
                      id="house-type"
                      className="form-select form-select-lg"
                      value={project.house_type_id}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          house_type_id: e.target.value,
                        });
                      }}
                      disabled={!isHouseEditMode}
                    >
                      <option value="">선택하세요</option>
                      {houseTypeList.map((type, index) => (
                        <option
                          key={type.house_type_id}
                          value={type.house_type_id}
                        >
                          {type.house_type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="house-site" className="form-label fs-base">
                      부지 유형
                    </label>
                    <select
                      id="house-site"
                      className="form-select form-select-lg"
                      value={project.house_site_id}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          house_site_id: e.target.value,
                        });
                      }}
                      disabled={!isHouseEditMode}
                    >
                      <option value="">선택하세요</option>
                      {houseSiteList.map((site, index) => (
                        <option
                          key={site.house_site_id}
                          value={site.house_site_id}
                        >
                          {site.house_site}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="house-option"
                      className="form-label fs-base"
                    >
                      추가 옵션
                    </label>
                    <select
                      id="house-option"
                      className="form-select form-select-lg"
                      value={project.house_option_id}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          house_option_id: e.target.value,
                        });
                      }}
                      disabled={!isHouseEditMode}
                    >
                      <option value="">선택하세요</option>
                      {houseOptionList.map((option, index) => (
                        <option
                          key={option.house_option_id}
                          value={option.house_option_id}
                        >
                          {option.house_option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="house-func" className="form-label fs-base">
                      특화된 기능
                    </label>
                    <select
                      id="house-func"
                      className="form-select form-select-lg"
                      value={project.house_func_id}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          house_func_id: e.target.value,
                        });
                      }}
                      disabled={!isHouseEditMode}
                    >
                      <option value="">선택하세요</option>
                      {houseFuncList.map((func, index) => (
                        <option
                          key={func.house_func_id}
                          value={func.house_func_id}
                        >
                          {func.house_func}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="house-exterior"
                      className="form-label fs-base"
                    >
                      외관 재질
                    </label>
                    <select
                      id="house-exterior"
                      className="form-select form-select-lg"
                      value={project.house_exterior_id}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          house_exterior_id: e.target.value,
                        });
                      }}
                      disabled={!isHouseEditMode}
                    >
                      <option value="">선택하세요</option>
                      {houseExteriorList.map((exterior, index) => (
                        <option
                          key={exterior.house_exterior_id}
                          value={exterior.house_exterior_id}
                        >
                          {exterior.house_exterior}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* 지붕재, 창호재, 단열재, 내외부 마감재 */}
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="roof-material"
                      className="form-label fs-base"
                    >
                      지붕재
                    </label>
                    <select
                      id="roof-material"
                      className="form-select form-select-lg"
                      value={project.house_roof_id}
                      onChange={(e) => {
                        setProject({
                          ...project,
                          house_roof_id: e.target.value,
                        });
                      }}
                      disabled={!isHouseEditMode}
                    >
                      <option value="">선택하세요</option>
                      {houseRoofList.map((roof, index) => (
                        <option
                          key={roof.house_roof_id}
                          value={roof.house_roof_id}
                        >
                          {roof.house_roof}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="house-floor" className="form-label fs-base">
                      주택 층수
                    </label>
                    <select
                      id="house-floor"
                      className="form-select form-select-lg"
                      value={project.house_floor}
                      onChange={(e) => {
                        setProject({ ...project, house_floor: e.target.value });
                      }}
                      disabled={!isHouseEditMode}
                    >
                      <option value="">선택하세요</option>
                      <option value="1">1층</option>
                      <option value="2">2층</option>
                      <option value="3">3층</option>
                      <option value="4">4층</option>
                      <option value="5">5층</option>
                      <option value="6">6층</option>
                      <option value="7">7층</option>
                      <option value="8">8층</option>
                      <option value="9">9층</option>
                      <option value="10">10층</option>
                    </select>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="house-site-size"
                      className="form-label fs-base"
                    >
                      대지면적
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="house-site-size"
                        className="form-control form-control-lg"
                        value={project.house_site_size}
                        onChange={(e) => {
                          setProject({
                            ...project,
                            house_site_size: e.target.value,
                          });
                        }}
                        disabled={!isHouseEditMode}
                      />
                      <span className="input-group-text">m²</span>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="house-size" className="form-label fs-base">
                      건축면적
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="house-size"
                        className="form-control form-control-lg"
                        value={project.house_size}
                        onChange={(e) => {
                          setProject({
                            ...project,
                            house_size: e.target.value,
                          });
                        }}
                        disabled={!isHouseEditMode}
                      />
                      <span className="input-group-text">m²</span>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="total-floor-area"
                      className="form-label fs-base"
                    >
                      연면적
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="total-floor-area"
                        className="form-control form-control-lg"
                        value={project.total_floor_area}
                        onChange={(e) => {
                          setProject({
                            ...project,
                            total_floor_area: e.target.value,
                          });
                        }}
                        disabled={!isHouseEditMode}
                      />
                      <span className="input-group-text">m²</span>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="building-to-land-ratio"
                      className="form-label fs-base"
                    >
                      건폐율
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="building-to-land-ratio"
                        className="form-control form-control-lg"
                        value={project.building_to_land_ratio}
                        onChange={(e) => {
                          setProject({
                            ...project,
                            building_to_land_ratio: e.target.value,
                          });
                        }}
                        disabled={!isHouseEditMode}
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label
                      htmlFor="floor-area-ratio"
                      className="form-label fs-base"
                    >
                      용적률
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="floor-area-ratio"
                        className="form-control form-control-lg"
                        value={project.floor_area_ratio}
                        onChange={(e) => {
                          setProject({
                            ...project,
                            floor_area_ratio: e.target.value,
                          });
                        }}
                        disabled={!isHouseEditMode}
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  {isHouseEditMode ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          saveHouseInfo();
                        }}
                      >
                        저장
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setIsHouseEditMode(false);
                        }}
                      >
                        취소
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setIsHouseEditMode(true);
                      }}
                    >
                      수정
                    </button>
                  )}
                </div>
              </div>
              {/* Delete account */}
              <h2 className="h5 text-primary pt-1 pt-lg-3 mt-4">
                프로젝트 삭제
              </h2>
              <p>
                프로젝트를 삭제하면 모든 데이터가 삭제되며 복구할 수 없습니다.
              </p>
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  id="delete-account"
                  className="form-check-input"
                />
                <label
                  htmlFor="delete-account"
                  className="form-check-label fs-base"
                >
                  네, 프로젝트를 삭제하겠습니다.
                </label>
              </div>
              <button type="button" className="btn btn-danger">
                삭제
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ProjectInfo;
