import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function DocumentCategoryModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [documentCategory, setDocumentCategory] = useState(null);

  const doCreate = async () => {
    if (!documentCategory.document_category_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "문서 분류를 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "문서 분류 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post(
          "/api/masterdata/document-category",
          documentCategory
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "문서 분류가 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else if (res.status === 400) {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "문서 분류 중복입니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "문서 분류 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!documentCategory.document_category_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "문서 분류를 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "문서 분류 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/masterdata/document-category/${documentCategory.document_category_id}`,
          {
            document_category_name: documentCategory.document_category_name,
          }
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "문서 분류가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else if (res.status === 400) {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "문서 분류 중복입니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "문서 분류 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.documentCategory) {
      setDocumentCategory(props.documentCategory);
    } else {
      setDocumentCategory({
        document_category_name: "",
      });
    }
    btnRef.current.click();
  }, [props.documentCategory]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#documentCategoryModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="documentCategoryModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">문서 분류</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              {documentCategory && (
                <div className="mb-3">
                  <label htmlFor="documentCategory" className="form-label">
                    문서 분류
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="documentCategory"
                    value={documentCategory.document_category_name}
                    onChange={(e) => {
                      setDocumentCategory({
                        ...documentCategory,
                        document_category_name: e.target.value,
                      });
                    }}
                    placeholder="문서 분류을 입력하세요."
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (
                    documentCategory &&
                    documentCategory.document_category_id
                  ) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {documentCategory && documentCategory.document_category_id
                  ? "수정"
                  : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentCategoryModal;
