import { Tooltip as ReactTooltip } from "react-tooltip";

function Tooltip({ children, text }) {
  return (
    <>
      <ReactTooltip
        id="btn-add-tooltip"
        place="bottom"
        content="자재공급업체 추가하기"
      />
      <ReactTooltip id="btn-edit-tooltip" place="bottom" content="수정하기" />
      <ReactTooltip id="btn-delete-tooltip" place="bottom" content="삭제하기" />
      <ReactTooltip id="btn-view-tooltip" place="bottom" content="상세히보기" />
      <ReactTooltip
        id="btn-photo-tooltip"
        place="bottom"
        content="사진업로드"
      />
      <ReactTooltip id="btn-review-tooltip" place="bottom" content="평가작성" />
      <ReactTooltip id="btn-note-tooltip" place="bottom" content="메모작성" />
      <ReactTooltip
        id="btn-total-floor-area-tooltip"
        place="bottom"
        content="연면적은 지상과 지하의 총 면적을 의미합니다. 예를 들어 지상 1층 면적이 100평, 지하 1층 면적이 50평이면 연면적은 150평이 됩니다."
      />
    </>
  );
}

export default Tooltip;
