import React, { useState, useEffect, useRef } from "react";
import { $get, $upload } from "../utils/common";
import Swal from "sweetalert2";

function PortfolioImageUploadModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [portfolioImage, setPortfolioImage] = useState({
    description: "",
  });
  const [portfolioImageFile, setPortfolioImageFile] = useState(null);
  const imageFileRef = useRef(null);

  const uploadPortfolioImage = async () => {
    const res = await $upload(`/api/portfolio/image`, portfolioImageFile, {
      title: portfolioImage.description,
    });

    if (res.status === 200) {
      Swal.fire("Success", "사진이 업로드 되었습니다.", "success");
      props.onList();
      btnCloseRef.current.click();
    }
  };

  useEffect(() => {
    btnRef.current.click();
  }, []);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#portfolioImageUploadModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="portfolioImageUploadModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">이미지 업로드</h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="documentType" className="form-label">
                  이미지 설명
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="documentType"
                  value={portfolioImage.description}
                  onChange={(e) => {
                    setPortfolioImage({
                      ...portfolioImage,
                      description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="documentFile" className="form-label">
                  파일
                </label>
                <button
                  className="btn d-flex btn-outline-secondary"
                  type="button"
                  onClick={() => {
                    imageFileRef.current.click();
                  }}
                >
                  <i className="fa fa-solid fa-upload me-2"></i>
                  파일 선택
                </button>
                <input
                  className="form-control"
                  type="file"
                  ref={imageFileRef}
                  id="documentFile"
                  accept=".jpg,.jpeg,.png,.gif"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setPortfolioImageFile(e.target.files[0]);
                  }}
                />
                <div>
                  {portfolioImageFile !== null && (
                    <span>
                      <i className="fa fa-solid fa-file me-2"></i>{" "}
                      {portfolioImageFile.name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  uploadPortfolioImage();
                }}
              >
                업로드
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioImageUploadModal;
