import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function PartnerReviewModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState(null);
  const [reviewList, setReviewList] = useState([]);

  const getReview = async () => {
    const res = await $get(`/api/partner/review/${props.partner.partner_id}`);
    if (res.status === 200) {
      setReviewList(res.data);
    }
  };

  useEffect(() => {
    if (!props.partner) {
      return;
    }

    getReview();

    // setPartner(props.partner);
    btnRef.current.click();
  }, [props.partner]);
  return (
    props.partner && (
      <div>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "none" }}
          data-bs-toggle="modal"
          data-bs-target="#partnerModal"
          ref={btnRef}
        ></button>
        <div
          className="modal fade"
          role="dialog"
          id="partnerModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  "{props.partner.partner_name}" 협력업체 평가
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={btnCloseRef}
                  onClick={() => {
                    props.onClose();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table table-hover align-middle text-nowrap">
                    <thead>
                      <tr>
                        <th className="border-top-0">프로젝트명</th>
                        <th className="border-top-0">기술력</th>
                        <th className="border-top-0">성실성</th>
                        <th className="border-top-0">책임감</th>
                        <th className="border-top-0">시간약속</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviewList.map((review, index) => (
                        <tr key={index}>
                          <td>{review.project_name}</td>
                          <td>
                            {Array.from({ length: 5 }, (_, i) => (
                              <i
                                key={i}
                                className={
                                  review.technical_score > i
                                    ? "fa-solid fa-star text-danger"
                                    : "fa-regular fa-star"
                                }
                              ></i>
                            ))}
                          </td>
                          <td>
                            {Array.from({ length: 5 }, (_, i) => (
                              <i
                                key={i}
                                className={
                                  review.diligence_score > i
                                    ? "fa-solid fa-star text-danger"
                                    : "fa-regular fa-star"
                                }
                              ></i>
                            ))}
                          </td>

                          <td>
                            {Array.from({ length: 5 }, (_, i) => (
                              <i
                                key={i}
                                className={
                                  review.responsibility_score > i
                                    ? "fa-solid fa-star text-danger"
                                    : "fa-regular fa-star"
                                }
                              ></i>
                            ))}
                          </td>
                          <td>
                            {Array.from({ length: 5 }, (_, i) => (
                              <i
                                key={i}
                                className={
                                  review.punctuality_score > i
                                    ? "fa-solid fa-star text-danger"
                                    : "fa-regular fa-star"
                                }
                              ></i>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default PartnerReviewModal;
