import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import DiaryImageUploadModal from "../component/DiaryImageUploadModal";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { RowsPhotoAlbum, MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import "react-photo-album/masonry.css";
import MessageModal from "../component/MessageModal";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function ProjectDiaryView() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData, getProjectRole } = useAuth();
  const [projectRole, setProjectRole] = useState(null);
  const { projectId, projectDiaryId } = useParams();

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);
  const [slides, setSlides] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [projectDiary, setProjectDiary] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [originMessage, setOriginMessage] = useState(null);

  const handleDeletePhoto = (photo) => {
    Swal.fire({
      title: "사진 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/diary/image/${photo.id}`);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "사진이 삭제되었습니다.",
          });
          getProjectDiaryImages();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "사진 삭제에 실패했습니다.",
          });
        }
      }
    });
  };

  // 이미지 주소에서 이미지 width, height 추출
  const getImgSize = (url) => {
    var img = new Image();
    img.src = url;
    return { width: img.width, height: img.height };
  };

  const getProjectDiaryImages = async () => {
    if (projectId === undefined || projectDiaryId === undefined) return;
    setLoading(true);
    const res = await $get(`/api/diary/images/${projectDiaryId}`);
    if (res.status === 200) {
      setSlides(
        res.data.map((file) => ({
          src: `${serverUrl}/static/images/${file.file_path.split("/").pop()}`,
          title: file.description,
          id: file.project_diary_file_id,
          width: file.image_width,
          height: file.image_height,
          srcSet: breakpoints.map((bp) => ({
            src: `${serverUrl}/static/images/${file.file_path
              .split("/")
              .pop()}`,
            width: bp,
            height: Math.round((file.image_height / file.image_width) * bp),
          })),
        }))
      );
    }
  };

  const deleteProjectDiary = async () => {
    if (projectId === undefined || projectDiaryId === undefined) return;
    Swal.fire({
      title: "작업일지 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/diary/${projectDiaryId}`);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "작업일지가 삭제되었습니다.",
          });
          navigate(`/project-diary/${projectId}`);
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "작업일지 삭제에 실패했습니다.",
          });
        }
      }
    });
  };

  const sendMessage = async (messageOwnerEmail) => {
    if (projectId === undefined || projectDiaryId === undefined) return;
    if (message === "") return;
    const res = await $post(`/api/diary/message`, {
      project_diary_id: projectDiaryId,
      project_id: projectId,
      message: message,
      level: 1,
      // message_owner: messageOwnerEmail,
    });
    if (res.status === 200) {
      setMessage("");
      getProjectDiaryMessages();
    }
  };

  const getProjectDiaryMessages = async () => {
    if (projectId === undefined || projectDiaryId === undefined) return;
    setLoading(true);
    const res = await $get(`/api/diary/messages/${projectDiaryId}`);
    if (res.status === 200) {
      setMessages(res.data);
    }
  };

  const getProjectDiary = async () => {
    if (projectId === undefined || projectDiaryId === undefined) return;
    setLoading(true);
    const res = await $get(`/api/diary/${projectDiaryId}`);
    if (res.status === 200) {
      setProjectDiary(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getProjectDiary();
    getProjectDiaryImages();
    getProjectDiaryMessages();
    getProjectRole(projectId).then((res) => {
      setProjectRole(res);
    });
    // getSlides();
  }, []);

  return (
    <section className="container pt-5">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">작업 일지</h1>
              {/* 확인완료 */}
              {projectRole && projectRole !== "client" ? (
                <div className="d-flex gap-1">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      navigate(`/project-diary-edit`, {
                        state: { projectId, projectDiaryId },
                      });
                    }}
                  >
                    수정
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteProjectDiary()}
                  >
                    삭제
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* Basic info */}
            {projectDiary && (
              <div
                className="needs-validation border-bottom pb-3 pb-lg-4"
                noValidate=""
              >
                <div className="row pb-2">
                  <div className="col-sm-12 mb-4">
                    <label htmlFor="diary-date" className="form-label fs-base">
                      작성일자
                    </label>
                    <input
                      type="date"
                      id="diary-date"
                      className="form-control form-control-lg"
                      value={$toDate(projectDiary.work_date)}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-12 mb-4">
                    <label htmlFor="diary-title" className="form-label fs-base">
                      제목
                    </label>
                    <input
                      type="text"
                      id="diary-title"
                      className="form-control form-control-lg"
                      value={projectDiary.title}
                      readOnly
                    />
                  </div>

                  <div className="col-12 mb-4">
                    <label
                      htmlFor="diary-content"
                      className="form-label fs-base"
                    >
                      작업 내용 및 결과
                    </label>
                    <textarea
                      id="diary-content"
                      className="form-control form-control-lg"
                      rows={6}
                      value={projectDiary.notes}
                      readOnly
                    />
                  </div>
                  {projectDiary?.workers.length > 0 && (
                    <div className="col-sm-12 mb-4">
                      <label
                        htmlFor="diary-people"
                        className="form-label fs-base"
                      >
                        투입 인원
                      </label>

                      <div className="table-responsive">
                        <table className="table table-bordered align-middle mb-0">
                          <thead>
                            <tr>
                              <th className="text-nowrap">이름</th>
                              <th className="text-nowrap">투입 시간</th>
                              <th className="text-nowrap">작업 내용</th>
                            </tr>
                          </thead>
                          <tbody>
                            {projectDiary.workers.map((worker) => (
                              <tr key={worker.worker_id}>
                                <td>{worker.worker_name}</td>
                                <td>{worker.work_hours}</td>
                                <td>{worker.work_notes}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                {(userData && userData.role_level === "company_admin") ||
                  (userData.email === projectDiary?.created_by && (
                    <div className="d-grid mb-2">
                      <button
                        type="button"
                        className="btn btn-dark btn-lg"
                        onClick={() => {
                          setShowImageUploadModal(true);
                        }}
                      >
                        <i className="fa-regular fa-image me-2"></i>
                        사진 추가
                      </button>
                    </div>
                  ))}
                {projectRole === "client" && slides.length > 0 ? (
                  <RowsPhotoAlbum
                    photos={slides}
                    onClick={({ index: current }) => setIndex(current)}
                  />
                ) : (
                  <RowsPhotoAlbum
                    photos={slides}
                    render={{
                      extras: (_, { photo, index }) => (
                        <button
                          className="btn btn-sm btn-danger position-absolute top-0 end-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeletePhoto(photo);
                          }}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      ),
                    }}
                    onClick={({ index: current }) => setIndex(current)}
                  />
                )}

                {slides.length > 0 && (
                  <Lightbox
                    index={index}
                    slides={slides}
                    open={index >= 0}
                    plugins={[
                      Fullscreen,
                      Slideshow,
                      Captions,
                      Video,
                      Zoom,
                      Thumbnails,
                    ]}
                    close={() => setIndex(-1)}
                  />
                )}
              </div>
            )}

            <div className="d-flex flex-column gap-3">
              {/* 메세지 입력창 - textarea */}
              <div className="d-sm-flex w-100 border-0 pt-4">
                <div className="position-relative w-100 me-2 mb-3 mb-sm-0">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    style={{ paddingRight: 85 }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (e.nativeEvent.isComposing) {
                          return;
                        }

                        sendMessage(projectDiary?.created_by);
                      }
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-icon btn-lg d-none d-sm-inline-flex ms-1"
                  aria-label="Send"
                  onClick={() => sendMessage(projectDiary?.created_by)}
                >
                  <i className="fa-regular fa-paper-plane me-2"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg w-100 d-sm-none"
                  onClick={() => sendMessage(projectDiary?.created_by)}
                >
                  <i className="fa-regular fa-paper-plane me-2"></i>
                  Send
                </button>
              </div>

              {messages.map((msg) => (
                <div className="py-4" key={msg.project_diary_message_id}>
                  <div className="d-flex align-items-center justify-content-between pb-2 mb-1">
                    <div className="d-flex align-items-center me-3">
                      <div className="">
                        <h6 className="fw-semibold mb-0">
                          {msg.created_by_name}
                        </h6>
                        <span className="fs-sm text-muted">
                          {$dateTimezone(msg.create_datetime)}
                        </span>
                      </div>
                    </div>
                    <a
                      onClick={() => {
                        setOriginMessage(msg);
                        setShowMessageModal(true);
                      }}
                      className="nav-link fs-sm px-0"
                    >
                      <i className="fa-solid fa-reply me-2"></i>
                      댓글달기
                    </a>
                  </div>
                  <p className="mb-0 pb-2">{msg.message}</p>
                  {/* Comment reply */}
                  {msg.replies.map((reply) => (
                    <div
                      className="position-relative ps-4 mt-4"
                      key={reply.project_diary_message_id}
                    >
                      <span className="position-absolute top-0 start-0 w-1 h-100 bg-primary" />
                      <div className="d-flex align-items-center justify-content-between ps-3 pb-2 mb-1">
                        <div className="d-flex align-items-center me-3">
                          <div className="">
                            <h6 className="fw-semibold mb-0">
                              {reply.created_by_name}
                            </h6>
                            <span className="fs-sm text-muted">
                              {$dateTimezone(reply.create_datetime)}
                            </span>
                          </div>
                        </div>
                        <a
                          onClick={() => {
                            setOriginMessage(reply);
                            setShowMessageModal(true);
                          }}
                          className="nav-link fs-sm px-0"
                        >
                          <i className="fa-solid fa-reply me-2"></i>
                          댓글달기
                        </a>
                      </div>
                      <p className="ps-3 mb-0">{reply.message}</p>
                    </div>
                  ))}
                </div>
              ))}

              {/* <hr className="my-2" /> */}
            </div>
          </div>
        </div>
      </div>
      {showMessageModal && (
        <MessageModal
          originMessage={originMessage}
          onClose={() => setShowMessageModal(false)}
          onList={() => {
            getProjectDiaryMessages();
            setShowMessageModal(false);
            setOriginMessage(null);
          }}
        />
      )}

      {showImageUploadModal && (
        <DiaryImageUploadModal
          projectId={projectId}
          projectDiaryId={projectDiaryId}
          onClose={() => setShowImageUploadModal(false)}
          onList={() => {
            getProjectDiary();
            getProjectDiaryImages();
            setShowImageUploadModal(false);
          }}
        />
      )}
    </section>
  );
}

export default ProjectDiaryView;
