import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { $get } from "../utils/common";
function Footer() {
  const navigate = useNavigate();
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");

  const getSystemInfo = async () => {
    const res = await $get("/api/system-info");
    if (res.status === 200) {
      setContactPhone(res.data.contact_phone);
      setContactEmail(res.data.contact_email);
      setWebsite(res.data.website);
      setPhone(res.data.phone);
      setEmail(res.data.email);
      setBusinessAddress(res.data.business_address);
    }
  };

  useEffect(() => {
    getSystemInfo();
  }, []);

  return (
    <footer className="footer position-relative bg-dark pt-5 pb-4 pb-lg-5">
      <span className="position-absolute top-0 start-0 d-dark-mode-block d-none w-100 h-100 bg-secondary opacity-75" />
      <div className="position-relative container pt-lg-4" data-bs-theme="dark">
        <div className="row pb-5">
          <div className="col-lg-4 col-md-6">
            <a
              href="/"
              className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4"
            >
              {/* <img
                src="./assets/images/logo-windyflo-white.svg"
                className="navbar-brand-logo"
                alt="Windyflo"
              /> */}
              인생홀딩스
            </a>
            <p className="fs-sm text-light opacity-70 pb-lg-3 mb-1">
              건축주와 함께 나만의 인생주택을 만들어갑니다. 건축주에게는 투명한
              정보와 신뢰를, 건축가에게는 공정한 경쟁과 기회를 제공합니다.
            </p>
            <p className="fs-sm text-light opacity-70 mb-0">
              주식회사 인생홀딩스 (대표: 박찬근, 최원남)
            </p>
            <p className="fs-sm text-light opacity-70 mb-0">
              사업자등록번호: 607-87-03212
            </p>
            <p className="fs-sm text-light opacity-70 mb-0">
              대표전화: {phone}
            </p>
            <p className="fs-sm text-light opacity-70 mb-1">
              주소: {businessAddress}
            </p>
            {/* <form className="needs-validation" noValidate="">
              <label htmlFor="subscr-email" className="form-label">
                Subscribe to our newsletter
              </label>
              <div className="input-group">
                <input
                  type="email"
                  id="subscr-email"
                  className="form-control rounded-start ps-5"
                  placeholder="Your email"
                  required=""
                />
                <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5" />
                <div className="invalid-tooltip position-absolute top-100 start-0">
                  Please provide a valid email address.
                </div>
                <button type="submit" className="btn btn-primary">
                  Subscribe
                </button>
              </div>
            </form> */}
          </div>
          <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
            <div id="footer-links" className="row">
              <div className="col-lg-4">
                <h6 className="mb-2">
                  <a
                    href="#useful-links"
                    className="d-block text-dark dropdown-toggle d-lg-none py-2"
                    data-bs-toggle="collapse"
                  >
                    Useful Links
                  </a>
                </h6>
                <div
                  id="useful-links"
                  className="collapse d-lg-block"
                  data-bs-parent="#footer-links"
                >
                  <ul className="nav flex-column pb-lg-1 mb-lg-3">
                    <li className="nav-item">
                      <a
                        onClick={() => {
                          navigate("/");
                        }}
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        홈
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        onClick={() => {
                          navigate("/portfolio");
                        }}
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        포트폴리오
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        onClick={() => {
                          navigate("/ai-quote");
                        }}
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        AI 견적받기
                      </a>
                    </li>
                  </ul>
                  <ul className="nav flex-column mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        href="https://blueengine.notion.site/113e3862c5e6803da37cdaff9b716437"
                        target="_blank"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        이용약관
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://blueengine.notion.site/113e3862c5e68030b13df3710a39309b"
                        target="_blank"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        개인정보처리방침
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3">
                <h6 className="mb-2">
                  <a
                    href="#social-links"
                    className="d-block text-dark dropdown-toggle d-lg-none py-2"
                    data-bs-toggle="collapse"
                  >
                    Socials
                  </a>
                </h6>
                <div
                  id="social-links"
                  className="collapse d-lg-block"
                  data-bs-parent="#footer-links"
                >
                  <ul className="nav flex-column mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        페이스북
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        링크드인
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        인스타그램
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        유튜브
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                <h6 className="mb-2">Contact Us</h6>
                <a href={`mailto:${email}`} className="fw-medium">
                  {email}
                </a>
              </div>
            </div>
          </div>
        </div>
        <p className="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
          <span className="text-light">
            © Copyright{" "}
            <a
              className="nav-link d-inline-block text-white p-0"
              href="#"
              target="_blank"
              rel="noopener"
            >
              인생홀딩스.
            </a>{" "}
            All rights reserved.{" "}
          </span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
