import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import PlaceHoderCard from "../component/PlacehoderCard";
import MasterArchitectureSidebar from "../component/MasterArchitectureSidebar";
import HouseTypeModal from "../component/HouseTypeModal";

function HouseType() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [activeHouseType, setActiveHouseType] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const deleteHouseType = async (houseTypeId) => {
    Swal.fire({
      title: "주택 형태 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/masterdata/house-type/${houseTypeId}`);
        if (res.status === 200) {
          if (res.data.status === 400) {
            Swal.fire({
              icon: "error",
              title: "실패",
              text: res.data.message,
            });
            return;
          } else {
            Swal.fire({
              icon: "success",
              title: "성공",
              text: "주택 형태가 삭제되었습니다.",
            });
            getList();
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "주택 형태 삭제에 실패했습니다.",
          });
        }
      }
    });
  };

  const getList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-type/all");
    if (res.status === 200) {
      setList(res.data);
      console.log(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }
  }, [userData]);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <MasterArchitectureSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">주택 형태 관리</h1>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setActiveHouseType(null);
                  setShowModal(true);
                }}
              >
                <i className="fa-solid fa-plus me-2"></i>
                추가
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>주택형태</th>
                    <th>등록일</th>
                    <th>활성화</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <tr key={index}>
                      <td>{item.house_type}</td>
                      <td>{$dateTimezone(item.create_datetime)}</td>
                      <td>{item.active_yn === "Y" ? "활성화" : "비활성화"}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm me-2"
                          onClick={() => {
                            setActiveHouseType(item);
                            setShowModal(true);
                          }}
                        >
                          <i className="fa-solid fa-pen"></i>
                        </button>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            deleteHouseType(item.house_type_id);
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <HouseTypeModal
          houseType={activeHouseType}
          onClose={() => {
            setShowModal(false);
          }}
          onList={() => {
            getList();
            setActiveHouseType(null);
            setShowModal(false);
          }}
        />
      )}
    </section>
  );
}

export default HouseType;
