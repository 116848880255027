import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import Menu from "./Menu.json";

function Header({ triggerLogin, setTriggerLogin }) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, userData, login, logout, checkLogin } = useAuth();
  const [loading, setLoading] = useState(false);

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: appUrl,
      scope:
        "profile_nickname, profile_image, account_email, name, phone_number",
      // scope:
      // "profile_nickname, profile_image, account_email, name, phone_number",
      //   state: "userme",
      //   serviceTerms: "account_email",
    });
  };

  const requestUserInfo = async (tokenInfo) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
      },
    });

    if (res.status === 401) {
      return;
    }

    const resJson = await res.json();

    const loginData = {
      email: resJson.kakao_account.email,
      nickname: resJson.kakao_account.profile.nickname,
      name:
        resJson.kakao_account.name || resJson.kakao_account.profile.nickname,
      phone: resJson.kakao_account.phone_number || "",
      profile_image_url: resJson.kakao_account.profile.profile_image_url,
      access_token: tokenInfo.access_token,
      access_token_expires_in: tokenInfo.expires_in,
      refresh_token: tokenInfo.refresh_token,
      refresh_token_expires_in: tokenInfo.refresh_token_expires_in,
    };

    const res2 = await $post("/api/kakao-login", loginData);

    const userInfo = res2.data[0];
    setLoading(false);

    if (res2.status === 200) {
      login({
        ...userInfo,
      });

      checkLogin();

      // navigate("/project");
      // window.location.reload();
      // refresh page

      // if (userInfo.role_level === 1) {
      //   if (userInfo.active_yn === "N") navigate("/waiting-list");
      // }
    }
  };

  const getToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&redirect_uri=${appUrl}&code=${code}`,
    });

    const resJson = await res.json();
    // console.log(resJson);

    if (resJson.access_token) {
      window.Kakao.Auth.setAccessToken(resJson.access_token);
      requestUserInfo(resJson);
    }
  };

  const requestKaKaoInfo = async () => {
    const params = new URL(document.location.toString()).searchParams;
    // console.log(params.get("code"));
    const code = params.get("code");
    if (code) {
      setLoading(true);
      getToken(code);
    }
  };

  const logoutWithKakao = async (accessToken) => {
    console.log("logoutWithKakao");
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
        // refresh page
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const unlinkKakao = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      fetch("https://kapi.kakao.com/v1/user/unlink", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${res.accessToken}`,
        },
      })
        .then((res) => {
          window.Kakao.Auth.setAccessToken(null);
          logout();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not logged in.");
      return;
    }
  };

  const initKaKao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    console.log(res);
    if (res.isLoggedIn) {
      logoutWithKakao(res.accessToken);
    }
  };

  useEffect(() => {
    initKaKao();
    requestKaKaoInfo();
  }, []);

  useEffect(() => {
    // console.log("triggerLogin", triggerLogin);
    if (triggerLogin) {
      loginWithKakao();
      setTriggerLogin(false);
    }
  }, [triggerLogin, setTriggerLogin]);

  return (
    <header
      className={
        location.pathname === "/"
          ? "header navbar navbar-expand-lg bg-dark navbar-dark fixed-top"
          : "header navbar navbar-expand-lg bg-light border-bottom border-light shadow-sm fixed-top"
      }
    >
      <div className="container px-3">
        <a href="/" className="navbar-brand pe-3">
          <img
            src="./assets/images/lifeholdings-logo-gold.png"
            alt="인생주택"
            style={{ height: "30px" }}
          />
        </a>
        <div id="navbarNav" className="offcanvas offcanvas-end bg-dark">
          <div className="offcanvas-header border-bottom border-light">
            <img
              src="./assets/images/lifeholdings-logo.png"
              alt="인생주택"
              style={{ height: "30px" }}
            />
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  onClick={() => navigate("/")}
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  홈
                </a>
              </li>
              {userData &&
                userData.role_level &&
                Menu[userData.role_level].map((menu, index) =>
                  menu.children && menu.children.length > 0 && menu.megamenu ? (
                    <li className="nav-item dropdown" key={`megamenu-${index}`}>
                      <a
                        href="#"
                        className={`nav-link dropdown-toggle${
                          location.pathname.startsWith(menu.header_path)
                            ? " active"
                            : ""
                        }`}
                        data-bs-toggle="dropdown"
                      >
                        {menu.title}
                      </a>
                      <div className="dropdown-menu">
                        <div className="d-lg-flex pt-lg-3">
                          {menu.children &&
                            menu.children.map((child, index) => (
                              <div
                                className="mega-dropdown-column"
                                key={`megamenu-child-${index}`}
                              >
                                {child.map((child2, index) => (
                                  <div key={`megamenu-child-child-${index}`}>
                                    <h6 className="px-3 mb-2">
                                      {child2.group_title}
                                    </h6>
                                    <ul className="list-unstyled mb-3">
                                      {child2.children &&
                                        child2.children.map((item, index) => (
                                          <li
                                            key={`megamenu-child-child-child-${index}`}
                                          >
                                            <a
                                              onClick={() =>
                                                navigate(item.path)
                                              }
                                              className="dropdown-item py-1"
                                            >
                                              {item.title}
                                            </a>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </li>
                  ) : menu.children && menu.children.length > 0 ? (
                    <li className="nav-item dropdown" key={`menu-${index}`}>
                      <a
                        href="#"
                        className={`nav-link dropdown-toggle${
                          location.pathname.startsWith(menu.header_path)
                            ? " active"
                            : ""
                        }`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {menu.title}
                      </a>
                      <ul className="dropdown-menu">
                        {menu.children.map((child, index) => (
                          <li key={`menu-child-${index}`}>
                            <a
                              onClick={() => navigate(child.path)}
                              className="dropdown-item"
                            >
                              {child.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li className="nav-item" key={`menu-${index}`}>
                      <a
                        onClick={() => navigate(menu.path)}
                        className={`nav-link ${
                          location.pathname !== "/" &&
                          location.pathname.startsWith(menu.header_path)
                            ? "active"
                            : location.pathname === "/" && menu.path === "/"
                            ? "active"
                            : ""
                        }`}
                      >
                        {menu.title}
                      </a>
                    </li>
                  )
                )}

              {!userData &&
                Menu["guest"].map((menu, index) => (
                  <li key={`guest-${index}`} className="nav-item">
                    <a
                      onClick={() => {
                        if (menu.auth) {
                          // Swal - 로그인이 필요한 서비스입니다. 로그인/회원가입 페이지로 이동하시겠습니까?
                          Swal.fire({
                            title: "로그인이 필요한 서비스입니다.",
                            text: "로그인/회원가입 페이지로 이동하시겠습니까?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "예",
                            cancelButtonText: "아니요",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              if (!isLoggedIn) {
                                loginWithKakao();
                              }
                            }
                          });
                        } else {
                          navigate(menu.path);
                        }
                      }}
                      className={`nav-link ${
                        location.pathname !== "/" &&
                        location.pathname.startsWith(menu.header_path)
                          ? "active"
                          : location.pathname === "/" && menu.path === "/"
                          ? "active"
                          : ""
                      }`}
                    >
                      {menu.title}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <div className="offcanvas-header border-top border-light">
            {!isLoggedIn ? (
              <>
                <a
                  onClick={loginWithKakao}
                  className="btn btn-primary w-100 me-2"
                >
                  로그인
                </a>
                <a
                  onClick={() => navigate("/signup")}
                  className="btn btn-light w-100"
                >
                  회원가입
                </a>
              </>
            ) : (
              <a onClick={socialLogout} className="btn btn-danger w-100">
                로그아웃
              </a>
            )}
          </div>
        </div>
        <div>
          {/* <button
            type="button"
            className="btn btn-outline-danger position-relative me-3 p-2"
          >
            <i className="fa-regular fa-bell"></i>
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              3<span className="visually-hidden">unread messages</span>
            </span>
          </button> */}

          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        {!isLoggedIn ? (
          <>
            <a
              onClick={loginWithKakao}
              className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex me-2"
            >
              로그인
            </a>
            <a
              onClick={() => navigate("/signup")}
              className={
                location.pathname === "/"
                  ? "btn btn-light btn-sm fs-sm rounded d-none d-lg-inline-flex"
                  : "btn btn-dark btn-sm fs-sm rounded d-none d-lg-inline-flex"
              }
            >
              회원가입
            </a>
          </>
        ) : (
          <div className="dropdown d-lg-block d-none">
            <a
              className=""
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={userData.profile_image_url}
                className="rounded-circle"
                width="30"
                height="30"
                alt={userData.nickname}
              />
            </a>

            <ul
              className="dropdown-menu dropdown-menu-end my-1"
              aria-labelledby="dropdownMenuLink"
              style={{ minWidth: "120px", maxWidth: "120px" }}
            >
              <li>
                <a onClick={socialLogout} className="dropdown-item" href="#">
                  로그아웃
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => navigate("/my-profile")}
                >
                  마이페이지
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
