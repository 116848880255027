import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put, $delete } from "../utils/common";
import Swal from "sweetalert2";

function AIQuoteDataModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [houseStyleList, setHouseStyleList] = useState([]);
  const [houseExteriorList, setHouseExteriorList] = useState([]);
  const [houseRoofList, setHouseRoofList] = useState([]);
  const [houseStyleId, setHouseStyleId] = useState("");
  const [houseExteriorId, setHouseExteriorId] = useState("0");
  const [houseRoofId, setHouseRoofId] = useState("0");
  const [totalFloorArea, setTotalFloorArea] = useState("");
  const [houseFloor, setHouseFloor] = useState("");
  const [quoteData, setQuoteData] = useState({});
  const [costCategoryList, setCostCategoryList] = useState([]);
  const [quoteGroupList, setQuoteGroupList] = useState([]);
  const [quoteCostList, setQuoteCostList] = useState([]);
  const getHouseStyleList = async () => {
    const res = await $get("/api/masterdata/house-style");
    if (res.status === 200) {
      setHouseStyleId(res.data[0].house_style_id);
      setHouseStyleList(res.data);
    }
  };

  const getHouseExteriorList = async () => {
    const res = await $get("/api/masterdata/house-exterior");
    if (res.status === 200) {
      setHouseExteriorId(res.data[0].house_exterior_id);
      setHouseExteriorList(res.data);
    }
  };

  const getHouseRoofList = async () => {
    const res = await $get("/api/masterdata/house-roof");
    if (res.status === 200) {
      setHouseRoofId(res.data[0].house_roof_id);
      setHouseRoofList(res.data);
    }
  };

  const getCostCategoryList = async () => {
    const res = await $get("/api/masterdata/cost-category/all");
    if (res.status === 200) {
      setCostCategoryList(res.data);
      if (!props.quoteData) {
        const tempQuoteCostList = [];
        res.data.forEach((item) => {
          tempQuoteCostList.push({
            cost_category_id: item.cost_category_id,
            cost_category_name: item.cost_category_name,
            cost: 0,
          });
        });
        setQuoteCostList(tempQuoteCostList);
      }
    }
  };

  const getQuoteGroupList = async (quoteInfo) => {
    const res = await $get(
      `/api/masterdata/quote-group/${quoteInfo.house_style_id}/${quoteInfo.house_exterior_id}/${quoteInfo.house_roof_id}/${quoteInfo.total_floor_area}/${quoteInfo.house_floor}`
    );
    if (res.status === 200) {
      if (res.data.length > 0) {
        setHouseStyleId(res.data[0].house_style_id);
        setHouseExteriorId(res.data[0].house_exterior_id);
        setHouseRoofId(res.data[0].house_roof_id);
        setTotalFloorArea(res.data[0].total_floor_area);
        setHouseFloor(res.data[0].house_floor);
        const tempQuoteCostList = [];
        res.data.forEach((item) => {
          tempQuoteCostList.push({
            project_cost_total_id: item.project_cost_total_id,
            house_style_id: item.house_style_id,
            house_exterior_id: item.house_exterior_id,
            house_roof_id: item.house_roof_id,
            total_floor_area: item.total_floor_area,
            house_floor: item.house_floor,
            cost_category_id: item.cost_category_id,
            cost_category_name: item.cost_category_name,
            total_cost: item.total_cost || 0,
          });
        });
        setQuoteCostList(tempQuoteCostList);
      }
    }
  };

  const doCreate = async () => {
    if (totalFloorArea === "") {
      Swal.fire({
        icon: "error",
        title: "연면적을 입력해주세요.",
      });
      return;
    }

    if (houseFloor === "") {
      Swal.fire({
        icon: "error",
        title: "층수를 입력해주세요.",
      });
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "견적 데이터를 추가하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // quoteCostList에서 비용분류별 total_cost에
        const res = await $post("/api/quote/ai-quote-data", {
          house_style_id: houseStyleId,
          house_exterior_id: houseExteriorId,
          house_roof_id: houseRoofId,
          total_floor_area: totalFloorArea,
          house_floor: houseFloor,
          quote_cost_list: quoteCostList,
        });

        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "견적 데이터를 추가하였습니다.",
          });
          props.onList();
          btnRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "견적 데이터를 추가하지 못했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    Swal.fire({
      icon: "warning",
      title: "견적 데이터를 수정하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // quoteCostList에서 비용분류별 total_cost에
        const res = await $put("/api/quote/ai-quote-data", {
          quote_cost_list: quoteCostList,
        });

        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "견적 데이터가 수정되었습니다.",
          });
          props.onList();
          btnRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "견적 데이터를 수정하지 못했습니다.",
          });
        }
      }
    });
  };

  const doDelete = async () => {
    Swal.fire({
      icon: "warning",
      title: "견적 데이터를 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(
          `/api/quote/ai-quote-data/${quoteData.house_style_id}/${quoteData.house_exterior_id}/${quoteData.house_roof_id}/${quoteData.total_floor_area}/${quoteData.house_floor}`
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "견적 데이터를 삭제하였습니다.",
          });
          props.onList();
          btnRef.current.click();
        }
      }
    });
  };

  useEffect(() => {
    getHouseStyleList();
    getHouseExteriorList();
    getHouseRoofList();
    getCostCategoryList();
    if (props.quoteData) {
      setQuoteData(props.quoteData);
      getQuoteGroupList(props.quoteData);
    }
    btnRef.current.click();
  }, [props.quoteData]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#aiQuoteDataModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="aiQuoteDataModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">AI 견적 데이터 관리</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                  btnRef.current.click();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <div className="input-group">
                  <select
                    className="form-select"
                    value={houseStyleId}
                    onChange={(e) => setHouseStyleId(e.target.value)}
                    disabled={quoteData.project_cost_total_id}
                  >
                    {houseStyleList.map((houseStyle, index) => (
                      <option key={index} value={houseStyle.house_style_id}>
                        {houseStyle.house_style}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-select"
                    value={houseExteriorId}
                    onChange={(e) => setHouseExteriorId(e.target.value)}
                    disabled={quoteData.project_cost_total_id}
                  >
                    {houseExteriorList.map((houseExterior, index) => (
                      <option
                        key={index}
                        value={houseExterior.house_exterior_id}
                      >
                        {houseExterior.house_exterior}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-select"
                    value={houseRoofId}
                    onChange={(e) => setHouseRoofId(e.target.value)}
                    disabled={quoteData.project_cost_total_id}
                  >
                    {houseRoofList.map((houseRoof, index) => (
                      <option key={index} value={houseRoof.house_roof_id}>
                        {houseRoof.house_roof}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="연면적(평수)"
                    value={totalFloorArea}
                    onChange={(e) => {
                      let totalFloorArea = parseInt(
                        e.target.value.replace(/\D/g, "")
                      );

                      setTotalFloorArea(totalFloorArea);
                    }}
                    disabled={quoteData.project_cost_total_id}
                  />
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="층수"
                    value={houseFloor}
                    onChange={(e) => {
                      let houseFloor = parseInt(
                        e.target.value.replace(/\D/g, "")
                      );

                      setHouseFloor(houseFloor);
                    }}
                    disabled={quoteData.project_cost_total_id}
                  />
                </div>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>비용분류</th>
                    <th>비용</th>
                  </tr>
                </thead>
                <tbody>
                  {quoteCostList.length > 0 &&
                    quoteCostList.map((item, index) => (
                      <tr key={item.cost_category_id}>
                        <td>{item.cost_category_name}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={item.total_cost}
                            onChange={(e) => {
                              let totalCost = parseInt(
                                e.target.value.replace(/\D/g, "")
                              );
                              if (isNaN(totalCost)) {
                                totalCost = 0;
                              }
                              setQuoteCostList((prev) => {
                                const newList = [...prev];
                                newList[index].total_cost = totalCost;
                                return newList;
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (quoteData && quoteData.project_cost_total_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {quoteData && quoteData.project_cost_total_id ? "수정" : "추가"}
              </button>
              {/* 삭제 */}
              {quoteData && quoteData.project_cost_total_id && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={doDelete}
                >
                  삭제
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIQuoteDataModal;
