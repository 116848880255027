import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function MasterSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <aside className="col-lg-2 col-md-4 border-end pb-5 pt-5">
      <div className="position-sticky top-0 pt-3">
        <div className="text-center pt-5">
          <button
            type="button"
            className="btn btn-secondary w-100 d-md-none mt-n2 mb-3"
            data-bs-toggle="collapse"
            data-bs-target="#account-menu"
          >
            <i className="bx bxs-user-detail fs-xl me-2" />
            마스터 관리 메뉴
            <i className="bx bx-chevron-down fs-lg ms-1" />
          </button>
          <div
            id="account-menu"
            className="list-group list-group-flush collapse d-md-block"
          >
            <a
              onClick={() => navigate("/master-house-style")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-house-style" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-house me-2"></i>
              건축 스타일 관리
            </a>
            <a
              onClick={() => navigate("/master-house-type")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-house-type" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-building me-2"></i>
              주택 형태 관리
            </a>
            <a
              onClick={() => navigate("/master-house-option")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-house-option" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-warehouse me-2"></i>
              주택 추가옵션
            </a>
            <a
              onClick={() => navigate("/master-house-site")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-house-site" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-house-flood-water me-2"></i>
              부지 유형 관리
            </a>
            <a
              onClick={() => navigate("/master-house-func")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-house-func" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-house-signal me-2"></i>
              주택 특화기능 관리
            </a>
            <a
              onClick={() => navigate("/master-house-exterior")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-house-exterior" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-cubes me-2"></i>
              건축 외장 재질
            </a>
            <a
              onClick={() => navigate("/master-house-roof")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-house-roof" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-people-roof me-2"></i>
              지붕재 관리
            </a>

            <a
              onClick={() => navigate("/master-architecture-process")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-architecture-process"
                  ? "active"
                  : ""
              }`}
            >
              <i className="fa-solid fa-diagram-project me-2"></i>
              건축 프로세스 관리
            </a>
            <a
              onClick={() => navigate("/master-construction-process")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-construction-process"
                  ? "active"
                  : ""
              }`}
            >
              <i className="fa-solid fa-diagram-project me-2"></i>
              시공 프로세스 관리
            </a>
            <a
              onClick={() => navigate("/master-document-category")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-document-category"
                  ? "active"
                  : ""
              }`}
            >
              <i className="fa-regular fa-folder-open me-2"></i>
              문서 분류 관리
            </a>
            <a
              onClick={() => navigate("/master-document-type")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-document-type" ? "active" : ""
              }`}
            >
              <i className="fa-regular fa-file-lines me-2"></i>
              문서 종류 관리
            </a>
            <a
              onClick={() => navigate("/master-worker-type")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-worker-type" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-person-digging me-2"></i>
              인력 역할 관리
            </a>
            <a
              onClick={() => navigate("/master-cost-category")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-cost-category" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-receipt me-2"></i>
              비용 항목 관리
            </a>
            <a
              onClick={() => navigate("/master-cost-subcategory")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-cost-subcategory" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-receipt me-2"></i>
              하위 비용 항목 관리
            </a>
            <a
              onClick={() => navigate("/master-material-category")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-material-category"
                  ? "active"
                  : ""
              }`}
            >
              <i className="fa-solid fa-list me-2" />
              자재 유형 관리
            </a>
            <a
              onClick={() => navigate("/master-material-unit")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-material-unit" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-weight-hanging me-2"></i>
              자재 단위 관리
            </a>
            {/* <a
              onClick={() => navigate("/master-company")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-company" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-hotel me-2" />
              건축회사 관리
            </a> */}
          </div>
        </div>
      </div>
    </aside>
  );
}

export default MasterSidebar;
