import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import MaterialPriceHistoryModal from "../component/MaterialPriceHistoryModal";
import MaterialModal from "../component/MaterialModal";
import MaterialSupplierSearchModal from "../component/MaterialSupplierSearchModal";

function ProjectMaterial() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [showMaterialPriceHistoryModal, setShowMaterialPriceHistoryModal] =
    useState(false);
  // const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [showMaterialSupplierModal, setShowMaterialSupplierModal] =
    useState(false);
  const [showMaterialSearchModal, setShowMaterialSearchModal] = useState(false);
  const [activeMaterial, setActiveMaterial] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [materialCategory, setMaterialCategory] = useState([]);
  // const [selectMaterialCategoryId, setSelectMaterialCategoryId] = useState(0);
  const [constructionProcess, setConstructionProcess] = useState([]);
  const [selectConstructionProcessId, setSelectConstructionProcessId] =
    useState(0);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    const url = searchText
      ? `/api/project/material/list/${projectId}/${tempPageNo}/${pageLimit}?materialName=${encodeURIComponent(
          searchText
        )}&constructionProcessId=${selectConstructionProcessId}`
      : `/api/project/material/list/${projectId}/${tempPageNo}/${pageLimit}?constructionProcessId=${selectConstructionProcessId}`;
    const res = await $get(url);
    if (res.status === 200) {
      setMaterialList(res.data.projectMaterialList);
      console.log(res.data.projectMaterialList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  const deleteMaterial = async (projectMaterialId) => {
    Swal.fire({
      title: "삭제하시겠습니까?",
      text: "삭제된 데이터는 복구할 수 없습니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/project/material/${projectMaterialId}`);
        if (res.status === 200) {
          getList();
        }
      }
    });
  };

  const getMaterialCategory = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/material-category");
    if (res.status === 200) {
      setMaterialCategory(res.data);
    }
    setLoading(false);
  };

  const getConstructionProcess = async () => {
    const res = await $get("/api/masterdata/construction-process");
    if (res.status === 200) {
      setConstructionProcess(res.data);
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      }
    });
  }, [projectId]);

  useEffect(() => {
    // getMaterialCategory();
    getConstructionProcess();
  }, []);

  return (
    <section className="container pt-3">
      <div className="row">
        <ProjectSidebar projectId={projectId} />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">자재 투입 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <div className="input-group">
                <select
                  className="form-select"
                  id="material-category"
                  style={{ maxWidth: "200px" }}
                  value={selectConstructionProcessId}
                  onChange={(e) => {
                    setSelectConstructionProcessId(e.target.value);
                  }}
                >
                  <option value="0">전체</option>
                  {constructionProcess.map((item, index) => (
                    <option key={index} value={item.construction_process_id}>
                      {item.construction_process_name}
                    </option>
                  ))}
                </select>
                <input
                  type="search"
                  className="form-control"
                  placeholder="자재명을 입력하세요."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (e.nativeEvent.isComposing) {
                        return;
                      }

                      getList();
                    }
                  }}
                />
                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => getList()}
                >
                  <i className="fa fa-solid fa-search"></i>
                </button>
              </div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setShowMaterialSearchModal(true);
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                자재 추가
              </button>
            </div>
            <div className="table-responsive">
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">프로세스</th>
                    <th className="border-top-0">자재명</th>
                    <th className="border-top-0">규격</th>
                    <th className="border-top-0">단위</th>
                    <th className="border-top-0">수량</th>
                    <th className="border-top-0">단가(원)</th>
                    <th className="border-top-0">분류</th>
                    <th className="border-top-0">공급처</th>
                    <th className="border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {materialList.map((material, index) => (
                    <tr key={index}>
                      <td>{material.construction_process_name}</td>
                      <td>{material.material_name}</td>
                      <td>{material.material_standard}</td>
                      <td>{material.material_unit_name}</td>
                      <td>{material.quantity}</td>
                      <td>{$convertNumberFormat(material.unit_price)}</td>
                      <td>{material.material_category_name}</td>
                      <td>{material.partner_name}</td>
                      <td>
                        {/* <button
                          type="button"
                          className="btn btn-sm btn-outline-primary me-2"
                          data-tooltip-id="btn-edit-tooltip"
                          onClick={() => {
                            setActiveMaterial(material);
                          }}
                        >
                          <i className="fa-solid fa-edit" />
                        </button> */}
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          data-tooltip-id="btn-delete-tooltip"
                          onClick={() => {
                            deleteMaterial(material.project_material_id);
                          }}
                        >
                          <i className="fa-solid fa-trash" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          href="#"
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="bx bx-chevron-left ms-n1 me-1" />
                          Prev
                        </a>
                      </li>
                    )}
                    {pageRange.map((page) => (
                      <li
                        key={page}
                        className={
                          pageNo === page
                            ? "page-item d-none d-sm-block active"
                            : "page-item d-none d-sm-block"
                        }
                      >
                        <a
                          onClick={(e) => setPageNo(page)}
                          className="page-link"
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          onClick={(e) => setPageNo(pageNo + 1)}
                          className="page-link"
                        >
                          Next
                          <i className="bx bx-chevron-right me-n1 ms-1" />
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

      {showMaterialSearchModal && (
        <MaterialSupplierSearchModal
          projectId={projectId}
          onClose={() => setShowMaterialSearchModal(false)}
          onList={() => {
            getList();
          }}
        />
      )}
    </section>
  );
}

export default ProjectMaterial;
