function PlaceHoderCard(props) {
  return props.type === "lg" ? (
    <article className="card card-hover-primary h-100 border-0 shadow-lg">
      <div className="card-body">
        <h5 className="card-title placeholder-glow">
          <span className="placeholder col-6"></span>
          <span className="visually-hidden">Card title</span>
        </h5>
        <p className="card-text placeholder-glow">
          <span className="placeholder placeholder-sm col-7 me-2"></span>
          <span className="placeholder placeholder-sm col-4"></span>
          <span className="placeholder placeholder-sm col-4 me-2"></span>
          <span className="placeholder placeholder-sm col-6"></span>
          <span className="placeholder placeholder-sm col-8"></span>
        </p>
        <a
          href="#"
          className="btn btn-secondary disabled placeholder col-6 placeholder-wave"
        >
          {" "}
        </a>
      </div>
    </article>
  ) : props.type === "sm" ? (
    <article className="card card-hover-primary h-100 border-0 shadow-lg">
      <div className="card-body">
        <h5 className="card-title placeholder-glow">
          <span className="placeholder col-6"></span>
          <span className="visually-hidden">Card title</span>
        </h5>
        <p className="card-text placeholder-glow">
          <span className="placeholder placeholder-sm col-7 me-2"></span>
          <span className="placeholder placeholder-sm col-4 me-2"></span>
        </p>
        <a
          href="#"
          tabindex="-1"
          className="btn btn-secondary disabled placeholder col-6 placeholder-wave"
        >
          {" "}
        </a>
      </div>
    </article>
  ) : (
    <article className="card card-hover-primary h-100 border-0 shadow-lg">
      <div className="card-body">
        <h5 className="card-title placeholder-glow">
          <span className="placeholder col-6"></span>
          <span className="visually-hidden">Card title</span>
        </h5>
        <a
          href="#"
          tabindex="-1"
          className="btn btn-secondary disabled placeholder col-6 placeholder-wave"
        >
          {" "}
        </a>
      </div>
    </article>
  );
}

export default PlaceHoderCard;
