import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function MaterialSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <aside className="col-lg-2 col-md-4 border-end pt-5 pb-5">
      <div className="position-sticky top-0 pt-3">
        <div className="text-center pt-5">
          <button
            type="button"
            className="btn btn-secondary w-100 d-md-none mt-n2 mb-3"
            data-bs-toggle="collapse"
            data-bs-target="#account-menu"
          >
            <i className="bx bxs-user-detail fs-xl me-2" />
            자재 관리 메뉴
            <i className="bx bx-chevron-down fs-lg ms-1" />
          </button>
          <div
            id="account-menu"
            className="list-group list-group-flush collapse d-md-block"
          >
            <a
              onClick={() => navigate("/material")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/material" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-tree me-2" />
              자재 관리
            </a>
            <a
              onClick={() => navigate("/material-category")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/material-category" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-list me-2" />
              자재 유형 관리
            </a>
            <a
              onClick={() => navigate("/material-unit")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/material-unit" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-weight-hanging me-2"></i>
              자재 단위 관리
            </a>
            {/* <a
              onClick={() => navigate("/material-price")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/material-price" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-coins me-2"></i>
              자재 가격 관리
            </a> */}

            {/* <a
              onClick={() => navigate("/material-supplier")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/material-supplier" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-truck me-2"></i>
              공급처 관리
            </a> */}
          </div>
        </div>
      </div>
    </aside>
  );
}

export default MaterialSidebar;
