import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

function Signup({ onLoginClick }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <section className="container">
      {/* Sign in form */}
      <div
        className="d-flex flex-column flex-wrap justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="w-100 pt-1 pt-md-4 pb-4" style={{ maxWidth: 426 }}>
          <h3 className="text-center">인생주택에 오신 것을 환영합니다.</h3>
          <p className="text-center">
            카카오톡 로그인을 통해 가입해주세요.
            <br /> 사용자 이름, 연락처, 이메일 정보가 수집이 됩니다.
            <br /> 자세한 개인정보처리방침은 아래 링크를 참고해주세요.
          </p>
          <div className="d-flex justify-content-center">
            <img
              src="./assets/images/kakao_login_large_wide.png"
              alt="카카오 로그인"
              style={{ width: "100%", height: "auto", cursor: "pointer" }}
              onClick={onLoginClick}
            />
          </div>

          <hr className="my-4" />
          <div className="row row-cols-1 row-cols-sm-2">
            <div className="col mb-3">
              <a
                href="https://blueengine.notion.site/113e3862c5e6803da37cdaff9b716437"
                target="_blank"
                className="btn btn-icon btn-secondary  btn-lg w-100"
              >
                이용약관
              </a>
            </div>
            <div className="col mb-3">
              <a
                href="https://blueengine.notion.site/113e3862c5e68030b13df3710a39309b"
                target="_blank"
                className="btn btn-icon btn-secondary  btn-lg w-100"
              >
                개인정보처리방침
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
