import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import MessageModal from "../component/MessageModal";

function ProjectMessage() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [workerList, setWorkerList] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const getProcessList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/architecture-process");
    if (res.status === 200) {
      setProcessList(res.data);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
    setLoading(false);
  };

  const getWorkerList = async () => {
    setLoading(true);
    setWorkerList([
      {
        role: "건축사",
        name: "홍길동",
        phone: "010-1234-5678",
        email: "hong@gmail.com",
        address: "서울시 강남구 서초동 123-456",
      },
      {
        role: "레미콘 기사",
        name: "김철수",
        phone: "010-1234-5678",
        email: "aaa@gmail.com",
        address: "서울시 강남구 서초동 123-456",
      },
      {
        role: "실내 내장사",
        name: "김영희",
        phone: "010-1234-5678",
        email: "hong@gmail.com",
        address: "서울시 강남구 서초동 123-456",
      },
      {
        role: "전기 기사",
        name: "강전기",
        phone: "010-1234-5678",
        email: "aaa@gmail.com",
        address: "서울시 강남구 서초동 123-456",
      },
    ]);

    setLoading(false);
  };

  useEffect(() => {
    getProcessList();
    getWorkerList();
  }, []);

  return (
    <section className="container pt-3">
      <div className="row">
        <ProjectSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">프로젝트 메시지</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <select className="form-select">
                {/* <option value="-1">프로세스 선택</option> */}
                {processList.map((process, index) => (
                  <option
                    key={process.architecture_process_id}
                    value={process.architecture_process_id}
                  >
                    {process.architecture_process_name}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => setShowMessageModal(true)}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                메시지 등록
              </button>
            </div>
            <div className="d-flex flex-column gap-3">
              {/* Comment */}
              <div className="py-4">
                <div className="d-flex align-items-center justify-content-between pb-2 mb-1">
                  <div className="d-flex align-items-center me-3">
                    <img
                      src="assets/images/avatar/2.jpg"
                      className="rounded-circle"
                      width={48}
                      alt="Avatar"
                    />
                    <div className="ps-3">
                      <h6 className="fw-semibold mb-0">홍길동</h6>
                      <span className="fs-sm text-muted">
                        2024년 7월 9일 12:48
                      </span>
                    </div>
                  </div>
                  <a
                    className="nav-link fs-sm px-0"
                    onClick={() => setShowMessageModal(true)}
                  >
                    <i className="fa-solid fa-reply me-2"></i>
                    댓글달기
                  </a>
                </div>
                <p className="mb-0">
                  시공전 필요한 모든 인허가 서류를 준비하였습니다.
                </p>
              </div>
              <hr className="my-2" />
              {/* Comment */}
              <div className="py-4">
                <div className="d-flex align-items-center justify-content-between pb-2 mb-1">
                  <div className="d-flex align-items-center me-3">
                    <img
                      src="assets/images/avatar/2.jpg"
                      className="rounded-circle"
                      width={48}
                      alt="Avatar"
                    />
                    <div className="ps-3">
                      <h6 className="fw-semibold mb-0">홍길동</h6>
                      <span className="fs-sm text-muted">
                        2024년 7월 6일 15:25
                      </span>
                    </div>
                  </div>
                  <a
                    onClick={() => setShowMessageModal(true)}
                    className="nav-link fs-sm px-0"
                  >
                    <i className="fa-solid fa-reply me-2"></i>
                    댓글달기
                  </a>
                </div>
                <p className="mb-0 pb-2">소방설비도 준비되면 알려주세요.</p>
                {/* Comment reply */}
                <div className="position-relative ps-4 mt-4">
                  <span className="position-absolute top-0 start-0 w-1 h-100 bg-primary" />
                  <div className="d-flex align-items-center justify-content-between ps-3 pb-2 mb-1">
                    <div className="d-flex align-items-center me-3">
                      <img
                        src="assets/images/avatar/3.jpg"
                        className="rounded-circle"
                        width={48}
                        alt="Avatar"
                      />
                      <div className="ps-3">
                        <h6 className="fw-semibold mb-0">강혜인</h6>
                        <span className="fs-sm text-muted">
                          2024년 7월 6일 17:49
                        </span>
                      </div>
                    </div>
                    <a
                      onClick={() => setShowMessageModal(true)}
                      className="nav-link fs-sm px-0"
                    >
                      <i className="fa-solid fa-reply me-2"></i>
                      댓글달기
                    </a>
                  </div>
                  <p className="ps-3 mb-0">
                    <a href="#" className="fw-semibold text-decoration-none">
                      @홍길동
                    </a>{" "}
                    서류 준비 완료되었습니다. 프로젝트 문서 관리 메뉴에
                    등록했으니 확인 후 알려주세요.
                  </p>
                </div>
              </div>

              <hr className="my-2" />
              <div className="py-4">
                <div className="d-flex align-items-center justify-content-between pb-2 mb-1">
                  <div className="d-flex align-items-center me-3">
                    <img
                      src="assets/images/avatar/2.jpg"
                      className="rounded-circle"
                      width={48}
                      alt="Avatar"
                    />
                    <div className="ps-3">
                      <h6 className="fw-semibold mb-0">홍길동</h6>
                      <span className="fs-sm text-muted">
                        2024년 7월 3일 09:11
                      </span>
                    </div>
                  </div>
                  <a
                    className="nav-link fs-sm px-0"
                    onClick={() => setShowMessageModal(true)}
                  >
                    <i className="fa-solid fa-reply me-2"></i>
                    댓글달기
                  </a>
                </div>
                <p className="mb-0">평면도 설계가 완료되었습니다.</p>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <a className="file-link">
                      <i className="fa-solid fa-paperclip me-2"></i>평면도 초기
                      설계안
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showMessageModal && (
        <MessageModal
          onClose={() => setShowMessageModal(false)}
          workerList={workerList}
        />
      )}
    </section>
  );
}

export default ProjectMessage;
