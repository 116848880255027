import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function WorkerModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [worker, setWorker] = useState(null);
  const [workerTypeList, setWorkerTypeList] = useState([]);

  const doCreate = async () => {
    if (!worker.worker_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "이름을 입력하세요.",
      });
      return;
    }

    if (!worker.phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "연락처를 입력하세요.",
      });
      return;
    }

    if (!worker.worker_type_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "역할을 선택하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "인력 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/worker", worker);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "인력이 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "인력 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!worker.worker_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "이름을 입력하세요.",
      });
      return;
    }

    if (!worker.phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "연락처를 입력하세요.",
      });
      return;
    }

    if (!worker.worker_type_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "역할을 선택하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "인력 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/worker/${worker.worker_id}`, {
          worker_name: worker.worker_name,
          phone_number: worker.phone_number,
          email: worker.email,
          address: worker.address,
          notes: worker.notes,
          worker_type_id: worker.worker_type_id,
          identity_number: worker.identity_number,
          bank_name: worker.bank_name,
          bank_account_number: worker.bank_account_number,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "인력 정보가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "인력 정보 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  const getWorkerTypeList = async () => {
    const res = await $get("/api/masterdata/worker-type");
    if (res.status === 200) {
      setWorkerTypeList(res.data);
    }
  };

  const getWorker = async (workerId) => {
    const res = await $get(`/api/worker/${workerId}`);
    if (res.status === 200) {
      setWorker(res.data);
    }
  };

  useEffect(() => {
    getWorkerTypeList();
    if (props.worker) {
      getWorker(props.worker.worker_id);
    } else {
      setWorker({
        worker_type_id: "",
        worker_name: "",
        phone_number: "",
        email: "",
        address: "",
        notes: "",
        identity_number: "",
        bank_name: "",
        bank_account_number: "",
      });
    }
    btnRef.current.click();
  }, [props.worker]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#workerModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="workerModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">인력(기술자)</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {worker && (
              <div className="modal-body">
                {/* select - 개인, 법인, 기관 */}
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <label
                      htmlFor="worker_name"
                      className="form-label required"
                    >
                      이름
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="worker_name"
                      value={worker.worker_name}
                      onChange={(e) => {
                        setWorker({
                          ...worker,
                          worker_name: e.target.value,
                        });
                      }}
                      placeholder="이름을 입력하세요."
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="worker" className="form-label required">
                      역할
                    </label>
                    <select
                      className="form-select"
                      id="workerType"
                      value={worker.worker_type_id}
                      onChange={(e) =>
                        setWorker({ ...worker, worker_type_id: e.target.value })
                      }
                    >
                      <option value="">전체</option>
                      {workerTypeList.map((workerType) => (
                        <option
                          key={workerType.worker_type_id}
                          value={workerType.worker_type_id}
                        >
                          {workerType.worker_type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label
                      htmlFor="phone_number"
                      className="form-label required"
                    >
                      연락처
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone_number"
                      value={worker.phone_number}
                      onChange={(e) => {
                        setWorker({
                          ...worker,
                          phone_number: e.target.value,
                        });
                      }}
                      placeholder="연락처를 입력하세요."
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      이메일
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={worker.email}
                      onChange={(e) => {
                        setWorker({
                          ...worker,
                          email: e.target.value,
                        });
                      }}
                      placeholder="이메일을 입력하세요."
                    />
                  </div>
                  <div className="col-sm-12 mb-3">
                    <label htmlFor="worker_address" className="form-label">
                      주소
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="worker_address"
                      value={worker.address}
                      onChange={(e) => {
                        setWorker({
                          ...worker,
                          address: e.target.value,
                        });
                      }}
                      placeholder="주소를 입력하세요."
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="identity_number" className="form-label">
                      주민번호
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="identity_number"
                      value={worker.identity_number}
                      onChange={(e) => {
                        setWorker({
                          ...worker,
                          identity_number: e.target.value,
                        });
                      }}
                      placeholder="주민번호를 입력하세요.(-제외)"
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="bank" className="form-label">
                      계좌정보
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="bank"
                        style={{ maxWidth: "120px" }}
                        value={worker.bank_name}
                        onChange={(e) => {
                          setWorker({
                            ...worker,
                            bank_name: e.target.value,
                          });
                        }}
                        placeholder="은행명"
                      />
                      <input
                        type="text"
                        className="form-control"
                        id="bank_account_number"
                        value={worker.bank_account_number}
                        onChange={(e) => {
                          setWorker({
                            ...worker,
                            bank_account_number: e.target.value,
                          });
                        }}
                        placeholder="계좌번호를 입력하세요."
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 mb-3">
                  <label htmlFor="note" className="form-label">
                    비고
                  </label>
                  <textarea
                    className="form-control"
                    id="note"
                    value={worker.notes}
                    onChange={(e) => {
                      setWorker({
                        ...worker,
                        notes: e.target.value,
                      });
                    }}
                    placeholder="비고를 입력하세요."
                  ></textarea>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (worker && worker.worker_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {worker && worker.worker_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkerModal;
