import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import ProjectMemberModal from "../component/ProjectMemberModal";

function ProjectMember() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const [projectMemberList, setProjectMemberList] = useState([]);
  const [activeProjectMember, setActiveProjectMember] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const deleteProjectMember = async (projectMemberId) => {
    Swal.fire({
      title: "프로젝트 멤버 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/project/member/${projectMemberId}`);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "프로젝트 멤버가 삭제되었습니다.",
          });
          getProjectMemberList();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "프로젝트 멤버 삭제에 실패했습니다.",
          });
        }
      }
    });
  };

  const getProjectMemberList = async () => {
    setLoading(true);
    const res = await $get(`/api/project/member/${projectId}`);
    if (res.status === 200) {
      setProjectMemberList(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getProjectMemberList();
      }
    });
  }, [projectId]);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <ProjectSidebar projectId={projectId} />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">프로젝트 멤버 관리</h1>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setActiveProjectMember(null);
                  setShowModal(true);
                }}
              >
                <i className="fa-solid fa-plus me-2"></i>
                추가
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>멤버 이름</th>
                    <th>이메일</th>
                    <th>역할</th>
                    <th>등록일</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {projectMemberList.map((member, index) => (
                    <tr key={index}>
                      <td>{member.member_name}</td>
                      <td>{member.email}</td>
                      <td>
                        {member.role_level === "project_manager"
                          ? "프로젝트 관리자"
                          : member.role_level === "client"
                          ? "건축주(고객)"
                          : ""}
                      </td>
                      <td>{$dateTimezone(member.create_datetime)}</td>
                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            deleteProjectMember(member.project_member_id);
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ProjectMemberModal
          projectMember={activeProjectMember}
          projectId={projectId}
          onClose={() => setShowModal(false)}
          onList={() => {
            getProjectMemberList();
            setActiveProjectMember(null);
            setShowModal(false);
          }}
        />
      )}
    </section>
  );
}

export default ProjectMember;
