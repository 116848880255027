import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import MaterialSidebar from "../component/MaterialSidebar";

function MaterialPrice() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [materialList, setMaterialList] = useState([]);

  const getMaterialList = async () => {
    setLoading(true);
    setMaterialList([
      {
        id: 1,
        name: "방화석고보드(12.5T)",
        category: "석고보드",
        standard: "900*1800",
        price: 10000,
        unit: "EA",
        supplier: "건영자재",
        user: "홍길동",
        created: "2024-08-01",
      },
    ]);
  };

  useEffect(() => {
    getMaterialList();
  }, []);

  return (
    <section className="container pt-3">
      <div className="row">
        <MaterialSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">자재 가격 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="자재명을 입력하세요."
                />
                <button className="btn btn-outline-secondary" type="button">
                  <i className="fa fa-solid fa-search"></i>
                </button>
              </div>
              {/* <button className="btn btn-primary" type="button">
                <i className="fa fa-solid fa-plus me-2"></i>
                자재 추가
              </button> */}
            </div>
            <div className="table-responsive">
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">자재명</th>
                    <th className="border-top-0">규격</th>
                    <th className="border-top-0">단위</th>
                    <th className="border-top-0">가격</th>
                    <th className="border-top-0">분류</th>
                    <th className="border-top-0">공급처</th>
                    <th className="border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {materialList.map((material, index) => (
                    <tr key={index}>
                      <td>{material.name}</td>
                      <td>{material.standard}</td>
                      <td>{material.unit}</td>
                      <td>{$convertNumberFormat(material.price)}</td>
                      <td>{material.category}</td>
                      <td>
                        <a className="link-primary">{material.supplier}</a>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary me-2"
                        >
                          <i className="fa-solid fa-edit me-2" />
                          수정
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                        >
                          <i className="fa-solid fa-trash me-2" />
                          삭제
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a href="#" className="page-link">
                      <i className="bx bx-chevron-left ms-n1 me-1" />
                      Prev
                    </a>
                  </li>
                  <li className="page-item disabled d-sm-none">
                    <span className="page-link text-body">2 / 5</span>
                  </li>
                  <li className="page-item d-none d-sm-block">
                    <a href="#" className="page-link">
                      1
                    </a>
                  </li>
                  <li
                    className="page-item active d-none d-sm-block"
                    aria-current="page"
                  >
                    <span className="page-link">
                      2<span className="visually-hidden">(current)</span>
                    </span>
                  </li>
                  <li className="page-item d-none d-sm-block">
                    <a href="#" className="page-link">
                      3
                    </a>
                  </li>
                  <li className="page-item d-none d-sm-block">
                    <a href="#" className="page-link">
                      4
                    </a>
                  </li>
                  <li className="page-item d-none d-sm-block">
                    <a href="#" className="page-link">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a href="#" className="page-link">
                      Next
                      <i className="bx bx-chevron-right me-n1 ms-1" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MaterialPrice;
