import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function CompanyModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);

  const doCreate = async () => {
    if (!company.company_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "회사명을 입력하세요.",
      });
      return;
    }

    if (!company.company_code) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "회사코드를 입력하세요.",
      });
      return;
    }

    if (!company.ceo_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "대표자명을 입력하세요.",
      });
      return;
    }

    if (!company.phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "연락처를 입력하세요.",
      });
      return;
    }

    if (!company.email) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "이메일을 입력하세요.",
      });
      return;
    }

    if (!company.company_address) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "주소를 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "건축회사 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/company", company);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "건축회사가 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "건축회사 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!company.company_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "회사명을 입력하세요.",
      });
      return;
    }

    if (!company.company_code) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "회사코드를 입력하세요.",
      });
      return;
    }

    if (!company.ceo_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "대표자명을 입력하세요.",
      });
      return;
    }

    if (!company.phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "연락처를 입력하세요.",
      });
      return;
    }

    if (!company.email) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "이메일을 입력하세요.",
      });
      return;
    }

    if (!company.company_address) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "주소를 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "건축회사 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const companyData = {
          company_name: company.company_name,
          ceo_name: company.ceo_name,
          phone_number: company.phone_number,
          email: company.email,
          company_address: company.company_address,
        };

        const res = await $put(
          `/api/company/${company.company_id}`,
          companyData
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "건축회사이 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "건축회사 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  const checkCompanyCode = async () => {
    const res = await $post(`/api/company/check-code`, {
      company_code: company.company_code,
    });
    if (res.status === 200) {
      if (res.data.count > 0) {
        Swal.fire({
          icon: "error",
          title: "실패",
          text: "회사코드가 중복됩니다.",
        });
        setCompany({
          ...company,
          company_code: "",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "성공",
          text: "회사코드가 중복되지 않습니다.",
        });
      }
    }
  };

  useEffect(() => {
    if (props.company) {
      setCompany(props.company);
    } else {
      setCompany({
        company_id: "",
        company_name: "",
        ceo_name: "",
        phone_number: "",
        email: "",
        company_address: "",
      });
    }
    btnRef.current.click();
  }, [props.company]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#companyModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="companyModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">건축회사</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {company && (
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="company_name" className="form-label">
                    회사명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="company_name"
                    value={company.company_name}
                    onChange={(e) => {
                      setCompany({
                        ...company,
                        company_name: e.target.value,
                      });
                    }}
                    placeholder="회사명을 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="company_code" className="form-label">
                    회사코드
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="company_code"
                      value={company.company_code}
                      onChange={(e) => {
                        setCompany({
                          ...company,
                          company_code: e.target.value,
                        });
                      }}
                      placeholder="회사코드를 입력하세요.(유일한 코드여야 합니다.)"
                      disabled={company.company_id}
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => {
                        checkCompanyCode();
                      }}
                    >
                      중복체크
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="ceo_name" className="form-label">
                    대표자명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="ceo_name"
                    value={company.ceo_name}
                    onChange={(e) => {
                      setCompany({
                        ...company,
                        ceo_name: e.target.value,
                      });
                    }}
                    placeholder="대표자명을 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone_number" className="form-label">
                    연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone_number"
                    value={company.phone_number}
                    onChange={(e) => {
                      setCompany({
                        ...company,
                        phone_number: e.target.value,
                      });
                    }}
                    placeholder="연락처를 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    이메일
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={company.email}
                    onChange={(e) => {
                      setCompany({
                        ...company,
                        email: e.target.value,
                      });
                    }}
                    placeholder="이메일을 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="company_address" className="form-label">
                    주소
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    value={company.company_address}
                    onChange={(e) => {
                      setCompany({
                        ...company,
                        company_address: e.target.value,
                      });
                    }}
                    placeholder="주소를 입력하세요."
                  />
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (company && company.company_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {company && company.company_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyModal;
