import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function PartnerSearchModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    const url = searchText
      ? `/api/partner/list/${tempPageNo}/${pageLimit}?search=${encodeURIComponent(
          searchText
        )}`
      : `/api/partner/list/${tempPageNo}/${pageLimit}`;
    const res = await $get(url);
    if (res.status === 200) {
      setPartnerList(res.data.partnerList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  const addProjectPartner = async (partner) => {
    const res = await $post(`/api/project/partner/${props.projectId}`, {
      partner_id: partner.partner_id,
    });
    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "성공",
        text: "협력업체가 추가되었습니다.",
      });
      props.onList();
      btnCloseRef.current.click();
    } else {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "협력업체 추가에 실패했습니다.",
      });
    }
  };

  useEffect(() => {
    if (!props.projectId) {
      return;
    }
    btnRef.current.click();
  }, [props.projectId]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#workerSearchModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="workerSearchModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">협력업체</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center gap-2 mb-4">
                <div className="input-group">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="업체명을 입력하세요"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (e.nativeEvent.isComposing) {
                          return;
                        }

                        getList();
                      }
                    }}
                  />
                  <button className="btn btn-dark" type="button">
                    <i className="fa fa-solid fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th className="border-top-0">업체명</th>
                      <th className="border-top-0">유형</th>
                      <th className="border-top-0">전문분야</th>
                      <th className="border-top-0">전화번호</th>
                      <th className="border-top-0">주소</th>
                      <th className="border-top-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {partnerList.map((partner, index) => (
                      <tr key={index}>
                        <td>{partner.partner_name}</td>
                        <td>{partner.partner_type}</td>
                        <td>{partner.partner_speciality}</td>
                        <td>{partner.phone_number}</td>
                        <td>{partner.address}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              addProjectPartner(partner);
                            }}
                          >
                            선택
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPage > 1 && (
                <div className="d-flex justify-content-center align-items-center">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {pageNo > 5 && (
                        <li className="page-item">
                          <a
                            href="#"
                            className="page-link"
                            onClick={(e) => setPageNo(pageNo - 1)}
                          >
                            <i className="bx bx-chevron-left ms-n1 me-1" />
                            Prev
                          </a>
                        </li>
                      )}
                      {pageRange.map((page) => (
                        <li
                          key={page}
                          className={
                            pageNo === page
                              ? "page-item d-none d-sm-block active"
                              : "page-item d-none d-sm-block"
                          }
                        >
                          <a
                            onClick={(e) => setPageNo(page)}
                            className="page-link"
                          >
                            {page}
                          </a>
                        </li>
                      ))}

                      {lastPage < totalPage && (
                        <li className="page-item">
                          <a
                            onClick={(e) => setPageNo(pageNo + 1)}
                            className="page-link"
                          >
                            Next
                            <i className="bx bx-chevron-right me-n1 ms-1" />
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerSearchModal;
