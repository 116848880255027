import { useTranslation } from "react-i18next";

function PageNotFound() {
  const { t, i18n } = useTranslation();
  return (
    <section className="container d-flex flex-column h-100 align-items-center position-relative zindex-5 pt-5 mt-5 mb-5">
      <div className="text-center pt-5 pb-3 mt-5 mb-5">
        <div
          className="parallax mx-auto d-dark-mode-none"
          style={{ maxWidth: 574 }}
        >
          <div className="parallax-layer" data-depth="-0.15">
            <img src="assets/images/404/light/layer01.png" alt="Layer" />
          </div>
          <div className="parallax-layer" data-depth="0.12">
            <img src="assets/images/404/light/layer02.png" alt="Layer" />
          </div>
          <div className="parallax-layer zindex-5" data-depth="-0.12">
            <img src="assets/images/404/light/layer03.png" alt="Layer" />
          </div>
        </div>

        <h1 className="visually-hidden">404</h1>
        <p className="fs-xl pb-3 pb-md-0 mb-md-5">페이지를 찾을 수 없습니다.</p>
      </div>
    </section>
  );
}

export default PageNotFound;
