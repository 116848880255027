import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import MasterDocumentSidebar from "../component/MasterDocumentSidebar";
import DocumentCategoryModal from "../component/DocumentCategoryModal";

function DocumentCategory() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);

  const deleteCategory = async (id) => {
    Swal.fire({
      title: "문서 분류 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/masterdata/document-category/${id}`);
        if (res.status === 200) {
          if (res.data.status === 400) {
            Swal.fire({
              icon: "error",
              title: "실패",
              text: res.data.message,
            });
            return;
          } else {
            Swal.fire({
              icon: "success",
              title: "성공",
              text: "문서 분류가 삭제되었습니다.",
            });
            getCategoryList();
          }
        } else {
          Swal.fire("Error", "삭제에 실패했습니다.", "error");
        }
      }
    });
  };

  const getCategoryList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/document-category");
    if (res.status === 200) {
      setCategoryList(res.data);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <MasterDocumentSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">문서 분류 관리</h1>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setActiveCategory(null);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                분류 추가
              </button>
            </div>

            <div className="table-responsive">
              <table className="table table-hover align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">분류명</th>
                    <th className="border-top-0">등록일</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {categoryList.map((category, index) => (
                    <tr key={index}>
                      <td>{category.document_category_name}</td>
                      <td>{$dateTimezone(category.create_datetime)}</td>
                      <td>
                        {/* edit */}
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary me-2"
                          onClick={() => {
                            setActiveCategory(category);
                            setShowModal(true);
                          }}
                        >
                          <i className="fa-solid fa-pen"></i>
                        </button>

                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          onClick={() =>
                            deleteCategory(category.document_category_id)
                          }
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <DocumentCategoryModal
          documentCategory={activeCategory}
          onClose={() => {
            setShowModal(false);
            setActiveCategory(null);
          }}
          onList={() => {
            setShowModal(false);
            setActiveCategory(null);
            getCategoryList();
          }}
        />
      )}
    </section>
  );
}

export default DocumentCategory;
