import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import SystemSidebar from "../component/SystemSidebar";
import UserModal from "../component/UserModal";

function SystemInfo() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const saveSystemInfo = async () => {
    if (!contactPhone) {
      Swal.fire({
        icon: "error",
        title: "상담 연락처를 입력해주세요.",
      });
      return;
    }

    if (!contactEmail) {
      Swal.fire({
        icon: "error",
        title: "상담 이메일을 입력해주세요.",
      });
      return;
    }

    if (!website) {
      Swal.fire({
        icon: "error",
        title: "웹사이트 주소를 입력해주세요.",
      });
      return;
    }

    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "대표 연락처를 입력해주세요.",
      });
      return;
    }

    if (!email) {
      Swal.fire({
        icon: "error",
        title: "대표 이메일을 입력해주세요.",
      });
      return;
    }

    if (!businessAddress) {
      Swal.fire({
        icon: "error",
        title: "사업자 주소를 입력해주세요.",
      });
      return;
    }

    const systemInfo = {
      contact_phone: contactPhone,
      contact_email: contactEmail,
      website: website,
      phone: phone,
      email: email,
      business_address: businessAddress,
    };

    const res = await $post("/api/system/info", systemInfo);
    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "시스템 정보가 성공적으로 저장되었습니다.",
      });

      getSystemInfo();
    } else {
      Swal.fire({
        icon: "error",
        title: "시스템 정보 저장에 실패했습니다.",
      });
    }
  };

  const getSystemInfo = async () => {
    const res = await $get("/api/system/info");
    if (res.status === 200) {
      setContactPhone(res.data.contact_phone);
      setContactEmail(res.data.contact_email);
      setWebsite(res.data.website);
      setPhone(res.data.phone);
      setEmail(res.data.email);
      setBusinessAddress(res.data.business_address);
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }

    getSystemInfo();
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <SystemSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">시스템 정보 관리</h1>
            </div>
            <div className="mb-3">
              {/* form - 연락처, 이메일, 웹사이트주소, 대표전화, 사업자주소 */}
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="contact_phone" className="form-label">
                    상담 연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact_phone"
                    placeholder="AI 견적 등 상담을 위한 연락처"
                    value={contactPhone}
                    onChange={(e) => setContactPhone(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="contact_email" className="form-label">
                    상담 이메일
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact_email"
                    placeholder="AI 견적 등 상담을 위한 이메일"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="website" className="form-label">
                    웹사이트 주소
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="website"
                    placeholder="lifebuild.ai"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="phone" className="form-label">
                    대표 연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="사이트 공식 연락처"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="email" className="form-label">
                    대표 이메일
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="사이트 공식 이메일"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="business_address" className="form-label">
                    사업자 주소
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="business_address"
                    placeholder="사업자 주소"
                    value={businessAddress}
                    onChange={(e) => setBusinessAddress(e.target.value)}
                  />
                </div>
              </div>
              <button
                className="btn btn-primary"
                onClick={() => {
                  saveSystemInfo();
                }}
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SystemInfo;
