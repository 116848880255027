import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import PlaceHoderCard from "../component/PlacehoderCard";
import SystemSidebar from "../component/MasterSidebar";
import PartnerSearchModal from "../component/PartnerSearchModal";
import ProjectSidebar from "../component/ProjectSidebar";
import PartnerProjectReviewModal from "../component/PartnerProjectReviewModal";

function ProjectPartner() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activePartner, setActivePartner] = useState(null);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const deletePartner = async (projectPartnerId) => {
    Swal.fire({
      title: "협럭업체를 삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/project/partner/${projectPartnerId}`);
        if (res.status === 200) {
          getList();
        } else {
          Swal.fire({
            icon: "error",
            title: "삭제 실패",
            text: "다시 시도해주세요.",
          });
        }
      }
    });
  };

  const getList = async () => {
    setLoading(true);
    const res = await $get(`/api/project/partner/${projectId}`);
    if (res.status === 200) {
      setPartnerList(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getList();
      }
    });
  }, [projectId]);

  return (
    <section className="container pt-3">
      <div className="row">
        <ProjectSidebar projectId={projectId} />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">프로젝트 협력업체 관리</h1>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setActivePartner(null);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                추가
              </button>
            </div>

            <div className="table-responsive">
              <table className="table table-hover align-middle text-nowrap">
                <thead>
                  <tr>
                    <th className="border-top-0">업체명</th>
                    <th className="border-top-0">유형</th>
                    <th className="border-top-0">전문분야</th>
                    <th className="border-top-0">전화번호</th>
                    <th className="border-top-0">주소</th>
                    <th className="border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {partnerList.map((partner, index) => (
                    <tr key={index}>
                      <td>{partner.partner_name}</td>
                      <td>{partner.partner_type}</td>
                      <td>{partner.partner_speciality}</td>
                      <td>{partner.phone_number}</td>
                      <td>{partner.address}</td>
                      <td className="d-flex gap-1">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary"
                          data-tooltip-id="btn-review-tooltip"
                          onClick={() => {
                            setActivePartner(partner);
                            setShowReviewModal(true);
                          }}
                        >
                          <i className="fa-regular fa-star"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-warning"
                          data-tooltip-id="btn-view-tooltip"
                          onClick={() => {
                            setActivePartner(partner);
                            setShowModal(true);
                          }}
                        >
                          <i className="fa-regular fa-eye"></i>
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-sm btn-outline-dark"
                          data-tooltip-id="btn-note-tooltip"
                          onClick={() => {
                            setActivePartner(partner);
                            setShowModal(true);
                          }}
                        >
                          <i className="fa-regular fa-note-sticky"></i>
                        </button> */}

                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          data-tooltip-id="btn-delete-tooltip"
                          onClick={() => {
                            deletePartner(partner.project_partner_id);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <PartnerSearchModal
          projectId={projectId}
          onClose={() => setShowModal(false)}
          onList={() => {
            getList();
            setShowModal(false);
          }}
        />
      )}

      {showReviewModal && (
        <PartnerProjectReviewModal
          partner={activePartner}
          projectId={projectId}
          onClose={() => setShowReviewModal(false)}
          onList={() => {
            getList();
            setActivePartner(null);
            setShowReviewModal(false);
          }}
        />
      )}
    </section>
  );
}

export default ProjectPartner;
