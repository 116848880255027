import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put, $toDate } from "../utils/common";
import Swal from "sweetalert2";

function ProjectCostModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [costCategoryList, setCostCategoryList] = useState([]);
  const [costSubcategoryList, setCostSubcategoryList] = useState([]);
  const [selectedCostCategoryId, setSelectedCostCategoryId] = useState(0);
  const [projectCost, setProjectCost] = useState(null);

  const doCreate = async () => {
    // if (projectCost.architecture_process_id === "") {
    //   Swal.fire({
    //     icon: "error",
    //     title: "실패",
    //     text: "공정을 선택하세요.",
    //   });
    //   return;
    // }

    if (projectCost.cost_category_id === "") {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "비용 항목 분류를 선택하세요.",
      });
      return;
    }

    if (!projectCost.cost_subcategory_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "하위 비용 항목 분류를 선택하세요.",
      });
      return;
    }

    if (!projectCost.cost_item) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "비용 항목을 입력하세요.",
      });
      return;
    }

    if (!projectCost.unit_price) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "단가를 입력하세요.",
      });
      return;
    }

    if (!projectCost.qty) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "수량을 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "비용 항목 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/cost", projectCost);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "비용 항목이 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "비용 항목 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    // if (projectCost.architecture_process_id === "") {
    //   Swal.fire({
    //     icon: "error",
    //     title: "실패",
    //     text: "공정을 선택하세요.",
    //   });
    //   return;
    // }

    if (projectCost.cost_category_id === "") {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "비용 항목 분류를 선택하세요.",
      });
      return;
    }

    if (!projectCost.cost_subcategory_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "하위 비용 항목 분류를 선택하세요.",
      });
      return;
    }

    if (!projectCost.cost_item) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "비용 항목을 입력하세요.",
      });
      return;
    }

    if (!projectCost.unit_price) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "단가를 입력하세요.",
      });
      return;
    }

    if (!projectCost.qty) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "수량을 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "비용 항목 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/cost/${projectCost.project_cost_id}`, {
          project_cost_name: projectCost.project_cost_name,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "비용 항목이 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "비용 항목 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  const getCostCategoryList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/cost-category/all");
    if (res.status === 200) {
      setCostCategoryList(res.data);
    }

    setLoading(false);
  };

  const getCostSubcategoryList = async (costCategoryId) => {
    const res = await $get(
      `/api/masterdata/cost-subcategory/${costCategoryId}`
    );
    if (res.status === 200) {
      setCostSubcategoryList(res.data);
    }
  };

  // useEffect(() => {
  //   console.log("selectedCostCategoryId", selectedCostCategoryId);
  //   if (selectedCostCategoryId === 0) {
  //     return;
  //   }
  //   getCostSubcategoryList();
  // }, [selectedCostCategoryId]);

  useEffect(() => {
    getCostCategoryList();
    if (props.projectCost) {
      getCostSubcategoryList(props.projectCost.cost_category_id);
      setProjectCost(props.projectCost);
    } else {
      setProjectCost({
        project_id: props.projectId,
        cost_item: "",
        unit_price: 0,
        qty: 1,
        tax: 0,
        total_amount: 0,
        cost_date: new Date().toISOString().slice(0, 10),
      });
    }
    btnRef.current.click();
  }, [props.projectCost]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#projectCostModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="projectCostModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">프로젝트 비용 항목</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {projectCost && (
              <div className="modal-body">
                <div className="row row-cols-1 row-cols-md-2 gx-3 gx-lg-4">
                  <div className="col mb-3">
                    <label
                      htmlFor="costCategory"
                      className="form-label required"
                    >
                      비용 항목 분류
                    </label>
                    <select
                      className="form-select"
                      id="costCategory"
                      value={projectCost.cost_category_id}
                      onChange={(e) => {
                        getCostSubcategoryList(e.target.value);
                        setProjectCost({
                          ...projectCost,
                          cost_category_id: e.target.value,
                        });
                      }}
                    >
                      <option value="">전체</option>
                      {costCategoryList.map((item, index) => (
                        <option key={index} value={item.cost_category_id}>
                          {item.cost_category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col mb-3">
                    <label
                      htmlFor="costSubcategory"
                      className="form-label required"
                    >
                      하위 비용 항목 분류
                    </label>
                    <select
                      className="form-select"
                      id="costSubcategory"
                      value={projectCost.cost_subcategory_id}
                      onChange={(e) => {
                        setProjectCost({
                          ...projectCost,
                          cost_subcategory_id: e.target.value,
                        });
                      }}
                    >
                      <option value="">전체</option>
                      {costSubcategoryList.map((item, index) => (
                        <option key={index} value={item.cost_subcategory_id}>
                          {item.cost_subcategory_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* 날짜 */}
                  <div className="col mb-3">
                    <label
                      htmlFor="projectCost"
                      className="form-label required"
                    >
                      날짜
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="projectCost"
                      value={$toDate(projectCost.cost_date)}
                      onChange={(e) => {
                        setProjectCost({
                          ...projectCost,
                          cost_date: e.target.value,
                        });
                      }}
                      placeholder="날짜를 입력하세요."
                    />
                  </div>

                  <div className="col mb-3">
                    <label
                      htmlFor="projectCost"
                      className="form-label required"
                    >
                      비용 항목
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="projectCost"
                      value={projectCost.cost_item}
                      onChange={(e) => {
                        setProjectCost({
                          ...projectCost,
                          cost_item: e.target.value,
                        });
                      }}
                      placeholder="비용 항목을 입력하세요."
                    />
                  </div>

                  {/* 단가 */}
                  <div className="col mb-3">
                    <label
                      htmlFor="projectCost"
                      className="form-label required"
                    >
                      단가
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="projectCost"
                      value={projectCost.unit_price}
                      onChange={(e) => {
                        setProjectCost({
                          ...projectCost,
                          unit_price: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        // 세액 = 단가 * 수량 * 0.1, 소수점 없이 정수로 계산
                        const tax = Math.floor(
                          projectCost.unit_price * projectCost.qty * 0.1
                        );
                        // 합계 = (단가 * 수량) + 세액
                        const total_amount =
                          parseInt(projectCost.unit_price) *
                            parseInt(projectCost.qty) +
                          parseInt(tax);
                        setProjectCost({
                          ...projectCost,
                          tax: parseInt(tax),
                          total_amount: total_amount,
                        });
                      }}
                      placeholder="단가를 입력하세요."
                    />
                  </div>
                  {/* 수량 */}
                  <div className="col mb-3">
                    <label
                      htmlFor="projectCost"
                      className="form-label required"
                    >
                      수량
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="projectCost"
                      value={projectCost.qty}
                      onChange={(e) => {
                        setProjectCost({
                          ...projectCost,
                          qty: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        const qty = e.target.value === "" ? 1 : e.target.value;
                        // 세액 = 단가 * 수량 * 0.1, 소수점 없이 정수로 계산
                        const tax = Math.floor(
                          projectCost.unit_price * parseInt(qty) * 0.1
                        );
                        // 합계 = (단가 * 수량) + 세액
                        const total_amount =
                          parseInt(projectCost.unit_price) * parseInt(qty) +
                          parseInt(tax);
                        setProjectCost({
                          ...projectCost,
                          tax: parseInt(tax),
                          qty: parseInt(qty),
                          total_amount: total_amount,
                        });
                      }}
                      placeholder="수량을 입력하세요."
                    />
                  </div>
                  {/* 세액 */}
                  <div className="col mb-3">
                    <label
                      htmlFor="projectCost"
                      className="form-label required"
                    >
                      세액
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="projectCost"
                      value={projectCost.tax}
                      onChange={(e) => {
                        setProjectCost({
                          ...projectCost,
                          tax: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        const tax = e.target.value === "" ? 0 : e.target.value;
                        // 합계 = (단가 * 수량) + 세액
                        const total_amount =
                          parseInt(projectCost.unit_price) *
                            parseInt(projectCost.qty) +
                          parseInt(tax);
                        setProjectCost({
                          ...projectCost,
                          tax: parseInt(tax),
                          total_amount: total_amount,
                        });
                      }}
                    />
                  </div>
                  {/* 합계 */}
                  <div className="col mb-3">
                    <label htmlFor="projectCost" className="form-label">
                      합계
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="projectCost"
                      value={projectCost.total_amount}
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (projectCost && projectCost.project_cost_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {projectCost && projectCost.project_cost_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCostModal;
