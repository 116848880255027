import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import MySidebar from "../component/MySidebar";
import QuoteModal from "../component/QuoteModal";

function MyProfile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { isLoggedIn, logout, userData, setUserData } = useAuth();
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const deleteAccount = async () => {
    Swal.fire({
      title: "정말로 탈퇴하시겠습니까?",
      text: "회원 탈퇴 시 모든 정보가 삭제됩니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "네",
      cancelButtonText: "아니요",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $delete("/api/user");
        setLoading(false);
        console.log(res);
        if (res.status === 200) {
          logout();
        } else {
          Swal.fire({
            title: "회원 탈퇴",
            text: "회원 탈퇴에 실패했습니다.",
            icon: "error",
          });
        }
      }
    });
  };

  const saveProfile = async () => {
    if (userName === "") {
      Swal.fire({
        title: "이름을 입력해주세요.",
        icon: "error",
      });
      return;
    }

    if (userPhone === "") {
      Swal.fire({
        title: "연락처를 입력해주세요.",
        icon: "error",
      });
      return;
    }

    // 저장하시겠습니까?
    Swal.fire({
      title: "저장하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "네",
      cancelButtonText: "아니요",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put("/api/user", {
          name: userName,
          phone: userPhone,
        });
        if (res.status === 200) {
          Swal.fire({
            title: "저장되었습니다.",
            icon: "success",
          });
          setUserData({
            ...userData,
            name: userName,
            phone: userPhone,
          });
        } else {
          Swal.fire({
            title: "저장에 실패했습니다.",
            icon: "error",
          });
        }
        setEditMode(false);
      }
    });
  };

  useEffect(() => {
    setUserName(userData.name);
    setUserPhone(userData.phone);
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <MySidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">내 프로필</h1>
            </div>

            <div className="card mb-4 shadow-sm">
              <div className="card-body">
                <div className="mb-4">
                  <h5 className="mb-0">회원 정보</h5>
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label">이름</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userName}
                          disabled={!editMode}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label">연락처</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userPhone}
                          disabled={!editMode}
                          onChange={(e) => setUserPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label">이메일</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userData?.email}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {/* eidtMode 버튼 */}
                  {/* {!editMode ? (
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => setEditMode(!editMode)}
                    >
                      수정
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        saveProfile();
                      }}
                    >
                      저장
                    </button>
                  )} */}
                </div>
              </div>
            </div>
            <div className="card border-danger bg-danger bg-opacity-10 mb-4 shadow-sm">
              <div className="card-body p-lg-5">
                <div className="mb-4">
                  <h5 className="mb-0">회원 탈퇴</h5>
                </div>
                <ul className="list-unstyled mb-3">
                  <li className="mb-2">1. 모든 정보가 영구히 삭제됩니다.</li>
                  <li className="mb-2">
                    2. 삭제된 데이터는 복구가 불가능합니다.
                  </li>
                </ul>

                <div className="mt-3">
                  <a
                    onClick={() => {
                      deleteAccount();
                    }}
                    className="btn btn-danger"
                  >
                    회원 탈퇴
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MyProfile;
