import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import WorkerProjectReviewModal from "../component/WorkerProjectReviewModal";

const currentYear = new Date().getFullYear();
const latest10Years = Array.from({ length: 10 }, (_, i) => currentYear - i);

function ProjectWorker() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [workerList, setWorkerList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [monthDays, setMonthDays] = useState([]);
  const [workResult, setWorkResult] = useState([]);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [activeWorker, setActiveWorker] = useState(null);

  const getProcessList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/construction-process");
    if (res.status === 200) {
      setProcessList(res.data);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
    setLoading(false);
  };

  const getWorkerList = async () => {
    setLoading(true);

    const res = await $get(`/api/project/worker/${projectId}`);
    if (res.status === 200) {
      const tempWorkerList = res.data.map((worker) => ({
        ...worker,
        work_date: $toDate(worker.work_date),
      }));

      setWorkerList(tempWorkerList);
    }

    setLoading(false);
  };

  const isInSelectedYearMonth = (date) => {
    const selectedDate = new Date(`${selectedYear}-${selectedMonth}-01`);
    const nextMonth = new Date(selectedDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    return new Date(date) >= selectedDate && new Date(date) < nextMonth;
  };

  const makeWorkResult = () => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const result = {};
    workerList.forEach((worker) => {
      if (!result[worker.worker_id]) {
        result[worker.worker_id] = {
          worker_id: worker.worker_id,
          worker_name: worker.worker_name,
          total_work_days_count: 0,
          selected_yearmonth_work_days_count: 0,
          selected_yearmonth_work_per_day: Array.from(
            { length: daysInMonth },
            () => 0
          ),
        };
      }

      if (isInSelectedYearMonth(worker.work_date)) {
        result[worker.worker_id].selected_yearmonth_work_days_count += 1;
        result[worker.worker_id].selected_yearmonth_work_per_day[
          new Date(worker.work_date).getDate() - 1
        ] = worker.work_hours;
      }
      result[worker.worker_id].total_work_days_count += 1;
    });

    setWorkResult(Object.values(result));
  };

  useEffect(() => {
    if (workerList.length > 0) {
      makeWorkResult();
    }
  }, [workerList]);

  useEffect(() => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    setMonthDays(days);

    if (workerList.length > 0) {
      makeWorkResult();
    }
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getWorkerList();
      }
    });
  }, [projectId]);

  return (
    <section className="container pt-3">
      <div className="row">
        <ProjectSidebar projectId={projectId} />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">인력 투입 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              {/* <select className="form-select">
                <option value="-1">프로세스 선택</option>
                {processList.map((process, index) => (
                  <option
                    key={process.construction_process_id}
                    value={process.construction_process_id}
                  >
                    {process.construction_process_name}
                  </option>
                ))}
              </select>
              <button className="btn btn-primary" type="button">
                <i className="fa fa-solid fa-plus me-2"></i>
                인력 추가
              </button> */}
              {/* <select className="form-select" style={{ maxWidth: "200px" }}>
                <option value="-1">프로세스 선택</option>
                {processList.map((process, index) => (
                  <option
                    key={process.construction_process_id}
                    value={process.construction_process_id}
                  >
                    {process.construction_process_name}
                  </option>
                ))}
              </select> */}
              <select
                className="form-select"
                style={{ maxWidth: "140px" }}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                {latest10Years.map((year) => (
                  <option key={year} value={year}>{`${year}년`}</option>
                ))}
              </select>
              <div className="input-group">
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => {
                    if (parseInt(selectedMonth) === 1) {
                      setSelectedYear(selectedYear - 1);
                      setSelectedMonth(12);
                    } else {
                      setSelectedMonth(selectedMonth - 1);
                    }
                  }}
                >
                  <i className="fa-solid fa-chevron-left"></i>
                </button>
                <select
                  className="form-select"
                  style={{ maxWidth: "90px" }}
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                    <option key={month} value={month}>{`${month}월`}</option>
                  ))}
                </select>
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => {
                    if (parseInt(selectedMonth) === 12) {
                      setSelectedYear(selectedYear + 1);
                      setSelectedMonth(1);
                    } else {
                      setSelectedMonth(selectedMonth + 1);
                    }
                  }}
                  disabled={
                    selectedYear === currentYear &&
                    selectedMonth === new Date().getMonth() + 1
                  }
                >
                  <i className="fa-solid fa-chevron-right"></i>
                </button>
              </div>
            </div>

            <div className="content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table className="table text-nowrap table-bordered">
                      <thead>
                        <tr className="table-dark">
                          <th className="text-white">기술자명</th>
                          <th className="text-white">총투입일수</th>
                          <th className="text-white">
                            {selectedMonth}월투입일수
                          </th>
                          {monthDays.map((day) => (
                            <th
                              key={day}
                              className="text-white"
                            >{`${day}일`}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {workResult.map((worker, index) => (
                          <tr key={index}>
                            <td>
                              {worker.worker_name}
                              <i
                                className="fa fa-solid fa-star ms-2 cursor-pointer"
                                onClick={() => {
                                  setActiveWorker(worker);
                                  setShowReviewModal(true);
                                }}
                                data-tooltip-id="btn-review-tooltip"
                              ></i>
                            </td>
                            <td>{worker.total_work_days_count}일</td>
                            <td>
                              {worker.selected_yearmonth_work_days_count}일
                            </td>
                            {worker.selected_yearmonth_work_per_day.map(
                              (hours, index) => (
                                <td key={index}>
                                  {hours === 0 ? (
                                    <i className="fa fa-close text-danger" />
                                  ) : (
                                    <span>{hours}시간</span>
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                        {/* <tr>
                          <td>
                            <a
                              onClick={() => {
                                setActiveWorker(workerList[0]);
                                setShowReviewModal(true);
                              }}
                            >
                              김철수
                            </a>
                          </td>
                          <td>41일</td>
                          <td>14일</td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <div className="half-day">
                              <span className="first-off">
                                <i className="fa fa-check text-success" />
                              </span>{" "}
                              <span className="first-off">
                                <i className="fa fa-close text-danger" />
                              </span>
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-close text-danger" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-close text-danger" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-close text-danger" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-close text-danger" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <div className="half-day">
                              <span className="first-off">
                                <i className="fa fa-close text-danger" />
                              </span>{" "}
                              <span className="first-off">
                                <i className="fa fa-check text-success" />
                              </span>
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-close text-danger" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-close text-danger" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                          <td>
                            <i className="fa fa-check text-success" />{" "}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showReviewModal && (
        <WorkerProjectReviewModal
          projectId={projectId}
          worker={activeWorker}
          onClose={() => setShowReviewModal(false)}
          onList={() => {
            getWorkerList();
            setActiveWorker(null);
            setShowReviewModal(false);
          }}
        />
      )}
    </section>
  );
}

export default ProjectWorker;
