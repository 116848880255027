import React, { useState, useEffect, useRef } from "react";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";

function MaterialSupplierModal(props) {
  const [materialSupplierList, setMaterialSupplierList] = useState([]);
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);

  const getMaterialSupplierList = async () => {
    const res = await $get(
      `/api/material/supplier/${props.material.material_id}`
    );
    if (res.status === 200) {
      const supplierList = res.data.map((supplier) => {
        return {
          ...supplier,
          editMode: false,
          change_price: supplier.original_price,
        };
      });
      setMaterialSupplierList(supplierList);
    }
  };

  const deleteMaterialSupplier = async (supplier) => {
    Swal.fire({
      title: `"${props.material.material_name}"에 대한 공급업체를 제거하시겠습니까?`,
      text: "삭제된 데이터는 복구할 수 없습니다.",
      icon: "warning",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(
          `/api/material/supplier/${supplier.material_supplier_id}`
        );
        if (res.status === 200) {
          Swal.fire("삭제되었습니다.", "", "success");
          getMaterialSupplierList();
          props.onList();
        }
      }
    });
  };

  const changePrice = async (supplier) => {
    Swal.fire({
      title: "공급가격 변경",
      text: "정말 변경하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "변경",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/material/supplier/${supplier.material_supplier_id}`,
          {
            original_price: supplier.change_price,
          }
        );
        if (res.status === 200) {
          Swal.fire("변경되었습니다.", "", "success");
          getMaterialSupplierList();
          props.onList();
        }
      }
    });
  };

  useEffect(() => {
    if (props.material === undefined || props.material === null) return;

    getMaterialSupplierList();
    btnRef.current.click();
  }, [props.material]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#materialSupplierModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="materialSupplierModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                "{props.material.material_name}" 공급 업체
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>공급체명</th>
                    <th>공급가격</th>
                    <th>날짜</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {materialSupplierList.map((supplier, index) => (
                    <tr>
                      <td>{supplier.partner_name}</td>
                      {!supplier.editMode ? (
                        <td>
                          <span>
                            {$convertNumberFormat(supplier.original_price)}
                          </span>
                          <i
                            className="fa-solid fa-edit text-danger cursor-pointer ms-2"
                            onClick={() => {
                              supplier.editMode = true;
                              setMaterialSupplierList([
                                ...materialSupplierList,
                              ]);
                            }}
                          ></i>
                        </td>
                      ) : (
                        <td>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control form-control-sm"
                              value={supplier.change_price}
                              style={{ maxWidth: "120px" }}
                              onChange={(e) => {
                                supplier.change_price = e.target.value;
                                setMaterialSupplierList([
                                  ...materialSupplierList,
                                ]);
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => {
                                changePrice(supplier);
                              }}
                            >
                              <i className="fa fa-check"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-sm"
                              onClick={() => {
                                supplier.editMode = false;
                                setMaterialSupplierList([
                                  ...materialSupplierList,
                                ]);
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </td>
                      )}
                      <td>{$dateTimezone(supplier.update_datetime)}</td>
                      <td className="text-center">
                        {/* <button
                          type="button"
                          className="btn btn-outline-primary btn-sm me-2"
                          onClick={() => {
                            btnCloseRef.current.click();
                          }}
                        >
                          <i className="fa fa-check me-2"></i>
                          대표 공급처로 지정
                        </button> */}
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => {
                            deleteMaterialSupplier(supplier);
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialSupplierModal;
