import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function WorkerTypeModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [workerType, setWorkerType] = useState(null);

  const doCreate = async () => {
    if (!workerType.worker_type) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "인력 역할을 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "인력 역할 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/masterdata/worker-type", workerType);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "인력 역할이 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "인력 역할 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!workerType.worker_type) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "인력 역할을 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "인력 역할 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/masterdata/worker-type/${workerType.worker_type_id}`,
          {
            worker_type: workerType.worker_type,
          }
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "인력 역할이 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "인력 역할 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.workerType) {
      setWorkerType(props.workerType);
    } else {
      setWorkerType({
        worker_type: "",
      });
    }
    btnRef.current.click();
  }, [props.workerType]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#workerTypeModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="workerTypeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">인력 역할</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              {workerType && (
                <div className="mb-3">
                  <label htmlFor="workerType" className="form-label required">
                    인력 역할
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="workerType"
                    value={workerType.worker_type}
                    onChange={(e) => {
                      setWorkerType({
                        ...workerType,
                        worker_type: e.target.value,
                      });
                    }}
                    placeholder="인력 역할을 입력하세요."
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (workerType && workerType.worker_type_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {workerType && workerType.worker_type_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkerTypeModal;
