import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $getLocalDateTime,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import ClientSearchModal from "../component/ClientSearchModal";

function ProjectCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  // state - project_id
  const { projectId } = location.state || { projectId: null };
  const [showClientSearchModal, setShowClientSearchModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [selectedClient, setSelectedClient] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [houseStyleList, setHouseStyleList] = useState([]);
  const [houseTypeList, setHouseTypeList] = useState([]);
  const [houseSiteList, setHouseSiteList] = useState([]);
  const [houseOptionList, setHouseOptionList] = useState([]);
  const [houseFuncList, setHouseFuncList] = useState([]);
  const [houseExteriorList, setHouseExteriorList] = useState([]);
  const [houseRoofList, setHouseRooftList] = useState([]);
  const [project, setProject] = useState({
    project_name: "",
    project_address: "",
    project_budget: "",
    estimated_start_date: "",
    estimated_end_date: "",
    notes: "",
    client_id: "",
    house_style_id: "",
    house_type_id: "",
    house_site_id: "",
    house_option_id: "",
    house_func_id: "",
    house_exterior_id: "",
    house_roof_id: "",
    house_floor: "",
    house_site_size: "",
    house_size: "",
    total_floor_area: "",
    building_to_land_ratio: "",
    floor_area_ratio: "",
  });

  const getProject = async (projectId) => {
    setLoading(true);
    const res = await $get(`/api/project/simple/${projectId}`);
    if (res.status === 200) {
      setProject(res.data);
      if (res.data.client_id) {
        const res2 = await $get(`/api/client/${res.data.client_id}`);
        if (res2.status === 200) {
          setSelectedClient(res2.data);

          const res3 = await $get(
            `/api/project/contact/${projectId}/${res.data.client_id}`
          );
          if (res3.status === 200) {
            setContactList(res3.data);
          }
        }
      }
    }
    setLoading(false);
  };

  const createProject = async () => {
    setLoading(true);

    const res = await $post("/api/project", {
      project_name: project.project_name,
      project_address: project.project_address,
      project_budget: project.project_budget,
      estimated_start_date: $toDate(project.estimated_start_date),
      estimated_end_date: $toDate(project.estimated_end_date),
      notes: project.notes,
    });
    if (res.status === 200) {
      Swal.fire("Success", "프로젝트가 생성되었습니다.", "success");
      const projectId = res.data.insertId;
      getProject(projectId);
      // navigate("/project");
    } else {
      Swal.fire("Error", res.msg, "error");
    }
    setLoading(false);
  };

  const saveProjectInfo = async () => {
    setLoading(true);
    const res = await $put(`/api/project/${project.project_id}`, {
      project_name: project.project_name,
      project_address: project.project_address,
      project_budget: project.project_budget,
      estimated_start_date: $toDate(project.estimated_start_date),
      estimated_end_date: $toDate(project.estimated_end_date),
      notes: project.notes,
    });
    if (res.status === 200) {
      Swal.fire("Success", "프로젝트 정보가 저장되었습니다.", "success");
    } else {
      Swal.fire("Error", "프로젝트 정보를 저장하지 못했습니다.", "error");
    }
  };

  const saveCustomerInfo = async () => {
    if (!project.client_id && !selectedClient) {
      Swal.fire("Warning", "고객을 먼저 선택하세요.", "warning");
      return;
    }

    if (contactList.length > 0) {
      let requiredField = true;
      for (let i = 0; i < contactList.length; i++) {
        if (
          !contactList[i].contact_name ||
          !contactList[i].contact_phone_number
        ) {
          requiredField = false;
        }
      }

      if (!requiredField) {
        Swal.fire(
          "Warning",
          "추가 연락처에 이름과 연락처는 필수 정보입니다. 정보를 입력하거나 입력되지 않은 연락처는 삭제하세요.",
          "warning"
        );
        return;
      }
    }

    setLoading(true);
    if (!project.client_id) {
      const res = await $put(`/api/project/${project.project_id}`, {
        client_id: selectedClient.client_id,
      });

      if (res.status === 200) {
        if (contactList.length > 0) {
          await saveClientContact();
        } else {
          Swal.fire("Success", "프로젝트 정보가 저장되었습니다.", "success");
        }
      } else {
        Swal.fire("Error", "프로젝트 정보를 저장하지 못했습니다.", "error");
      }
    } else {
      if (contactList.length > 0) {
        await saveClientContact();
      } else {
        Swal.fire("Success", "프로젝트 정보가 저장되었습니다.", "success");
      }
    }
  };

  const saveClientContact = async () => {
    setLoading(true);
    const res = await $post(`/api/project/contact/${project.project_id}`, {
      contactList: contactList.map((contact) => [
        project.project_id,
        project.client_id,
        contact.contact_name,
        contact.contact_phone_number,
        contact.notes,
      ]),
    });
    if (res.status === 200) {
      Swal.fire("Success", "프로젝트 정보가 저장되었습니다.", "success");
    } else {
      Swal.fire("Error", "프로젝트 정보를 저장하지 못했습니다.", "error");
    }
    setLoading(false);
  };

  const completeProject = async () => {
    // 프로젝트를 저장하고 프로젝트 생성 상태를 완료로 변경하시겠습니까?
    Swal.fire({
      title: "프로젝트 생성 완료",
      text: "프로젝트를 저장하고 프로젝트 생성 상태를 진행중으로 변경하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "완료",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $put(`/api/project/${project.project_id}`, {
          house_style_id: project.house_style_id,
          house_type_id: project.house_type_id,
          house_site_id: project.house_site_id,
          house_option_id: project.house_option_id,
          house_func_id: project.house_func_id,
          house_exterior_id: project.house_exterior_id,
          house_roof_id: project.house_roof_id,
          house_floor: project.house_floor,
          house_site_size: project.house_site_size,
          house_size: project.house_size,
          total_floor_area: project.total_floor_area,
          building_to_land_ratio: project.building_to_land_ratio,
          floor_area_ratio: project.floor_area_ratio,
          project_status: "등록완료",
        });
        if (res.status === 200) {
          Swal.fire("Success", "프로젝트 생성이 완료되었습니다.", "success");
          navigate("/project");
        } else {
          Swal.fire("Error", "프로젝트 생성을 완료하지 못했습니다.", "error");
        }
        setLoading(false);
      }
    });
  };

  const getHouseStyleList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-style");
    if (res.status === 200) {
      setHouseStyleList(res.data);
    }
    setLoading(false);
  };

  const getHouseTypeList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-type");
    if (res.status === 200) {
      setHouseTypeList(res.data);
    }
    setLoading(false);
  };

  const getHouseSiteList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-site");
    if (res.status === 200) {
      setHouseSiteList(res.data);
    }
    setLoading(false);
  };

  const getHouseOptionList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-option");
    if (res.status === 200) {
      setHouseOptionList(res.data);
    }
    setLoading(false);
  };

  const getHouseFuncList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-func");
    if (res.status === 200) {
      setHouseFuncList(res.data);
    }
    setLoading(false);
  };

  const getHouseExteriorList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-exterior");
    if (res.status === 200) {
      setHouseExteriorList(res.data);
    }
    setLoading(false);
  };

  const getRoofingMaterialList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-roof");
    if (res.status === 200) {
      setHouseRooftList(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (projectId) {
      getProject(projectId);
    }
    getHouseStyleList();
    getHouseTypeList();
    getHouseSiteList();
    getHouseOptionList();
    getHouseFuncList();
    getHouseExteriorList();
    getRoofingMaterialList();
  }, []);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-5">
      <div className="pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
        <h1 className="h2 pt-xl-1 pb-3">프로젝트 생성</h1>
        <div className="steps steps-center steps-sm steps-horizontal-md">
          {/* Step */}
          <div className="step">
            {activeStep === 1 ? (
              <div className="step-number bg-light">
                <span className="position-absolute top-0 start-0 w-100 h-100 rounded-circle bg-faded-success"></span>
                <div className="step-number-inner bg-success text-white">1</div>
              </div>
            ) : (
              <div className="step-number">
                <div className="step-number-inner">1</div>
              </div>
            )}
            <div className="step-body">
              <h5 className="mb-2">프로젝트 정보</h5>
            </div>
          </div>
          {/* Step */}
          <div className="step">
            {activeStep === 2 ? (
              <div className="step-number bg-light">
                <span className="position-absolute top-0 start-0 w-100 h-100 rounded-circle bg-faded-success"></span>
                <div className="step-number-inner bg-success text-white">2</div>
              </div>
            ) : (
              <div className="step-number">
                <div className="step-number-inner">2</div>
              </div>
            )}
            <div className="step-body">
              <h5 className="mb-2">고객 정보</h5>
            </div>
          </div>
          {/* Step */}
          <div className="step">
            {activeStep === 3 ? (
              <div className="step-number bg-light">
                <span className="position-absolute top-0 start-0 w-100 h-100 rounded-circle bg-faded-success"></span>
                <div className="step-number-inner bg-success text-white">3</div>
              </div>
            ) : (
              <div className="step-number">
                <div className="step-number-inner">3</div>
              </div>
            )}
            <div className="step-body">
              <h5 className="mb-2">건축 정보</h5>
            </div>
          </div>
        </div>
        {activeStep === 1 && (
          <div className="mt-5">
            <div
              className="needs-validation border-bottom pb-3 pb-lg-4"
              noValidate=""
            >
              <div className="row pb-2">
                <div className="col-sm-12 mb-4">
                  <label htmlFor="project-name" className="form-label fs-base">
                    프로젝트명
                  </label>
                  <input
                    type="text"
                    id="project-name"
                    className="form-control form-control-lg"
                    value={project.project_name}
                    onChange={(e) =>
                      setProject({ ...project, project_name: e.target.value })
                    }
                  />
                </div>
                <div className="col-sm-12 mb-4">
                  <label
                    htmlFor="project-address"
                    className="form-label fs-base"
                  >
                    대지위치
                  </label>
                  <input
                    type="text"
                    id="project-address"
                    className="form-control form-control-lg"
                    value={project.project_address}
                    onChange={(e) =>
                      setProject({
                        ...project,
                        project_address: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-sm-6 mb-4">
                  <label
                    htmlFor="project-budget"
                    className="form-label fs-base"
                  >
                    전체 예산
                  </label>
                  <input
                    type="text"
                    id="project-budget"
                    className="form-control form-control-lg"
                    value={project.project_budget}
                    onChange={(e) =>
                      setProject({ ...project, project_budget: e.target.value })
                    }
                  />
                </div>
                <div className="col-sm-6 mb-4">
                  <label
                    htmlFor="project-duration"
                    className="form-label fs-base"
                  >
                    공사기간
                  </label>
                  {/* from date to date */}
                  <div className="input-group">
                    <input
                      type="date"
                      id="estimated-start-date"
                      className="form-control form-control-lg"
                      value={$toDate(project.estimated_start_date)}
                      onChange={(e) =>
                        setProject({
                          ...project,
                          estimated_start_date: e.target.value,
                        })
                      }
                    />
                    <span className="input-group-text">~</span>
                    <input
                      type="date"
                      id="estimated-end-date"
                      className="form-control form-control-lg"
                      value={$toDate(project.estimated_end_date)}
                      onChange={(e) =>
                        setProject({
                          ...project,
                          estimated_end_date: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <label htmlFor="notes" className="form-label fs-base">
                    건축주 요구사항{" "}
                    <small className="text-muted">(optional)</small>
                  </label>
                  <textarea
                    id="notes"
                    className="form-control form-control-lg"
                    rows={4}
                    placeholder="고객 요구사항을 입력하세요."
                    value={project.notes}
                    onChange={(e) =>
                      setProject({ ...project, notes: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="d-flex gap-2 mb-3">
                {project && project.project_id ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => {
                      saveProjectInfo();
                    }}
                  >
                    <i className="fa fa-solid fa-save me-2"></i>
                    저장
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => {
                      createProject();
                    }}
                  >
                    <i className="fa fa-solid fa-save me-2"></i>
                    생성
                  </button>
                )}
                {project && project.project_id && (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setActiveStep(2);
                    }}
                  >
                    <i className="fa fa-solid fa-arrow-right me-2"></i>
                    다음
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div className="mt-5">
            <div className="needs-validation pb-2 pb-lg-4" noValidate="">
              <div className="row pb-2">
                {/* 고객 불러오기 */}
                <div className="col-sm-12 mb-4">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={() => {
                        setShowClientSearchModal(true);
                      }}
                    >
                      <i className="fa fa-solid fa-search me-2"></i>
                      고객 선택
                    </button>
                    {showClientSearchModal && (
                      <ClientSearchModal
                        onClose={() => setShowClientSearchModal(false)}
                        onSelect={(client) => {
                          setProject({
                            ...project,
                          });
                          setSelectedClient(client);
                          setShowClientSearchModal(false);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-sm-6 mb-4">
                  <label htmlFor="customer-name" className="form-label fs-base">
                    이름
                  </label>
                  <input
                    type="text"
                    id="customer-name"
                    className="form-control form-control-lg"
                    value={selectedClient ? selectedClient.client_name : ""}
                    disabled
                  />
                </div>
                <div className="col-sm-6 mb-4">
                  <label
                    htmlFor="customer-email"
                    className="form-label fs-base"
                  >
                    이메일
                  </label>
                  <input
                    type="email"
                    id="customer-email"
                    className="form-control form-control-lg"
                    value={selectedClient ? selectedClient.email : ""}
                    disabled
                  />
                </div>
                <div className="col-sm-6 mb-4">
                  <label
                    htmlFor="customer-phone"
                    className="form-label fs-base"
                  >
                    연락처
                  </label>
                  <input
                    type="text"
                    id="customer-phone"
                    className="form-control form-control-lg"
                    value={selectedClient ? selectedClient.phone_number : ""}
                    disabled
                  />
                </div>
                <div className="col-sm-6 mb-4">
                  <label
                    htmlFor="customer-phone"
                    className="form-label fs-base"
                  >
                    고객 유형
                  </label>
                  <input
                    type="text"
                    id="customer-phone"
                    className="form-control form-control-lg"
                    value={selectedClient ? selectedClient.client_type : ""}
                    disabled
                  />
                </div>
                {/* 연락처 추가 - table, 추가 버튼 */}
                <div className="col-12 mb-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <label className="form-label fs-base">추가 연락처</label>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        if (!selectedClient && !project.client_id) {
                          Swal.fire(
                            "Warning",
                            "고객을 먼저 선택하세요.",
                            "warning"
                          );
                          return;
                        }

                        setContactList([
                          ...contactList,
                          {
                            contact_name: "",
                            contact_phone_number: "",
                            notes: "",
                          },
                        ]);
                      }}
                    >
                      <i className="fa fa-solid fa-plus me-2"></i>
                      추가
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>이름</th>
                          <th>연락처</th>
                          <th>비고</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactList.map((contact, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={contact.contact_name}
                                onChange={(e) => {
                                  const newContactList = [...contactList];
                                  newContactList[index].contact_name =
                                    e.target.value;
                                  setContactList(newContactList);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={contact.contact_phone_number}
                                onChange={(e) => {
                                  const newContactList = [...contactList];
                                  newContactList[index].contact_phone_number =
                                    e.target.value;
                                  setContactList(newContactList);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={contact.notes}
                                onChange={(e) => {
                                  const newContactList = [...contactList];
                                  newContactList[index].notes = e.target.value;
                                  setContactList(newContactList);
                                }}
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  const newContactList = [...contactList];
                                  newContactList.splice(index, 1);
                                  setContactList(newContactList);
                                }}
                              >
                                <i className="fa fa-solid fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 mb-3">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setActiveStep(1);
                  }}
                >
                  <i className="fa fa-solid fa-arrow-left me-2"></i>
                  이전
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    saveCustomerInfo();
                  }}
                >
                  <i className="fa fa-solid fa-save me-2"></i>
                  저장
                </button>
                {project && project.project_id && (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setActiveStep(3);
                    }}
                  >
                    <i className="fa fa-solid fa-arrow-right me-2"></i>
                    다음
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {activeStep === 3 && (
          <div className="mt-5">
            <div className="needs-validation pb-2 pb-lg-4" noValidate="">
              <div className="row pb-2">
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label htmlFor="house-style" className="form-label fs-base">
                    건축 스타일
                  </label>
                  <select
                    id="house-style"
                    className="form-select form-select-lg"
                    value={project.house_style_id}
                    onChange={(e) =>
                      setProject({ ...project, house_style_id: e.target.value })
                    }
                  >
                    <option value="">선택하세요</option>
                    {houseStyleList.map((style, index) => (
                      <option
                        key={style.house_style_id}
                        value={style.house_style_id}
                      >
                        {style.house_style}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label htmlFor="house-type" className="form-label fs-base">
                    주택 형태
                  </label>
                  <select
                    id="house-type"
                    className="form-select form-select-lg"
                    value={project.house_type_id}
                    onChange={(e) =>
                      setProject({ ...project, house_type_id: e.target.value })
                    }
                  >
                    <option value="">선택하세요</option>
                    {houseTypeList.map((type, index) => (
                      <option
                        key={type.house_type_id}
                        value={type.house_type_id}
                      >
                        {type.house_type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label htmlFor="house-site" className="form-label fs-base">
                    부지 유형
                  </label>
                  <select
                    id="house-site"
                    className="form-select form-select-lg"
                    value={project.house_site_id}
                    onChange={(e) =>
                      setProject({ ...project, house_site_id: e.target.value })
                    }
                  >
                    <option value="">선택하세요</option>
                    {houseSiteList.map((site, index) => (
                      <option
                        key={site.house_site_id}
                        value={site.house_site_id}
                      >
                        {site.house_site}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label htmlFor="house-option" className="form-label fs-base">
                    추가 옵션
                  </label>
                  <select
                    id="house-option"
                    className="form-select form-select-lg"
                    value={project.house_option_id}
                    onChange={(e) =>
                      setProject({
                        ...project,
                        house_option_id: e.target.value,
                      })
                    }
                  >
                    <option value="">선택하세요</option>
                    {houseOptionList.map((option, index) => (
                      <option
                        key={option.house_option_id}
                        value={option.house_option_id}
                      >
                        {option.house_option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label htmlFor="house-func" className="form-label fs-base">
                    특화된 기능
                  </label>
                  <select
                    id="house-func"
                    className="form-select form-select-lg"
                    value={project.house_func_id}
                    onChange={(e) =>
                      setProject({ ...project, house_func_id: e.target.value })
                    }
                  >
                    <option value="">선택하세요</option>
                    {houseFuncList.map((func, index) => (
                      <option
                        key={func.house_func_id}
                        value={func.house_func_id}
                      >
                        {func.house_func}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="house-exterior"
                    className="form-label fs-base"
                  >
                    외관 재질
                  </label>
                  <select
                    id="house-exterior"
                    className="form-select form-select-lg"
                    value={project.house_exterior_id}
                    onChange={(e) =>
                      setProject({
                        ...project,
                        house_exterior_id: e.target.value,
                      })
                    }
                  >
                    <option value="">선택하세요</option>
                    {houseExteriorList.map((exterior, index) => (
                      <option
                        key={exterior.house_exterior_id}
                        value={exterior.house_exterior_id}
                      >
                        {exterior.house_exterior}
                      </option>
                    ))}
                  </select>
                </div>

                {/* 지붕재, 창호재, 단열재, 내외부 마감재 */}
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label htmlFor="roof-material" className="form-label fs-base">
                    지붕재
                  </label>
                  <select
                    id="roof-material"
                    className="form-select form-select-lg"
                    value={project.house_roof_id}
                    onChange={(e) =>
                      setProject({ ...project, house_roof_id: e.target.value })
                    }
                  >
                    <option value="">선택하세요</option>
                    {houseRoofList.map((roof, index) => (
                      <option
                        key={roof.house_roof_id}
                        value={roof.house_roof_id}
                      >
                        {roof.house_roof}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="window-material"
                    className="form-label fs-base"
                  >
                    창호재
                  </label>
                  <select
                    id="window-material"
                    className="form-select form-select-lg"
                    required=""
                  >
                    <option value="">선택하세요</option>
                  </select>
                </div> */}
                {/* <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="insulation-material"
                    className="form-label fs-base"
                  >
                    단열재
                  </label>
                  <select
                    id="insulation-material"
                    className="form-select form-select-lg"
                    required=""
                  >
                    <option value="">선택하세요</option>
                  </select>
                </div> */}
                {/* <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="interior-material"
                    className="form-label fs-base"
                  >
                    내부 마감재
                  </label>
                  <select
                    id="interior-material"
                    className="form-select form-select-lg"
                    required=""
                  >
                    <option value="">선택하세요</option>
                  </select>
                </div> */}
                {/* <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="exterior-material"
                    className="form-label fs-base"
                  >
                    외부 마감재
                  </label>
                  <select
                    id="exterior-material"
                    className="form-select form-select-lg"
                    required=""
                  >
                    <option value="">선택하세요</option>
                  </select>
                </div> */}

                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label htmlFor="house-floor" className="form-label fs-base">
                    주택 층수
                  </label>
                  <select
                    id="house-floor"
                    className="form-select form-select-lg"
                    value={project.house_floor}
                    onChange={(e) =>
                      setProject({ ...project, house_floor: e.target.value })
                    }
                  >
                    <option value="">선택하세요</option>
                    <option value="1">1층</option>
                    <option value="2">2층</option>
                    <option value="3">3층</option>
                    <option value="4">4층</option>
                    <option value="5">5층</option>
                    <option value="6">6층</option>
                    <option value="7">7층</option>
                    <option value="8">8층</option>
                    <option value="9">9층</option>
                    <option value="10">10층</option>
                  </select>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="house-site-size"
                    className="form-label fs-base"
                  >
                    대지면적
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="house-site-size"
                      className="form-control form-control-lg"
                      value={project.house_site_size}
                      onChange={(e) =>
                        setProject({
                          ...project,
                          house_site_size: e.target.value,
                        })
                      }
                    />
                    <span className="input-group-text">m²</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label htmlFor="house-size" className="form-label fs-base">
                    건축면적
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="house-size"
                      className="form-control form-control-lg"
                      value={project.house_size}
                      onChange={(e) =>
                        setProject({ ...project, house_size: e.target.value })
                      }
                    />
                    <span className="input-group-text">m²</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="total-floor-area"
                    className="form-label fs-base"
                  >
                    연면적
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="total-floor-area"
                      className="form-control form-control-lg"
                      value={project.total_floor_area}
                      onChange={(e) =>
                        setProject({
                          ...project,
                          total_floor_area: e.target.value,
                        })
                      }
                    />
                    <span className="input-group-text">m²</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="building-to-land-ratio"
                    className="form-label fs-base"
                  >
                    건폐율
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="building-to-land-ratio"
                      className="form-control form-control-lg"
                      value={project.building_to_land_ratio}
                      onChange={(e) =>
                        setProject({
                          ...project,
                          building_to_land_ratio: e.target.value,
                        })
                      }
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                  <label
                    htmlFor="floor-area-ratio"
                    className="form-label fs-base"
                  >
                    용적률
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="floor-area-ratio"
                      className="form-control form-control-lg"
                      value={project.floor_area_ratio}
                      onChange={(e) =>
                        setProject({
                          ...project,
                          floor_area_ratio: e.target.value,
                        })
                      }
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>

                <div className="d-flex gap-2 mb-3">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setActiveStep(2);
                    }}
                  >
                    <i className="fa fa-solid fa-arrow-left me-2"></i>
                    이전
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => {
                      completeProject();
                    }}
                  >
                    <i className="fa fa-solid fa-check me-2"></i>
                    완료
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default ProjectCreate;
