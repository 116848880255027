import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ReportSidebar from "../component/ReportSidebar";

function MaterialStatistics() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [materialList, setMaterialList] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [showModal, setShowModal] = useState(false);

  // get house style list
  const [houseStyleList, setHouseStyleList] = useState([]);
  const [houseExteriorList, setHouseExteriorList] = useState([]);
  const [houseRoofList, setHouseRoofList] = useState([]);
  const [houseStyleId, setHouseStyleId] = useState("");
  const [houseExteriorId, setHouseExteriorId] = useState("0");
  const [houseRoofId, setHouseRoofId] = useState("0");

  const getHouseStyleList = async () => {
    const res = await $get("/api/masterdata/house-style");
    if (res.status === 200) {
      setHouseStyleId(res.data[0].house_style_id);
      setHouseStyleList(res.data);
    }
  };

  const getHouseExteriorList = async () => {
    const res = await $get("/api/masterdata/house-exterior");
    if (res.status === 200) {
      setHouseExteriorList(res.data);
    }
  };

  const getHouseRoofList = async () => {
    const res = await $get("/api/masterdata/house-roof");
    if (res.status === 200) {
      setHouseRoofList(res.data);
    }
  };

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    if (!houseStyleId) {
      return;
    }
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    console.log("houseStyleId", houseStyleId);
    console.log("houseExteriorId", houseExteriorId);
    console.log("houseRoofId", houseRoofId);
    const url = `/api/material/statistics/${tempPageNo}/${pageLimit}?houseStyleId=${houseStyleId}&houseExteriorId=${houseExteriorId}&houseRoofId=${houseRoofId}`;
    const res = await $get(url);
    if (res.status === 200) {
      console.log(res.data);
      setMaterialList(res.data.materialList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }

    getHouseStyleList();
    getHouseExteriorList();
    getHouseRoofList();
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <ReportSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div
            className="ps-md-3 ps-lg-0 mt-md-2 py-md-4"
            style={{ minHeight: "700px" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">건축 유형별 자재 통계</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <div className="input-group">
                <select
                  className="form-select"
                  value={houseStyleId}
                  onChange={(e) => setHouseStyleId(e.target.value)}
                >
                  {houseStyleList.map((houseStyle, index) => (
                    <option key={index} value={houseStyle.house_style_id}>
                      {houseStyle.house_style}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select"
                  value={houseExteriorId}
                  onChange={(e) => setHouseExteriorId(e.target.value)}
                >
                  <option value="0">외장 전체</option>
                  {houseExteriorList.map((houseExterior, index) => (
                    <option key={index} value={houseExterior.house_exterior_id}>
                      {houseExterior.house_exterior}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select"
                  value={houseRoofId}
                  onChange={(e) => setHouseRoofId(e.target.value)}
                >
                  <option value="0">지붕 전체</option>
                  {houseRoofList.map((houseRoof, index) => (
                    <option key={index} value={houseRoof.house_roof_id}>
                      {houseRoof.house_roof}
                    </option>
                  ))}
                </select>

                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => getList()}
                >
                  <i className="fa fa-solid fa-search"></i>
                </button>
              </div>
              {/* <button className="btn btn-primary" type="button">
                <i className="fa fa-solid fa-plus me-2"></i>
                자재 추가
              </button> */}
            </div>
            <div className="table-responsive">
              <table className="table table-hover align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">자재명</th>
                    <th className="border-top-0">규격</th>
                    <th className="border-top-0">단위</th>
                    <th className="border-top-0">분류</th>
                    <th className="border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {materialList.map((material, index) => (
                    <tr key={index}>
                      <td>{material.material_name}</td>
                      <td>{material.material_standard}</td>
                      <td>{material.material_unit_name}</td>
                      <td>{material.material_category_name}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          상세보기
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          href="#"
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="bx bx-chevron-left ms-n1 me-1" />
                          Prev
                        </a>
                      </li>
                    )}
                    {pageRange.map((page) => (
                      <li
                        key={page}
                        className={
                          pageNo === page
                            ? "page-item d-none d-sm-block active"
                            : "page-item d-none d-sm-block"
                        }
                      >
                        <a
                          onClick={(e) => setPageNo(page)}
                          className="page-link"
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          onClick={(e) => setPageNo(pageNo + 1)}
                          className="page-link"
                        >
                          Next
                          <i className="bx bx-chevron-right me-n1 ms-1" />
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default MaterialStatistics;
