import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import Chart from "react-apexcharts";

function ProjectDashboard() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole, getProjectRole } =
    useAuth();
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [scheduleData, setScheduleData] = useState(null);
  const [projectCostList, setProjectCostList] = useState([]);
  const [projectWorkerList, setProjectWorkerList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [projectMemberList, setProjectMemberList] = useState([]);
  const [projectArchitectureProcessList, setProjectArchitectureProcessList] =
    useState([]);

  const [projectRole, setProjectRole] = useState(null);

  const getScheduleData = async () => {
    setScheduleData({
      series: [
        {
          data: [
            {
              x: "계약",
              y: [
                new Date("2024-02-27").getTime(),
                new Date("2024-03-04").getTime(),
              ],
              fillColor: "#008FFB",
            },
            {
              x: "설계",
              y: [
                new Date("2024-03-04").getTime(),
                new Date("2024-04-08").getTime(),
              ],
              fillColor: "#00E396",
            },
            {
              x: "인허가",
              y: [
                new Date("2024-03-27").getTime(),
                new Date("2024-04-16").getTime(),
              ],
              fillColor: "#775DD0",
            },
            {
              x: "시공",
              y: [
                new Date("2024-04-16").getTime(),
                new Date("2024-07-12").getTime(),
              ],
              fillColor: "#FEB019",
            },
            {
              x: "준공 및 인도",
              y: [
                new Date("2024-07-12").getTime(),
                new Date("2024-07-20").getTime(),
              ],
              fillColor: "#FF4560",
            },
          ],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            borderRadius: 10,
            dataLabels: {
              hideOverflowingLabels: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            var label = opts.w.globals.labels[opts.dataPointIndex];
            // console.log("val", val);
            // 종료일 - 시작일
            var days =
              Math.floor((val[1] - val[0]) / (1000 * 60 * 60 * 24)) + 1;

            // console.log("days", days);

            return days + "일";
            // return label + ": " + days + "일";
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
          },
        },
        xaxis: {
          type: "datetime",
        },
        // yaxis: {
        //   show: false,
        // },
        grid: {
          row: {
            colors: ["#f3f4f5", "#fff"],
            opacity: 1,
          },
        },
      },
    });
  };

  const getProjectMember = async () => {
    try {
      const res = await $get(`/api/project/member/${projectId}`);
      setProjectMemberList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectPartner = async () => {
    try {
      const res = await $get(`/api/project/partner/${projectId}`);
      setPartnerList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectWorker = async () => {
    try {
      const res = await $get(`/api/diary/worker-sum/${projectId}`);
      setProjectWorkerList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectCost = async () => {
    try {
      const res = await $get(`/api/cost/project/category-sum/${projectId}`);
      setProjectCostList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectArchitectureProcess = async () => {
    const res = await $get(`/api/project/architecture-process/${projectId}`);
    if (res.status === 200) {
      setProjectArchitectureProcessList(res.data);
    }
  };

  const saveProjectArchitectureProcess = async (process) => {
    try {
      const res = await $put(
        `/api/project/architecture-process/${process.project_architecture_process_id}`,
        {
          start_date: process.start_date ? $toDate(process.start_date) : null,
          end_date: process.end_date ? $toDate(process.end_date) : null,
          status: process.status,
        }
      );
      if (res.status === 200) {
        getProjectArchitectureProcess();
        Swal.fire({
          icon: "success",
          title: "저장 성공",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getProjectRole(projectId).then((res) => {
          setProjectRole(res);
        });
        getScheduleData();
        getProjectCost();
        getProjectWorker();
        getProjectPartner();
        getProjectMember();
        getProjectArchitectureProcess();
      }
    });
  }, [projectId]);

  // useEffect(() => {
  //   if (!projectId) {
  //     navigate("/project");
  //   }
  //   getScheduleData();
  //   getProjectCost();
  //   getProjectWorker();
  //   getProjectPartner();
  //   getProjectMember();
  //   getProjectArchitectureProcess();
  // }, []);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          {/* <h2 className="h5 pt-1 pt-lg-3 my-4">프로젝트 일정</h2> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-4">
                <div className="card-body">
                  <h3 className="h5">프로젝트 진행 현황</h3>

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>과정</th>
                          <th>시작일</th>
                          <th>종료일</th>
                          <th>진행 상황</th>
                          {projectRole !== "client" && <th></th>}
                        </tr>
                      </thead>
                      <tbody>
                        {projectArchitectureProcessList.map(
                          (process, index) => (
                            <tr key={index}>
                              <td>{process.architecture_process_name}</td>
                              <td>
                                {projectRole === "client" ? (
                                  <span>
                                    {process.start_date
                                      ? $toDate(process.start_date)
                                      : ""}
                                  </span>
                                ) : (
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      process.start_date
                                        ? $toDate(process.start_date)
                                        : ""
                                    }
                                    onChange={(e) => {
                                      process.start_date = e.target.value;
                                      setProjectArchitectureProcessList([
                                        ...projectArchitectureProcessList,
                                      ]);
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {projectRole === "client" ? (
                                  <span>
                                    {process.end_date
                                      ? $toDate(process.end_date)
                                      : ""}
                                  </span>
                                ) : (
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      process.end_date
                                        ? $toDate(process.end_date)
                                        : ""
                                    }
                                    onChange={(e) => {
                                      process.end_date = e.target.value;
                                      setProjectArchitectureProcessList([
                                        ...projectArchitectureProcessList,
                                      ]);
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {/* select- 시작전, 진행중, 완료 */}
                                {projectRole === "client" ? (
                                  <span>{process.status}</span>
                                ) : (
                                  <select
                                    className="form-control"
                                    value={process.status}
                                    onChange={(e) => {
                                      process.status = e.target.value;
                                      setProjectArchitectureProcessList([
                                        ...projectArchitectureProcessList,
                                      ]);
                                    }}
                                  >
                                    <option value="시작전">시작전</option>
                                    <option value="진행중">진행중</option>
                                    <option value="완료">완료</option>
                                  </select>
                                )}
                              </td>
                              {projectRole === "client" ? (
                                ""
                              ) : (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      saveProjectArchitectureProcess(process)
                                    }
                                  >
                                    저장
                                  </button>
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            {scheduleData != null && (
              <Chart
                options={scheduleData.options}
                series={scheduleData.series}
                type="rangeBar"
                height={350}
              />
            )}
          </div> */}
          <div className="row">
            <div className="col-lg-6">
              <div className="card mt-2">
                <div className="card-body">
                  <h3 className="h5">실시간 비용 현황</h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>카테고리</th>
                          <th>금액(원)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectCostList.map((cost, index) => (
                          <tr key={index}>
                            <td>{cost.cost_category_name}</td>
                            <td>{$convertNumberFormat(cost.total_amount)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mt-2">
                <div className="card-body">
                  <h3 className="h5">투입 인력 현황</h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>이름</th>
                          <th>총근무일수</th>
                          <th>총근무시간</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectWorkerList.map((worker, index) => (
                          <tr key={index}>
                            <td>{worker.worker_name}</td>
                            <td>{$convertNumberFormat(worker.work_days)}</td>
                            <td>{$convertNumberFormat(worker.work_hours)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mt-2">
                <div className="card-body">
                  <h3 className="h5">협력업체 현황</h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>업체명</th>
                          <th>전문분야</th>
                          <th>전화번호</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partnerList.map((partner, index) => (
                          <tr key={index}>
                            <td>{partner.partner_name}</td>
                            <td>{partner.partner_speciality}</td>
                            <td>{partner.phone_number}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mt-2">
                <div className="card-body">
                  <h3 className="h5">프로젝트 멤버</h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>멤버 이름</th>
                          <th>역할</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectMemberList.map((member, index) => (
                          <tr key={index}>
                            <td>{member.member_name}</td>
                            <td>
                              {member.role_level === "project_manager"
                                ? "프로젝트 관리자"
                                : member.role_level === "client"
                                ? "건축주(고객)"
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectDashboard;
