import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import PortfolioImageUploadModal from "../component/PortfolioImageUploadModal";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { RowsPhotoAlbum, MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import "react-photo-album/masonry.css";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function Portfolio() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const { projectId, projectDiaryId } = useParams();

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);
  const [slides, setSlides] = useState([]);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [portfolioList, setPortfolioList] = useState([]);

  const handleDeletePhoto = (photo) => {
    Swal.fire({
      title: "사진 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/portfolio/${photo.id}`);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "사진이 삭제되었습니다.",
          });
          getPortfolioList();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "사진 삭제에 실패했습니다.",
          });
        }
      }
    });
  };

  // 이미지 주소에서 이미지 width, height 추출
  const getImgSize = (url) => {
    var img = new Image();
    img.src = url;
    return { width: img.width, height: img.height };
  };

  const getPortfolioList = async () => {
    const res = await $get(`/api/portfolio`);
    if (res.status === 200) {
      setPortfolioList(res.data);
      setSlides(
        res.data.map((item) => ({
          src: item.img_src
            ? `${serverUrl}/static/images/${item.img_src.split("/").pop()}`
            : "assets/images/bg/house_blank2.png",
          title: item.title,
          id: item.portfolio_id,
          width: item.img_width,
          height: item.img_height,
          srcSet: breakpoints.map((bp) => ({
            src: `${serverUrl}/static/images/${item.img_src.split("/").pop()}`,
            width: bp,
            height: Math.round((item.img_height / item.img_width) * bp),
          })),
        }))
      );
    }
  };

  useEffect(() => {
    getPortfolioList();
  }, []);

  return (
    <section className="container pt-5">
      <div className="row">
        <div className="col-12 offset-lg-1 pb-5 mb-lg-2 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div
              className="needs-validation border-bottom pb-3 pb-lg-4"
              noValidate=""
            >
              {userData &&
                (userData.role_level === "system_admin" ||
                  userData.role_level === "company_admin") && (
                  <div className="d-grid mb-2">
                    <button
                      type="button"
                      className="btn btn-dark btn-lg"
                      onClick={() => {
                        setShowImageUploadModal(true);
                      }}
                    >
                      <i className="fa-regular fa-image me-2"></i>
                      사진 추가
                    </button>
                  </div>
                )}
              {userData &&
              userData.role_level === "client" &&
              slides.length > 0 ? (
                <RowsPhotoAlbum
                  photos={slides}
                  onClick={({ index: current }) => setIndex(current)}
                />
              ) : (
                <RowsPhotoAlbum
                  photos={slides}
                  render={{
                    extras: (_, { photo, index }) => (
                      <button
                        className="btn btn-sm btn-danger position-absolute top-0 end-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeletePhoto(photo);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    ),
                  }}
                  onClick={({ index: current }) => setIndex(current)}
                />
              )}

              {slides.length > 0 && (
                <Lightbox
                  index={index}
                  slides={slides}
                  open={index >= 0}
                  plugins={[
                    Fullscreen,
                    Slideshow,
                    Captions,
                    Video,
                    Zoom,
                    Thumbnails,
                  ]}
                  close={() => setIndex(-1)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {showImageUploadModal && (
        <PortfolioImageUploadModal
          onClose={() => setShowImageUploadModal(false)}
          onList={() => {
            setShowImageUploadModal(false);
            getPortfolioList();
          }}
        />
      )}
    </section>
  );
}

export default Portfolio;
