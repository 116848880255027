import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $upload,
  $dateTimezone,
  $convertMarkdownToHtml,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";

function Project() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const imageFileRef = useRef();

  const uploadImage = async (imageFile, projectId) => {
    const res = await $upload(`/api/project/image`, imageFile, {
      projectId: projectId,
    });

    if (res.status === 200) {
      Swal.fire("Success", "대표 이미지가 변경 되었습니다.", "success");
      getProjectList();
    }
  };

  const deleteProject = async (projectId) => {
    Swal.fire({
      title: "프로젝트를 삭제하시겠습니까?",
      text: "삭제된 프로젝트는 복구할 수 없습니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "삭제하기",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/project/${projectId}`);
        if (res.status === 200) {
          Swal.fire("Success", "프로젝트가 삭제 되었습니다.", "success");
          getProjectList();
        }
      }
    });
  };

  const getProjectList = async () => {
    setLoading(true);
    const res = await $get(`/api/project`);
    if (res.status === 200) {
      if (userData.role_level === "client") {
        res.data = res.data.filter(
          (project) =>
            project.project_status !== "등록중" && project.status !== "등록완료"
        );
      }
      setProjectList(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getProjectList();
  }, [userData]);

  return (
    <section className="container pt-5">
      <div className="row">
        {/* Account collections */}
        <div className="col-12 offset-lg-1 pb-5 mb-lg-2 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
            <div className="d-sm-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
              <h1 className="h2 mb-sm-0">내 프로젝트</h1>
              {userData &&
                (userData.role_level === "system_admin" ||
                  userData.role_level === "company_admin") && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => navigate("/project-create")}
                  >
                    <i className="fas fa-plus me-2" />
                    프로젝트 추가
                  </button>
                )}
            </div>
            {projectList.length === 0 && (
              <div className="text-center">
                <h3>진행중인 프로젝트가 없습니다.</h3>
              </div>
            )}
            <input
              className="form-control"
              type="file"
              ref={imageFileRef}
              accept=".jpg,.jpeg,.png,.gif"
              style={{ display: "none" }}
              onChange={(e) => {
                uploadImage(e.target.files[0], activeProject.project_id);
              }}
            />
            {/* Item */}
            {projectList.map((project) => (
              <div
                key={project.project_id}
                className="card card-hover border-primary border-0 shadow-sm overflow-hidden mb-4"
              >
                <div className="row g-0">
                  <a
                    className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                    style={{
                      backgroundImage: project.img_src
                        ? `url(${serverUrl}/static/images/${project.img_src
                            .split("/")
                            .pop()})`
                        : "url(assets/images/bg/house_blank2.png)",
                      // maxHeight: "200px",
                    }}
                    aria-label="Cover image"
                  />
                  {/* icon - change image - left top */}
                  {userData && userData.role_level !== "client" && (
                    <div className="position-absolute top-0 start-0 mt-1 ms-1">
                      <a
                        onClick={() => {
                          setActiveProject(project);
                          imageFileRef.current.click();
                        }}
                        className="btn btn-sm btn-icon btn-icon-only btn-soft-light"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Change image"
                      >
                        <i className="fa-regular fa-image text-danger" />
                      </a>
                    </div>
                  )}

                  <div className="col-sm-8">
                    <div className="card-body">
                      <div className="fs-sm text-muted mb-1">
                        {$toDate(project.estimated_start_date)} -{" "}
                        {$toDate(project.estimated_end_date)}
                      </div>
                      <h2 className="h4 pb-1 mb-2">
                        <a onClick={() => navigate("/project-dashboard")}>
                          {project.project_name} / {project.project_status}
                        </a>
                      </h2>
                      <p className="">{project.project_address}</p>
                      <p className="mb-4">
                        <small>{project.notes}</small>
                      </p>
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-outline-primary px-3 px-lg-4 me-3"
                          onClick={() => {
                            if (project.project_status === "등록중") {
                              navigate("/project-create", {
                                state: { projectId: project.project_id },
                              });
                            } else {
                              navigate(`/project-inbox/${project.project_id}`);
                            }
                          }}
                        >
                          상세히보기
                        </button>
                        {/* project.status === "등록중" 인 경우 삭제 버튼 */}
                        {project.project_status === "등록중" && (
                          <button
                            type="button"
                            className="btn btn-outline-danger px-3 px-lg-4 me-3"
                            onClick={() => {
                              deleteProject(project.project_id);
                            }}
                          >
                            삭제
                          </button>
                        )}
                        {/* <button
                          type="button"
                          className="btn btn-outline-secondary px-3 px-lg-4 me-3"
                        >
                          {project.project_status}
                        </button> */}
                        {userData &&
                          userData.role_level === "client" &&
                          project.status === "준공완료" && (
                            <button
                              type="button"
                              className="btn btn-outline-secondary px-3 px-lg-4 me-3"
                            >
                              최종승인하기
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
