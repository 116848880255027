import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function SupplierModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [supplier, setSupplier] = useState(null);

  const doCreate = async () => {
    if (!supplier.supplier_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "공급업체명을 입력하세요.",
      });
      return;
    }

    if (!supplier.contact_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "담당자명을 입력하세요.",
      });
      return;
    }

    if (!supplier.contact_phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "담당자 연락처를 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "공급업체 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/supplier", supplier);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "공급업체가 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "공급업체 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!supplier.supplier_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "공급업체명을 입력하세요.",
      });
      return;
    }

    if (!supplier.contact_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "담당자명을 입력하세요.",
      });
      return;
    }

    if (!supplier.contact_phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "담당자 연락처를 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "공급업체 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // delete create_datetime
        delete supplier.create_datetime;
        const res = await $put(
          `/api/supplier/${supplier.supplier_id}`,
          supplier
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "공급업체가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "공급업체 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.supplier) {
      setSupplier(props.supplier);
    } else {
      setSupplier({
        supplier_name: "",
        contact_name: "",
        contact_phone_number: "",
        contact_email: "",
        supplier_address: "",
        notes: "",
      });
    }
    btnRef.current.click();
  }, [props.supplier]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#supplierModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="supplierModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">공급업체</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {supplier && (
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="supplier_name" className="form-label">
                    공급업체명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="supplier_name"
                    value={supplier.supplier_name}
                    onChange={(e) => {
                      setSupplier({
                        ...supplier,
                        supplier_name: e.target.value,
                      });
                    }}
                    placeholder="공급업체명을 입력하세요."
                  />
                </div>
                {/* 담당자 */}
                <div className="mb-3">
                  <label htmlFor="contact_name" className="form-label">
                    담당자명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact_name"
                    value={supplier.contact_name}
                    onChange={(e) => {
                      setSupplier({
                        ...supplier,
                        contact_name: e.target.value,
                      });
                    }}
                    placeholder="연락처를 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="contact_phone_number" className="form-label">
                    연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact_phone_number"
                    value={supplier.contact_phone_number}
                    onChange={(e) => {
                      setSupplier({
                        ...supplier,
                        contact_phone_number: e.target.value,
                      });
                    }}
                    placeholder="연락처를 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="contact_email" className="form-label">
                    이메일
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="contact_email"
                    value={supplier.contact_email}
                    onChange={(e) => {
                      setSupplier({
                        ...supplier,
                        contact_email: e.target.value,
                      });
                    }}
                    placeholder="이메일을 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="supplier_address" className="form-label">
                    주소
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="supplier_address"
                    value={supplier.supplier_address}
                    onChange={(e) => {
                      setSupplier({
                        ...supplier,
                        supplier_address: e.target.value,
                      });
                    }}
                    placeholder="주소를 입력하세요."
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="note" className="form-label">
                    비고
                  </label>
                  <textarea
                    className="form-control"
                    id="note"
                    value={supplier.notes}
                    onChange={(e) => {
                      setSupplier({
                        ...supplier,
                        notes: e.target.value,
                      });
                    }}
                    placeholder="비고를 입력하세요."
                  ></textarea>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (supplier && supplier.supplier_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {supplier && supplier.supplier_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierModal;
