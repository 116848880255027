import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function MaterialMasterModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [material, setMaterial] = useState(null);
  const [materialCategory, setMaterialCategory] = useState([]);
  const [materialUnit, setMaterialUnit] = useState([]);

  const doCreate = async () => {
    if (!material.material_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "자재명을 입력하세요.",
      });
      return;
    }

    if (!material.material_category_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "자재 분류를 선택하세요.",
      });
      return;
    }

    if (!material.material_unit_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "자재 단위를 선택하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "자재 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/masterdata/material-master", material);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "자재가 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "자재 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!material.material_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "자재명을 입력하세요.",
      });
      return;
    }

    if (!material.material_category_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "자재 분류를 선택하세요.",
      });
      return;
    }

    if (!material.material_unit_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "자재 단위를 선택하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "자재 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/masterdata/material-master/${material.material_master_id}`,
          {
            material_name: material.material_name,
            material_standard: material.material_standard,
            material_category_id: material.material_category_id,
            material_unit_id: material.material_unit_id,
          }
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "자재가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "자재 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  const getMaterialCategory = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/material-category");
    if (res.status === 200) {
      setMaterialCategory(res.data);
    }
    setLoading(false);
  };

  const getMaterialUnit = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/material-unit");
    if (res.status === 200) {
      setMaterialUnit(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.material) {
      setMaterial(props.material);
    } else {
      setMaterial({
        material_name: "",
        material_standard: "",
        material_category_id: "",
        material_unit_id: "",
      });
    }

    getMaterialCategory();
    getMaterialUnit();

    btnRef.current.click();
  }, [props.material]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#materialModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="materialModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">자재</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {material && (
              <div className="modal-body">
                {/* select - 개인, 법인, 기관 */}
                <div className="mb-3">
                  <label
                    htmlFor="material-category"
                    className="form-label required"
                  >
                    자재 분류
                  </label>
                  <select
                    className="form-select"
                    id="material-category"
                    value={material.material_category_id}
                    onChange={(e) => {
                      setMaterial({
                        ...material,
                        material_category_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">선택하세요.</option>
                    {materialCategory.map((item, index) => (
                      <option key={index} value={item.material_category_id}>
                        {item.material_category_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="material-name"
                    className="form-label required"
                  >
                    자재명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="material-name"
                    value={material.material_name}
                    onChange={(e) => {
                      setMaterial({
                        ...material,
                        material_name: e.target.value,
                      });
                    }}
                    placeholder="자재명을 입력하세요."
                  />
                </div>
                {/* 규격: material-unit */}
                <div className="mb-3">
                  <label htmlFor="material-standard" className="form-label">
                    규격
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="material-standard"
                    value={material.material_standard}
                    onChange={(e) => {
                      setMaterial({
                        ...material,
                        material_standard: e.target.value,
                      });
                    }}
                    placeholder="규격을 입력하세요."
                  />
                </div>
                {/* 단위 - material-unit, select */}
                <div className="mb-3">
                  <label
                    htmlFor="material-unit"
                    className="form-label required"
                  >
                    단위
                  </label>
                  <select
                    className="form-select"
                    id="material-unit"
                    value={material.material_unit_id}
                    onChange={(e) => {
                      setMaterial({
                        ...material,
                        material_unit_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">선택하세요.</option>
                    {materialUnit.map((item, index) => (
                      <option key={index} value={item.material_unit_id}>
                        {item.material_unit_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (material && material.material_master_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {material && material.material_master_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialMasterModal;
