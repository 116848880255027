import React, { useState, useEffect, useRef } from "react";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";

function MaterialMasterSearchModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [materialList, setMaterialList] = useState([]);
  const [materialCategory, setMaterialCategory] = useState([]);
  const [selectMaterialCategoryId, setSelectMaterialCategoryId] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    const url = searchText
      ? `/api/masterdata/material-master/${tempPageNo}/${pageLimit}?materialName=${encodeURIComponent(
          searchText
        )}&materialCategoryId=${selectMaterialCategoryId}`
      : `/api/masterdata/material-master/${tempPageNo}/${pageLimit}?materialCategoryId=${selectMaterialCategoryId}`;
    const res = await $get(url);
    if (res.status === 200) {
      setMaterialList(res.data.materialList);
      console.log(res.data.materialList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  const getMaterialCategory = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/material-category");
    if (res.status === 200) {
      setMaterialCategory(res.data);
    }
    setLoading(false);
  };

  const addMaterial = async (material) => {
    const res = await $post("/api/material", {
      material_master_id: material.material_master_id,
    });

    if (res.status === 200) {
      if (res.data.message === "material_master_id already exists") {
        Swal.fire({
          icon: "error",
          title: "이미 등록된 자재입니다.",
        });
      } else {
        props.onList();
        btnCloseRef.current.click();
      }
    }
  };

  const addAllMaterial = async () => {
    // const res = await $post("/api/material/master/add-all");
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    getMaterialCategory();
    btnRef.current.click();
  }, []);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#workerSearchModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="workerSearchModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">자재 마스터</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center gap-2 mb-4">
                <div className="input-group">
                  <select
                    className="form-select"
                    id="material-category"
                    style={{ maxWidth: "200px" }}
                    value={selectMaterialCategoryId}
                    onChange={(e) => {
                      setSelectMaterialCategoryId(e.target.value);
                    }}
                  >
                    <option value="0">전체</option>
                    {materialCategory.map((item, index) => (
                      <option key={index} value={item.material_category_id}>
                        {item.material_category_name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="자재명을 입력하세요."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (e.nativeEvent.isComposing) {
                          return;
                        }

                        getList();
                      }
                    }}
                  />
                  <button
                    className="btn btn-dark"
                    type="button"
                    onClick={() => getList()}
                  >
                    <i className="fa fa-solid fa-search"></i>
                  </button>
                </div>
                <button className="btn btn-primary">전체 추가</button>
              </div>
              <div className="table-responsive">
                <table className="table align-middle">
                  <thead>
                    <tr>
                      <th className="border-top-0">자재명</th>
                      <th className="border-top-0">규격</th>
                      <th className="border-top-0">단위</th>
                      <th className="border-top-0">분류</th>
                      <th className="border-top-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialList.map((material, index) => (
                      <tr key={index}>
                        <td>{material.material_name}</td>
                        <td>{material.material_standard}</td>
                        <td>{material.material_unit_name}</td>
                        <td>{material.material_category_name}</td>

                        <td>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              addMaterial(material);
                            }}
                          >
                            선택
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPage > 1 && (
                <div className="d-flex justify-content-center align-items-center">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {pageNo > 5 && (
                        <li className="page-item">
                          <a
                            href="#"
                            className="page-link"
                            onClick={(e) => setPageNo(pageNo - 1)}
                          >
                            <i className="bx bx-chevron-left ms-n1 me-1" />
                            Prev
                          </a>
                        </li>
                      )}
                      {pageRange.map((page) => (
                        <li
                          key={page}
                          className={
                            pageNo === page
                              ? "page-item d-none d-sm-block active"
                              : "page-item d-none d-sm-block"
                          }
                        >
                          <a
                            onClick={(e) => setPageNo(page)}
                            className="page-link"
                          >
                            {page}
                          </a>
                        </li>
                      ))}

                      {lastPage < totalPage && (
                        <li className="page-item">
                          <a
                            onClick={(e) => setPageNo(pageNo + 1)}
                            className="page-link"
                          >
                            Next
                            <i className="bx bx-chevron-right me-n1 ms-1" />
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialMasterSearchModal;
