import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function PermitCategoryModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [permitCategory, setPermitCategory] = useState(null);

  const doCreate = async () => {
    if (!permitCategory.permit_category_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "인허가 유형을 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "인허가 유형 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post(
          "/api/masterdata/permit-category",
          permitCategory
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "인허가 유형이 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "인허가 유형 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!permitCategory.permit_category) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "인허가 유형을 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "인허가 유형 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/masterdata/permit-category/${permitCategory.permit_category_id}`,
          {
            permit_category_name: permitCategory.permit_category_name,
            description: permitCategory.description,
          }
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "인허가 유형이 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "인허가 유형 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.permitCategory) {
      setPermitCategory(props.permitCategory);
    } else {
      setPermitCategory({
        permit_category_name: "",
        description: "",
      });
    }
    btnRef.current.click();
  }, [props.permitCategory]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#permitCategoryModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="permitCategoryModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">인허가 유형</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {permitCategory && (
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="permitCategory" className="form-label">
                    인허가 유형
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="permitCategory"
                    value={permitCategory.permit_category_name}
                    onChange={(e) => {
                      setPermitCategory({
                        ...permitCategory,
                        permit_category_name: e.target.value,
                      });
                    }}
                    placeholder="인허가 유형을 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    설명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    value={permitCategory.description}
                    onChange={(e) => {
                      setPermitCategory({
                        ...permitCategory,
                        description: e.target.value,
                      });
                    }}
                    placeholder="인허가 유형에 대한 설명을 입력하세요."
                  />
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (permitCategory && permitCategory.permit_category_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {permitCategory && permitCategory.permit_category_id
                  ? "수정"
                  : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermitCategoryModal;
