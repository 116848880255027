import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import PlaceHoderCard from "../component/PlacehoderCard";
import MasterArchitectureSidebar from "../component/MasterArchitectureSidebar";
import PermitCategoryModal from "../component/PermitCategoryModal";

function PermitCategory() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [permitCategoryList, setPermitCategoryList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeProcess, setActiveProcess] = useState(null);

  const deleteProcess = async (id) => {
    Swal.fire({
      title: "시공 프로세스 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/masterdata/permit-category/${id}`, {
          active_yn: "N",
        });
        if (res.status === 200) {
          gePermitCategoryList();
        }
      }
    });
  };

  const downSequence = async (id) => {
    // Swal 순서를 바꾸시겠습니까?
    Swal.fire({
      title: "순서를 바꾸시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // id를 찾아서 sequence_number가 1을 더해야 함
        const currentProcess = permitCategoryList.find(
          (process) => process.permit_category_id === id
        );
        const res = await $put(`/api/masterdata/permit-category/${id}`, {
          sequence_number: currentProcess.sequence_number + 1,
        });
        // sequence_number가 id보다 1 큰 것을 찾아서 sequence_number를 1 감소시킴
        const nextProcess = permitCategoryList.find(
          (process) =>
            process.sequence_number === currentProcess.sequence_number + 1
        );
        const res2 = await $put(
          `/api/masterdata/permit-category/${nextProcess.permit_category_id}`,
          {
            sequence_number: currentProcess.sequence_number,
          }
        );

        gePermitCategoryList();
        Swal.fire({
          icon: "success",
          title: "성공",
          text: "순서가 변경되었습니다.",
        });
      }
    });
  };

  const upSequence = async (id) => {
    Swal.fire({
      title: "순서를 바꾸시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // id를 찾아서 sequence_number가 1을 감소해야 함
        const currentProcess = permitCategoryList.find(
          (process) => process.permit_category_id === id
        );
        const res = await $put(`/api/masterdata/permit-category/${id}`, {
          sequence_number: currentProcess.sequence_number - 1,
        });
        // sequence_number가 id보다 1 작은 것을 찾아서 sequence_number를 1 증가시킴
        const prevProcess = permitCategoryList.find(
          (process) =>
            process.sequence_number === currentProcess.sequence_number - 1
        );
        const res2 = await $put(
          `/api/masterdata/permit-category/${prevProcess.permit_category_id}`,
          {
            sequence_number: currentProcess.sequence_number,
          }
        );

        gePermitCategoryList();
        Swal.fire({
          icon: "success",
          title: "성공",
          text: "순서가 변경되었습니다.",
        });
      }
    });
  };

  const gePermitCategoryList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/permit-category");
    if (res.status === 200) {
      setPermitCategoryList(res.data);
      console.log(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    gePermitCategoryList();
  }, []);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <MasterArchitectureSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">인허가 유형 관리</h1>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setActiveProcess(null);
                  setShowModal(true);
                }}
              >
                <i className="fa-solid fa-plus me-2"></i>
                추가
              </button>
            </div>

            {/* Vertical steps */}
            <div className="steps">
              {!loading &&
                permitCategoryList.map((process, index) => (
                  <div className="step">
                    <div className="step-number">
                      <div className="step-number-inner">
                        {process.sequence_number}
                      </div>
                    </div>
                    <div className="step-body">
                      <h5 className="mb-2">{process.permit_category_name}</h5>
                      <p className="fs-sm mb-0">{process.description}</p>
                      {/* 수정, 삭제 버튼 */}
                      <div className="d-flex mt-3 gap-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => {
                            setActiveProcess(process);
                            setShowModal(true);
                          }}
                        >
                          <i className="fa-solid fa-pen"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                        {index < permitCategoryList.length - 1 && (
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => {
                              downSequence(process.permit_category_id);
                            }}
                          >
                            <i className="fa-solid fa-chevron-down"></i>
                          </button>
                        )}
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => {
                              upSequence(process.permit_category_id);
                            }}
                          >
                            <i className="fa-solid fa-chevron-up"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <PermitCategoryModal
          onClose={() => {
            setActiveProcess(null);
            setShowModal(false);
          }}
          permitCategory={activeProcess}
          onList={() => {
            gePermitCategoryList();
            setActiveProcess(null);
            setShowModal(false);
          }}
        />
      )}
    </section>
  );
}

export default PermitCategory;
