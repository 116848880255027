import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function HouseTypeModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [houseType, setHouseType] = useState(null);

  const doCreate = async () => {
    if (!houseType.house_type) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "주택 형태를 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "주택 형태 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/masterdata/house-type", houseType);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "주택 형태가 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else if (res.status === 400) {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "주택 형태 중복입니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "주택 형태 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!houseType.house_type) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "주택 형태를 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "주택 형태 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/masterdata/house-type/${houseType.house_type_id}`,
          {
            house_type: houseType.house_type,
            active_yn: houseType.active_yn,
          }
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "주택 형태가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else if (res.status === 400) {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "주택 형태 중복입니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "주택 형태 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.houseType) {
      setHouseType(props.houseType);
    } else {
      setHouseType({
        house_type: "",
      });
    }
    btnRef.current.click();
  }, [props.houseType]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#houseTypeModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="houseTypeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">주택 형태</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {houseType && (
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="houseType" className="form-label required">
                    주택 형태
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="houseType"
                    value={houseType.house_type}
                    onChange={(e) => {
                      setHouseType({
                        ...houseType,
                        house_type: e.target.value,
                      });
                    }}
                    placeholder="주택 형태를 입력하세요."
                  />
                </div>
                {/* 활성화 */}
                <div className="mb-3">
                  <label htmlFor="active_yn" className="form-label">
                    활성화
                  </label>
                  <select
                    className="form-select"
                    id="active_yn"
                    value={houseType.active_yn}
                    onChange={(e) => {
                      setHouseType({
                        ...houseType,
                        active_yn: e.target.value,
                      });
                    }}
                  >
                    <option value="Y">활성화</option>
                    <option value="N">비활성화</option>
                  </select>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (houseType && houseType.house_type_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {houseType && houseType.house_type_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HouseTypeModal;
