import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function MasterDocumentSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <aside className="col-lg-2 col-md-4 border-end pb-5 pt-5">
      <div className="position-sticky top-0 pt-3">
        <div className="text-center pt-5">
          <button
            type="button"
            className="btn btn-secondary w-100 d-md-none mt-n2 mb-3"
            data-bs-toggle="collapse"
            data-bs-target="#account-menu"
          >
            <i className="bx bxs-user-detail fs-xl me-2" />
            문서 관리 메뉴
            <i className="bx bx-chevron-down fs-lg ms-1" />
          </button>
          <div
            id="account-menu"
            className="list-group list-group-flush collapse d-md-block"
          >
            <a
              onClick={() => navigate("/master-document-category")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-document-category"
                  ? "active"
                  : ""
              }`}
            >
              <i className="fa-regular fa-folder-open me-2"></i>
              문서 분류 관리
            </a>
            <a
              onClick={() => navigate("/master-document-type")}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname === "/master-document-type" ? "active" : ""
              }`}
            >
              <i className="fa-regular fa-file-lines me-2"></i>
              문서 종류 관리
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default MasterDocumentSidebar;
