import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function PartnerProjectReviewModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState(null);
  const [review, setReview] = useState({
    technical_score: 0,
    technical_review: "",
    diligence_score: 0,
    diligence_review: "",
    responsibility_score: 0,
    responsibility_review: "",
    punctuality_score: 0,
    punctuality_review: "",
  });

  const doSave = async () => {
    if (review.technical_score === 0) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "기술력을 평가해주세요.",
      });
      return;
    }

    if (review.diligence_score === 0) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "성실성을 평가해주세요.",
      });
      return;
    }

    if (review.responsibility_score === 0) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "책임감을 평가해주세요.",
      });
      return;
    }

    if (review.punctuality_score === 0) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "시간약속을 평가해주세요.",
      });
      return;
    }

    Swal.fire({
      title: "평가 저장",
      text: "저장하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "저장",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (review.partner_review_id) {
          const res = await $put(
            `/api/partner/review/${review.partner_review_id}`,
            {
              technical_score: review.technical_score,
              technical_review: review.technical_review,
              diligence_score: review.diligence_score,
              diligence_review: review.diligence_review,
              responsibility_score: review.responsibility_score,
              responsibility_review: review.responsibility_review,
              punctuality_score: review.punctuality_score,
              punctuality_review: review.punctuality_review,
            }
          );
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "성공",
              text: "평가 정보가 저장되었습니다.",
            });
            props.onList();
            btnCloseRef.current.click();
          } else {
            Swal.fire({
              icon: "error",
              title: "실패",
              text: "평가 정보 저장에 실패했습니다.",
            });
          }
        } else {
          const res = await $post(`/api/partner/review`, {
            ...review,
            project_id: props.projectId,
            partner_id: props.partner.partner_id,
          });

          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "성공",
              text: "평가 정보가 저장되었습니다.",
            });
            props.onList();
            btnCloseRef.current.click();
          } else {
            Swal.fire({
              icon: "error",
              title: "실패",
              text: "평가 정보 저장에 실패했습니다.",
            });
          }
        }
      }
    });
  };

  const getReview = async () => {
    const res = await $get(
      `/api/partner/review/detail/${props.projectId}/${props.partner.partner_id}`
    );
    if (res.status === 200) {
      if (res.data.partner_review_id) {
        setReview(res.data);
      } else {
        setReview({
          technical_score: 0,
          technical_review: "",
          diligence_score: 0,
          diligence_review: "",
          responsibility_score: 0,
          responsibility_review: "",
          punctuality_score: 0,
          punctuality_review: "",
        });
      }
    }
  };

  useEffect(() => {
    if (!props.partner) {
      return;
    }

    if (props.projectId) {
      getReview();
    }

    // setPartner(props.partner);
    btnRef.current.click();
  }, [props.partner, props.projectId]);
  return (
    props.partner && (
      <div>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "none" }}
          data-bs-toggle="modal"
          data-bs-target="#partnerModal"
          ref={btnRef}
        ></button>
        <div
          className="modal fade"
          role="dialog"
          id="partnerModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  "{props.partner.partner_name}" 협력업체 평가
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={btnCloseRef}
                  onClick={() => {
                    props.onClose();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3 row">
                  <div className="col-4">
                    <label className="form-label">기술력</label>
                    <div className="d-flex align-items-center">
                      {Array.from({ length: 5 }, (_, i) => (
                        <i
                          key={i}
                          className={
                            review.technical_score > i
                              ? "fa-solid fa-star text-danger"
                              : "fa-regular fa-star"
                          }
                          onClick={() => {
                            setReview({
                              ...review,
                              technical_score: i + 1,
                            });
                          }}
                        ></i>
                      ))}
                    </div>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      rows="3"
                      value={review?.technical_review}
                      onChange={(e) => {
                        setReview({
                          ...review,
                          technical_review: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-4">
                    <label className="form-label">성실성</label>
                    <div className="d-flex align-items-center">
                      {Array.from({ length: 5 }, (_, i) => (
                        <i
                          key={i}
                          className={
                            review.diligence_score > i
                              ? "fa-solid fa-star text-danger"
                              : "fa-regular fa-star"
                          }
                          onClick={() => {
                            setReview({
                              ...review,
                              diligence_score: i + 1,
                            });
                          }}
                        ></i>
                      ))}
                    </div>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      rows="3"
                      value={review?.diligence_review}
                      onChange={(e) => {
                        setReview({
                          ...review,
                          diligence_review: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-4">
                    <label className="form-label">책임감</label>
                    <div className="d-flex align-items-center">
                      {Array.from({ length: 5 }, (_, i) => (
                        <i
                          key={i}
                          className={
                            review.responsibility_score > i
                              ? "fa-solid fa-star text-danger"
                              : "fa-regular fa-star"
                          }
                          onClick={() => {
                            setReview({
                              ...review,
                              responsibility_score: i + 1,
                            });
                          }}
                        ></i>
                      ))}
                    </div>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      rows="3"
                      value={review?.responsibility_review}
                      onChange={(e) => {
                        setReview({
                          ...review,
                          responsibility_review: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-4">
                    <label className="form-label">시간약속</label>
                    <div className="d-flex align-items-center">
                      {Array.from({ length: 5 }, (_, i) => (
                        <i
                          key={i}
                          className={
                            review.punctuality_score > i
                              ? "fa-solid fa-star text-danger"
                              : "fa-regular fa-star"
                          }
                          onClick={() => {
                            setReview({
                              ...review,
                              punctuality_score: i + 1,
                            });
                          }}
                        ></i>
                      ))}
                    </div>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      rows="3"
                      value={review?.punctuality_review}
                      onChange={(e) => {
                        setReview({
                          ...review,
                          punctuality_review: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  닫기
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    doSave();
                  }}
                >
                  저장
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default PartnerProjectReviewModal;
