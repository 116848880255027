import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import Chart from "react-apexcharts";

function ProjectAIBudget() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole, getProjectRole } =
    useAuth();
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [project, setProject] = useState(null);
  const [projectCostList, setProjectCostList] = useState([]);
  const [projectRole, setProjectRole] = useState(null);
  const [costCategoryList, setCostCategoryList] = useState([]);
  const [budgetResult, setBudgetResult] = useState([]);
  const [projectBudget, setProjectBudget] = useState([]);
  const requestBudget = async () => {
    const budgetOptions = {
      house_style_id: project.house_style_id,
      house_exterior_id: project.house_exterior_id,
      house_roof_id: project.house_roof_id,
      house_func_id: project.house_func_id,
      house_option_id: project.house_option_id,
      house_floor: project.house_floor,
      total_floor_area: Math.floor(project.total_floor_area / 3.305785), // 연면적 미터제곱을 평으로 변환
    };

    // 프로젝트를 저장하고 프로젝트 생성 상태를 완료로 변경하시겠습니까?
    Swal.fire({
      title: "AI 예산 산정 실행",
      text: "예산을 산정하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "실행",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // swal loading

        Swal.fire({
          title: "예산 산정 진행 중...",
          text: "잠시만 기다려주세요.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const res = await $post(
          `/api/quote/budget/${projectId}`,
          budgetOptions
        );
        if (res.status === 200) {
          getProjectBudget();

          Swal.fire({
            icon: "success",
            title: "예산 산정이 완료되었습니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "예산 산정에 실패했습니다.",
          });
        }

        setLoading(false);
      }
    });
  };

  const getProjectCost = async () => {
    try {
      const res = await $get(`/api/cost/project/category-sum/${projectId}`);
      setProjectCostList(res.data);
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const getCostCategoryList = async () => {
    const res = await $get(`/api/masterdata/cost-category/all`);
    if (res.status === 200) {
      setCostCategoryList(res.data);
    }
  };

  const getProjectDetail = async () => {
    const res = await $get(`/api/project/${projectId}`);
    if (res.status === 200) {
      setProject(res.data);
    }
  };

  const getProjectBudget = async () => {
    const res = await $get(`/api/project/budget/${projectId}`);
    if (res.status === 200) {
      const res2 = await getProjectCost();
      if (res2.status === 200) {
        const tempProjectCostList = res2.data;
        const tempProjectBudget = res.data.map((item) => {
          const tempCostCategory = tempProjectCostList.find(
            (cost) => cost.cost_category_id === item.cost_category_id
          );
          return {
            ...item,
            real_cost: tempCostCategory ? tempCostCategory.total_amount : 0,
          };
        });
        setProjectBudget(tempProjectBudget);
      }
    }
  };

  const saveBudget = async () => {
    // projectBudget에서 planned_budget 숫자인지 확인
    const isPlannedBudgetNumber = projectBudget.every((item) => {
      return !isNaN(item.planned_budget);
    });
    if (!isPlannedBudgetNumber) {
      Swal.fire({
        icon: "error",
        title: "계획 예산은 숫자만 입력 가능합니다.",
      });
      return;
    }

    Swal.fire({
      title: "예산을 저장하시겠습니까?",
      text: "예산을 저장하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "저장",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/project/budget/${projectId}`,
          projectBudget
        );
        if (res.status === 200) {
          getProjectBudget();
          Swal.fire({
            icon: "success",
            title: "예산 저장이 완료되었습니다.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "예산 저장에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getProjectRole(projectId).then((res) => {
          setProjectRole(res);
        });
        getProjectDetail();
        getProjectBudget();
        getCostCategoryList();
        getProjectDetail();
      }
    });
  }, [projectId]);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">AI 예산 산정</h1>
            </div>
            {/* AI 예산 산정 실행 버튼 - 꽉차게 */}
            <div className="d-grid gap-2 mb-5">
              <button className="btn btn-danger btn-lg" onClick={requestBudget}>
                <i className="fa-solid fa-calculator me-2"></i>
                AI 예산 산정 실행
              </button>
            </div>
            {/* AI 예산 산정 결과 테이블 */}
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>카테고리</th>
                    <th>AI 예상(원)</th>
                    <th style={{ width: "180px" }}>계획(원)</th>
                    <th>실제 비용(원)</th>
                    <th>계획 대비 비용 차이(원)</th>
                  </tr>
                </thead>
                <tbody>
                  {projectBudget.map((budget, index) => (
                    <tr key={index}>
                      <td>{budget.cost_category_name}</td>
                      <td>{$convertNumberFormat(budget.ai_budget)}</td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={budget.planned_budget}
                          onChange={(e) => {
                            const plannedBudget = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            const newBudget = [...projectBudget];
                            if (plannedBudget === "") {
                              newBudget[index].planned_budget = 0;
                            } else {
                              newBudget[index].planned_budget = plannedBudget;
                            }
                            setProjectBudget(newBudget);
                          }}
                        />
                      </td>
                      <td>{$convertNumberFormat(budget.real_cost)}</td>
                      <td>
                        {$convertNumberFormat(
                          budget.planned_budget - budget.real_cost
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="fw-bold">
                    <td>합계</td>
                    <td>
                      {$convertNumberFormat(
                        projectBudget.reduce(
                          (acc, cur) => acc + cur.ai_budget,
                          0
                        )
                      )}
                    </td>
                    <td>
                      {$convertNumberFormat(
                        projectBudget.reduce(
                          (acc, cur) => acc + parseInt(cur.planned_budget),
                          0
                        )
                      )}
                    </td>
                    <td>
                      {$convertNumberFormat(
                        projectBudget.reduce(
                          (acc, cur) => acc + cur.real_cost,
                          0
                        )
                      )}
                    </td>
                    <td>
                      {$convertNumberFormat(
                        projectBudget.reduce(
                          (acc, cur) =>
                            acc + cur.planned_budget - cur.real_cost,
                          0
                        )
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="d-grid gap-2 mb-5">
              <button
                className="btn btn-primary btn-lg"
                onClick={saveBudget}
                disabled={
                  project && project.project_status === "최종승인"
                    ? true
                    : false
                }
              >
                예산 저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectAIBudget;
