import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import PlaceHoderCard from "../component/PlacehoderCard";

function ProjectDiary() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole, getProjectRole } =
    useAuth();
  const [projectRole, setProjectRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [diaryList, setDiaryList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    if (projectId === undefined) return;
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    const url = searchText
      ? `/api/diary/list/${projectId}/${tempPageNo}/${pageLimit}?searchText=${encodeURIComponent(
          searchText
        )}`
      : `/api/diary/list/${projectId}/${tempPageNo}/${pageLimit}`;
    const res = await $get(url);
    if (res.status === 200) {
      setDiaryList(res.data.projectDiaryList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getProjectRole(projectId).then((res) => {
          setProjectRole(res);
        });
      }
    });
  }, [userData]);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-5">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="h2 pt-xl-1 pb-3">작업 일지</h1>
          </div>
          <div className="d-flex align-items-center gap-2 mb-4">
            <div className="input-group">
              <input
                type="search"
                className="form-control"
                placeholder="제목 또는 작업 내용을 입력하세요"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    if (e.nativeEvent.isComposing) {
                      return;
                    }

                    getList();
                  }
                }}
              />
              <button className="btn btn-dark" type="button">
                <i className="fa fa-solid fa-search"></i>
              </button>
            </div>
            {projectRole && projectRole !== "client" && (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  navigate("/project-diary-create", { state: { projectId } });
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                일지 작성
              </button>
            )}
          </div>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">작업일</th>
                  <th scope="col">제목</th>
                </tr>
              </thead>
              <tbody>
                {diaryList.map((diary) => (
                  <tr
                    key={diary.project_diary_id}
                    onClick={() => {
                      navigate(
                        `/project-diary-view/${projectId}/${diary.project_diary_id}`
                      );
                    }}
                    className="cursor-pointer"
                  >
                    <td>{$toDate(diary.work_date)}</td>
                    <td>{diary.title}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {totalPage > 1 && (
            <div className="d-flex justify-content-center align-items-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {pageNo > 5 && (
                    <li className="page-item">
                      <a
                        href="#"
                        className="page-link"
                        onClick={(e) => setPageNo(pageNo - 1)}
                      >
                        <i className="bx bx-chevron-left ms-n1 me-1" />
                        Prev
                      </a>
                    </li>
                  )}
                  {pageRange.map((page) => (
                    <li
                      key={page}
                      className={
                        pageNo === page
                          ? "page-item d-none d-sm-block active"
                          : "page-item d-none d-sm-block"
                      }
                    >
                      <a onClick={(e) => setPageNo(page)} className="page-link">
                        {page}
                      </a>
                    </li>
                  ))}

                  {lastPage < totalPage && (
                    <li className="page-item">
                      <a
                        onClick={(e) => setPageNo(pageNo + 1)}
                        className="page-link"
                      >
                        Next
                        <i className="bx bx-chevron-right me-n1 ms-1" />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ProjectDiary;
