import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import ProjectCostModal from "../component/ProjectCostModal";

function ProjectCost() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData, checkProjectRole, getProjectRole } =
    useAuth();
  const [loading, setLoading] = useState(false);
  const { projectId } = useParams();
  const [projectCostList, setProjectCostList] = useState([]);
  const [architectureProcessList, setArchitectureProcessList] = useState([]);
  const [costCategoryList, setCostCategoryList] = useState([]);
  const [showProjectCostModal, setShowProjectCostModal] = useState(false);
  const [activeProjectCost, setActiveProjectCost] = useState(null);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [projectCostCategory, setProjectCostCategory] = useState([]);
  const [architectureProcessId, setArchitectureProcessId] = useState(0);
  const [costCategoryId, setCostCategoryId] = useState(0);
  const [costItem, setCostItem] = useState("");
  const [projectRole, setProjectRole] = useState(null);

  const deleteProjectCost = async (categoryId) => {
    Swal.fire({
      title: "삭제 확인",
      text: "정말 삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/projectCost/${categoryId}`);
        if (res.status === 200) {
          getList();
        } else {
          Swal.fire("Error", "삭제에 실패했습니다.", "error");
        }
      }
    });
  };

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    // const url = `/api/cost/list/${projectId}/${tempPageNo}/${pageLimit}?architectureProcessId=${architectureProcessId}&costCategoryId=${costCategoryId}&costItem=${encodeURIComponent(
    //   costItem
    // )}`;
    const url = `/api/cost/list/${projectId}/${tempPageNo}/${pageLimit}?costCategoryId=${costCategoryId}&costItem=${encodeURIComponent(
      costItem
    )}`;
    const res = await $get(url);
    if (res.status === 200) {
      setProjectCostList(res.data.projectCostList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  const geArchitectureProcessList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/architecture-process");
    if (res.status === 200) {
      setArchitectureProcessList(res.data);
    }

    setLoading(false);
  };

  const getCostCategoryList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/cost-category/all");
    if (res.status === 200) {
      setCostCategoryList(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    getList();
  }, [costCategoryId]);

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        geArchitectureProcessList();
        getCostCategoryList();
        getList();
        getProjectRole(projectId).then((res) => {
          setProjectRole(res);
        });
      }
    });
  }, [projectId]);

  return (
    <section className="container pt-3">
      <div className="row">
        <ProjectSidebar projectId={projectId} />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">프로젝트 비용 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <div className="input-group">
                <select
                  className="form-select"
                  id="costCategory"
                  value={costCategoryId}
                  onChange={(e) => {
                    setCostCategoryId(e.target.value);
                  }}
                >
                  <option value="0">전체</option>
                  {costCategoryList.map((item, index) => (
                    <option key={index} value={item.cost_category_id}>
                      {item.cost_category_name}
                    </option>
                  ))}
                </select>
                {/* <input
                  type="search"
                  className="form-control"
                  placeholder="비용 항목 입력하세요."
                  value={costItem}
                  onChange={(e) => setCostItem(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (e.nativeEvent.isComposing) {
                        return;
                      }

                      getList();
                    }
                  }}
                />

                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => getList()}
                >
                  <i className="fa fa-solid fa-search"></i>
                </button> */}
              </div>
              {projectRole && projectRole !== "client" && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    setShowProjectCostModal(true);
                    setActiveProjectCost(null);
                  }}
                >
                  <i className="fa fa-solid fa-plus me-2"></i>
                  비용 추가
                </button>
              )}
            </div>
            <div className="table-responsive">
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">날짜</th>
                    {/* <th className="border-top-0">공정</th> */}
                    <th className="border-top-0">분류</th>
                    <th className="border-top-0">분류2</th>
                    <th className="border-top-0">항목</th>
                    <th className="border-top-0">단가(원)</th>
                    <th className="border-top-0">수량</th>
                    <th className="border-top-0">세액(원)</th>
                    <th className="border-top-0">합계(원)</th>
                    {projectRole !== "client" && (
                      <th className="border-top-0"></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {projectCostList.map((item, index) => (
                    <tr key={index}>
                      <td>{$toDate(item.cost_date)}</td>
                      {/* <td>{item.architecture_process_name}</td> */}
                      <td>{item.cost_category_name}</td>
                      <td>{item.cost_subcategory_name}</td>
                      <td>{item.cost_item}</td>
                      <td>{$convertNumberFormat(item.unit_price)}</td>
                      <td>{$convertNumberFormat(item.qty)}</td>
                      <td>{$convertNumberFormat(item.tax)}</td>
                      <td>{$convertNumberFormat(item.total_amount)}</td>
                      {projectRole !== "client" && (
                        <td>
                          <button
                            className="btn btn-outline-dark btn-sm"
                            type="button"
                            data-tooltip-id="btn-edit-tooltip"
                            onClick={() => {
                              setShowProjectCostModal(true);
                              setActiveProjectCost(item);
                            }}
                          >
                            <i className="fa fa-solid fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-outline-danger btn-sm ms-2"
                            type="button"
                            data-tooltip-id="btn-delete-tooltip"
                            onClick={() =>
                              deleteProjectCost(item.project_cost_id)
                            }
                          >
                            <i className="fa fa-solid fa-trash"></i>
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          href="#"
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="bx bx-chevron-left ms-n1 me-1" />
                          Prev
                        </a>
                      </li>
                    )}
                    {pageRange.map((page) => (
                      <li
                        key={page}
                        className={
                          pageNo === page
                            ? "page-item d-none d-sm-block active"
                            : "page-item d-none d-sm-block"
                        }
                      >
                        <a
                          onClick={(e) => setPageNo(page)}
                          className="page-link"
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          onClick={(e) => setPageNo(pageNo + 1)}
                          className="page-link"
                        >
                          Next
                          <i className="bx bx-chevron-right me-n1 ms-1" />
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

      {showProjectCostModal && (
        <ProjectCostModal
          projectCost={activeProjectCost}
          projectId={projectId}
          onClose={() => setShowProjectCostModal(false)}
          onList={() => {
            getList();
            setShowProjectCostModal(false);
            setActiveProjectCost(null);
          }}
        />
      )}
    </section>
  );
}

export default ProjectCost;
