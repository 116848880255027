import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $download,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import PlaceHoderCard from "../component/PlacehoderCard";
import DocumentUploadModal from "../component/DocumentUploadModal";
import DocumentVersionModal from "../component/DocumentVersionModal";

function ProjectDocument() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole, getProjectRole } =
    useAuth();
  const [projectRole, setProjectRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
  const [showDocumentVersionModal, setShowDocumentVersionModal] =
    useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedDocumentCategoryId, setSelectedDocumentCategoryId] =
    useState(-1);

  const getCategoryList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/document-category");
    if (res.status === 200) {
      setCategoryList(res.data);
      // setSelectedCategoryId(res.data[0].document_category_id);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
  };

  const getDocumentList = async () => {
    if (projectId === -1) return;
    setLoading(true);
    const url =
      parseInt(selectedDocumentCategoryId) === -1
        ? `/api/document/${projectId}`
        : `/api/document/document-category/${projectId}/${selectedDocumentCategoryId}`;
    const res = await $get(url);
    if (res.status === 200) {
      setDocumentList(res.data);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
    setLoading(false);
  };

  const deleteDocument = async (documentId) => {
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/document/${documentId}`);
        if (res.status === 200) {
          Swal.fire("Success", "문서가 삭제되었습니다.", "success");
          getDocumentList();
        } else {
          Swal.fire("Error", "문서가 삭제되지 않았습니다.", "error");
        }
      }
    });
  };

  const shareClient = async (documentId, documentTypeName) => {
    Swal.fire({
      title: "건축주(고객)과 공유하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "공유",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Swal 입력창 : 공유 메시지 입력
        Swal.fire({
          title: "공유 메시지를 입력하세요.",
          input: "text",
          inputLabel: "공유 메시지",
          inputPlaceholder: "공유 메시지",
          showCancelButton: true,
          confirmButtonText: "공유",
          cancelButtonText: "취소",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const shareMessage = result.value;
            if (shareMessage === "") {
              Swal.fire("Error", "공유 메시지를 입력하세요.", "error");
              return;
            }
            const res = await $put(
              `/api/document/share/${projectId}/${documentId}`,
              {
                client_share_yn: "Y",
                document_type_name: documentTypeName,
                client_share_message: shareMessage,
              }
            );
            if (res.status === 200) {
              Swal.fire("Success", "문서가 공유되었습니다.", "success");
              getDocumentList();
            } else {
              Swal.fire("Error", "문서가 공유되지 않았습니다.", "error");
            }
          }
        });
      }
    });
  };

  const cancelShareClient = async (documentId) => {
    Swal.fire({
      title: "건축주(고객)와 공유를 취소하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "공유 취소",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/document/share/${projectId}/${documentId}`,
          {
            client_share_yn: "N",
          }
        );
        if (res.status === 200) {
          Swal.fire("Success", "문서 공유가 취소되었습니다.", "success");
          getDocumentList();
        } else {
          Swal.fire("Error", "문서 공유가 취소되지 않았습니다.", "error");
        }
      }
    });
  };

  const downloadFile = async (filePath, fileOriginalName) => {
    const fileName = filePath.split("/").pop();
    const res = await $download(`/api/document/file/${fileName}`);
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileOriginalName);
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    getDocumentList();
  }, [selectedDocumentCategoryId]);

  // useEffect(() => {
  //   getDocumentList();
  // }, [projectId]);

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getCategoryList();
        getDocumentList();
        getProjectRole(projectId).then((res) => {
          setProjectRole(res);
        });
      }
    });
  }, [projectId]);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">프로젝트 문서 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <select
                className="form-select"
                value={selectedDocumentCategoryId}
                onChange={(e) => {
                  setSelectedDocumentCategoryId(e.target.value);
                }}
              >
                <option value="-1">문서 분류 선택</option>
                {categoryList.map((category, index) => (
                  <option
                    key={category.document_category_id}
                    value={category.document_category_id}
                  >
                    {category.document_category_name}
                  </option>
                ))}
              </select>
              {projectRole !== "client" && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowDocumentUploadModal(true)}
                >
                  <i className="fas fa-upload me-2"></i>
                  문서 업로드
                </button>
              )}
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-lg-4">
              {loading &&
                Array.from({ length: 12 }, (_, index) => (
                  <div className="col pb-lg-3 mb-4" key={`placehoder-${index}`}>
                    <PlaceHoderCard type="lg" />
                  </div>
                ))}
              {!loading &&
                documentList.map((document, index) => (
                  <div className="col pb-lg-3 mb-4" key={index}>
                    <div className="card card-hover border-primary shadow-sm text-center no-round">
                      <div className="d-flex">
                        <span className="badge bg-secondary no-round me-auto">
                          {document.document_category_name}
                        </span>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">
                          {document.document_type_name}
                        </h5>

                        <p className="card-text fs-sm mb-1">
                          {document.file_name}
                        </p>
                        <p className="card-text fs-sm mb-1">
                          {$dateTimezone(document.create_datetime)} /{" "}
                          {/* <a
                            onClick={() => setShowDocumentVersionModal(true)}
                            className="link-primary"
                          >
                            ver. {document.version}
                          </a> */}
                        </p>
                      </div>
                      <div className="card-footer d-flex justify-content-center text-muted gap-1">
                        <a
                          className="btn btn-sm btn-outline-primary"
                          onClick={() => {
                            downloadFile(
                              document.file_path,
                              document.file_name
                            );
                          }}
                        >
                          <i className="fa-solid fa-download me-1"></i>
                          다운로드
                        </a>
                        {projectRole &&
                        projectRole !== "client" &&
                        document.client_share_yn === "N" ? (
                          <a
                            className="btn btn-sm btn-outline-dark"
                            onClick={() => {
                              shareClient(
                                document.project_document_id,
                                document.document_type_name
                              );
                            }}
                          >
                            <i className="fa-regular fa-paper-plane me-1"></i>
                            고객공유
                          </a>
                        ) : projectRole &&
                          projectRole !== "client" &&
                          document.client_share_yn === "Y" ? (
                          <a
                            className="btn btn-sm btn-outline-dark"
                            onClick={() => {
                              cancelShareClient(document.project_document_id);
                            }}
                          >
                            공유취소
                          </a>
                        ) : (
                          <></>
                        )}
                        {projectRole !== "client" && (
                          <a
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => {
                              deleteDocument(document.project_document_id);
                            }}
                          >
                            <i className="fa-solid fa-trash me-1"></i>
                            삭제
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {showDocumentUploadModal && (
        <DocumentUploadModal
          projectId={projectId}
          onClose={() => setShowDocumentUploadModal(false)}
          onList={() => {
            setShowDocumentUploadModal(false);
            getDocumentList();
          }}
        />
      )}
      {showDocumentVersionModal && (
        <DocumentVersionModal
          onClose={() => setShowDocumentVersionModal(false)}
        />
      )}
    </section>
  );
}

export default ProjectDocument;
