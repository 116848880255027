import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";

function MaterialPriceHistoryModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);

  useEffect(() => {
    btnRef.current.click();
  }, []);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#collaboratorModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="collaboratorModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">가격 변경 히스토리</h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>등록일</th>
                    <th>가격</th>
                    <th>공급업체</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2024-08-01</td>
                    <td>10,000</td>
                    <td>한성건재</td>
                  </tr>
                  <tr>
                    <td>2024-03-16</td>
                    <td>9,200</td>
                    <td>한성건재</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialPriceHistoryModal;
