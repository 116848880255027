import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

function WorkerDetail() {
  const [pipelineList, setPipelineList] = useState([]);
  const navigate = useNavigate();

  const getPipelineList = async () => {
    setPipelineList([
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
        status: "Published",
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
        status: "Published",
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
        status: "Published",
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
        status: "Published",
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
        status: "Published",
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
      },
      {
        title: "file-analyzer-pipeline",
        description:
          "This project develops an AI-powered file management, which is a great solution for businesses.",
        badges: ["GPT-4o", "claude-3.5-sonnet"],
        stars: 238,
        views: 1457,
        tags: 104,
        forks: 105,
      },
    ]);
  };

  useEffect(() => {
    getPipelineList();
  }, []);
  return (
    <section className="container pt-5">
      <div className="row">
        {/* Account details */}
        <div className="col-12 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            {/* <h1 className="h2 pt-xl-1 pb-3">Account Details</h1> */}
            <div className="card overflow-hidden">
              <div className="row g-0">
                <div
                  className="col-md-3 col-sm-4 bg-repeat-0 bg-size-cover"
                  style={{
                    backgroundImage:
                      "url(assets/images/avatar/placeholder.jpg)",
                    minHeight: "12rem",
                  }}
                />
                <div className="col-md-9 col-sm-8">
                  <div className="card-body">
                    <h5 className="card-title">홍길동</h5>

                    <p className="card-text mb-1">
                      <a href={`tel:0100000000`}>
                        <i className="fa-solid fa-phone me-2"></i>
                        010-0000-0000
                      </a>
                    </p>
                    <p className="card-text mb-1">서울시 강남구 테헤란로 123</p>
                    <div className="d-flex flex-wrap mt-2 mb-2">
                      <span className="badge bg-secondary me-1 mb-1">
                        건축사
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="h5 mt-4">프로젝트 투입 이력</h2>
            </div>
            <div className="row row-cols-1 row-cols-md-2 gx-3 gx-lg-4">
              {/* Item */}
              <div className="card card-hover border-primary border-0 shadow-sm overflow-hidden mb-4">
                <div className="row g-0">
                  <a
                    href="#"
                    className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                    style={{
                      backgroundImage: "url(assets/images/bg/bg01.jpg)",
                      minHeight: "13rem",
                    }}
                    aria-label="Cover image"
                  />
                  <div className="col-sm-8">
                    <div className="card-body">
                      <div className="fs-sm text-muted mb-1">2024년 8월</div>
                      <h2 className="h4 pb-1 mb-2">
                        <a onClick={() => navigate("/project-dashboard")}>
                          제주 애월 세컨하우스
                        </a>
                      </h2>
                      <p className="mb-4 mb-lg-5">목조 / 세컨하우스</p>
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-outline-primary px-3 px-lg-4 me-3"
                        >
                          <i className="bx bx-edit fs-xl me-xl-2" />
                          상세히보기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="card card-hover border-primary border-0 shadow-sm overflow-hidden mb-4">
                <div className="row g-0">
                  <a
                    href="#"
                    className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
                    style={{
                      backgroundImage: "url(assets/images/bg/bg02.jpg)",
                      minHeight: "13rem",
                    }}
                    aria-label="Cover image"
                  />
                  <div className="col-sm-8">
                    <div className="card-body">
                      <div className="fs-sm text-muted mb-1">2024년 8월</div>
                      <h2 className="h4 pb-1 mb-2">
                        <a onClick={() => navigate("/project-dashboard")}>
                          강원도세컨하우스
                        </a>
                      </h2>
                      <p className="mb-4 mb-lg-5">
                        {" "}
                        판넬 / 구옥리모델링 / 세컨하우스
                      </p>
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-outline-primary px-3 px-lg-4 me-3"
                          onClick={() => navigate("/project-dashboard")}
                        >
                          <i className="bx bx-edit fs-xl me-xl-2" />
                          상세히보기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="#" className="btn btn-lg btn-outline-primary w-100">
                <i className="bx bx-down-arrow-alt me-2 lh-1 lead" />
                Show more
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkerDetail;
