import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";

function ProjectInbox() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, logout, userData, checkProjectRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const { projectId } = useParams();
  const [inboxList, setInboxList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    if (projectId === undefined) return;
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    const url = `/api/project/inbox/list/${projectId}/${tempPageNo}/${pageLimit}`;
    const res = await $get(url);
    if (res.status === 200) {
      setInboxList(res.data.projectInboxList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  const goToAction = (inbox) => {
    if (inbox.read_yn === "N") {
      const res = $put(`/api/project/inbox/${inbox.project_inbox_id}`, {
        read_yn: "Y",
      });
    }
    navigate(inbox.link);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      }
    });
  }, [projectId]);

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }
  }, []);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-5">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <h1 className="h2 pt-xl-1 pb-3">프로젝트 Inbox</h1>
          </div>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">타입</th>
                  <th scope="col">제목</th>
                  <th scope="col">작성자</th>
                  <th scope="col">작성일</th>
                </tr>
              </thead>
              <tbody>
                {inboxList.map((inbox) => (
                  <tr
                    key={inbox.project_inbox_id}
                    className={
                      inbox.read_yn === "N"
                        ? "unread cursor-pointer"
                        : "cursor-pointer"
                    }
                    onClick={(e) => {
                      goToAction(inbox);
                    }}
                  >
                    <td>{inbox.type}</td>
                    <td>{inbox.title}</td>
                    <td>{inbox.created_by_name}</td>
                    <td>{$dateTimezone(inbox.create_datetime)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {totalPage > 1 && (
            <div className="d-flex justify-content-center align-items-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {pageNo > 5 && (
                    <li className="page-item">
                      <a
                        href="#"
                        className="page-link"
                        onClick={(e) => setPageNo(pageNo - 1)}
                      >
                        <i className="bx bx-chevron-left ms-n1 me-1" />
                        Prev
                      </a>
                    </li>
                  )}
                  {pageRange.map((page) => (
                    <li
                      key={page}
                      className={
                        pageNo === page
                          ? "page-item d-none d-sm-block active"
                          : "page-item d-none d-sm-block"
                      }
                    >
                      <a onClick={(e) => setPageNo(page)} className="page-link">
                        {page}
                      </a>
                    </li>
                  ))}

                  {lastPage < totalPage && (
                    <li className="page-item">
                      <a
                        onClick={(e) => setPageNo(pageNo + 1)}
                        className="page-link"
                      >
                        Next
                        <i className="bx bx-chevron-right me-n1 ms-1" />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ProjectInbox;
