import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import MasterDocumentSidebar from "../component/MasterDocumentSidebar";
import DocumentTypeModal from "../component/DocumentTypeModal";

function DocumentType() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [categoryList, setCategoryList] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeDocumentType, setActiveDocumentType] = useState(null);

  const deleteType = async (id) => {
    Swal.fire({
      title: "문서 타입 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/masterdata/document-type/${id}`);
        if (res.status === 200) {
          if (res.data.status === 400) {
            Swal.fire({
              icon: "error",
              title: "실패",
              text: res.data.message,
            });
            return;
          } else {
            Swal.fire({
              icon: "success",
              title: "성공",
              text: "문서 종류가 삭제되었습니다.",
            });
            getDocumentTypeList();
          }
        } else {
          Swal.fire("Error", "삭제에 실패했습니다.", "error");
        }
      }
    });
  };

  const getCategoryList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/document-category");
    if (res.status === 200) {
      setCategoryList(res.data);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
  };

  const getDocumentTypeList = async () => {
    setLoading(true);

    const res = await $get(
      `/api/masterdata/document-type/${selectedCategoryId}`
    );
    if (res.status === 200) {
      setDocumentTypeList(res.data);
    } else {
      Swal.fire("Error", res.msg, "error");
    }
  };

  useEffect(() => {
    getDocumentTypeList();
  }, [selectedCategoryId]);

  useEffect(() => {
    getCategoryList();
    getDocumentTypeList();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <MasterDocumentSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">문서 종류 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <select
                className="form-select"
                onChange={(e) => {
                  setSelectedCategoryId(e.target.value);
                }}
              >
                <option value="-1">분류 선택</option>
                {categoryList.map((category, index) => (
                  <option
                    key={category.document_category_id}
                    value={category.document_category_id}
                  >
                    {category.document_category_name}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setActiveDocumentType(null);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                종류 추가
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-hover align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">문서명</th>
                    <th className="border-top-0">분류명</th>
                    <th className="border-top-0">등록일</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {documentTypeList.map((documentType, index) => (
                    <tr key={index}>
                      <td>{documentType.document_type_name}</td>
                      <td>{documentType.document_category_name}</td>
                      <td>{$dateTimezone(documentType.create_datetime)}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary me-2"
                          onClick={() => {
                            setActiveDocumentType(documentType);
                            setShowModal(true);
                          }}
                        >
                          <i className="fa-solid fa-pen"></i>
                        </button>

                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          onClick={() =>
                            deleteType(documentType.document_type_id)
                          }
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <DocumentTypeModal
          onClose={() => {
            setActiveDocumentType(null);
            setShowModal(false);
          }}
          documentType={activeDocumentType}
          onList={() => {
            getDocumentTypeList();
            setActiveDocumentType(null);
            setShowModal(false);
          }}
        />
      )}
    </section>
  );
}

export default DocumentType;
