import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $getLocalDateTime,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import WorkerSearchModal from "../component/WorkerSearchModal";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function ProjectDiaryEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, logout, userData } = useAuth();
  const { projectId, projectDiaryId } = location.state || {
    projectId: null,
    projectDiaryId: null,
  };
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);
  const [slides, setSlides] = useState([]);
  const [projectDiary, setProjectDiary] = useState({
    work_date: "",
    title: "",
    notes: "",
    workerList: [],
  });
  const [workerList, setWorkerList] = useState([]);
  const [imageList, setImageList] = useState([]);

  // 이미지 주소에서 이미지 width, height 추출
  const getImgSize = (url) => {
    var img = new Image();
    img.src = url;
    return { width: img.width, height: img.height };
  };

  const saveDiary = async () => {
    if (!projectDiary.work_date) {
      Swal.fire("Error", "작업일을 입력하세요.", "error");
      return;
    }

    if (!projectDiary.title) {
      Swal.fire("Error", "제목을 입력하세요.", "error");
      return;
    }

    if (!projectDiary.notes) {
      Swal.fire("Error", "작업 내용을 입력하세요.", "error");
      return;
    }

    Swal.fire({
      title: "작업 일지 수정",
      text: "작업 일지를 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "등록",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/diary/${projectDiaryId}`, {
          ...projectDiary,
          work_date: $toDate(projectDiary.work_date),
          workerList,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "작업 일지가 수정되었습니다.",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate(`/project-diary-view/${projectId}/${projectDiaryId}`);
        } else {
          Swal.fire("Error", "작업 일지 등록에 실패했습니다.", "error");
        }
      }
    });
  };

  const getProjectDiaryImage = async () => {
    if (projectId === undefined || projectDiaryId === undefined) return;
    setLoading(true);
    const res = await $get(`/api/diary/image/${projectDiaryId}`);
    if (res.status === 200) {
      setImageList(res.data);
    }
    setLoading(false);
  };

  const getProjectDiary = async () => {
    if (projectId === undefined || projectDiaryId === undefined) return;
    setLoading(true);
    const res = await $get(`/api/diary/${projectDiaryId}`);
    if (res.status === 200) {
      setProjectDiary(res.data);
      setWorkerList(res.data.workers);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    } else {
      getProjectDiary();
    }
  }, []);

  return (
    <section className="container pt-5">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <h1 className="h2 pt-xl-1 pb-3">작업 일지</h1>
            {/* Basic info */}
            <div
              className="needs-validation border-bottom pb-3 pb-lg-4"
              noValidate=""
            >
              <div className="row pb-2">
                <div className="col-sm-12 mb-4">
                  <label htmlFor="diary-date" className="form-label fs-base">
                    작업일
                  </label>
                  <input
                    type="date"
                    id="diary-date"
                    className="form-control form-control-lg"
                    value={$toDate(projectDiary.work_date)}
                    onChange={(e) => {
                      setProjectDiary({
                        ...projectDiary,
                        work_date: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-sm-12 mb-4">
                  <label htmlFor="diary-title" className="form-label fs-base">
                    제목
                  </label>
                  <input
                    type="text"
                    id="diary-title"
                    className="form-control form-control-lg"
                    value={projectDiary.title}
                    onChange={(e) => {
                      setProjectDiary({
                        ...projectDiary,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-12 mb-4">
                  <label htmlFor="diary-content" className="form-label fs-base">
                    작업 내용 및 결과
                  </label>
                  <textarea
                    id="diary-content"
                    className="form-control form-control-lg"
                    rows={6}
                    placeholder="작업 내용을 입력하세요."
                    value={projectDiary.notes}
                    onChange={(e) => {
                      setProjectDiary({
                        ...projectDiary,
                        notes: e.target.value,
                      });
                    }}
                  />
                </div>
                {/* 투입 인원 */}
                <div className="col-sm-12 mb-4">
                  <div className="d-flex justify-content-between mb-1">
                    <label
                      htmlFor="diary-people"
                      className="form-label fs-base"
                    >
                      투입 인원
                    </label>
                    <button
                      type="button"
                      className="btn btn-dark btn-sm"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      <i className="fa-solid fa-plus me-2"></i>
                      인원 추가
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered align-middle mb-0">
                      <thead>
                        <tr>
                          <th
                            className="text-nowrap"
                            style={{ width: "120px" }}
                          >
                            이름
                          </th>
                          <th
                            className="text-nowrap"
                            style={{ width: "100px" }}
                          >
                            투입 시간
                          </th>
                          <th className="text-nowrap">작업 내용</th>
                        </tr>
                      </thead>
                      <tbody>
                        {workerList.map((worker, index) => (
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={worker.worker_name}
                                disabled
                              />
                            </td>
                            <td>
                              <select
                                className="form-select form-select-sm"
                                value={worker.work_hours}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const newWorkerList = workerList.map((w, i) =>
                                    i === index
                                      ? { ...w, work_hours: value }
                                      : w
                                  );
                                  setWorkerList(newWorkerList);
                                }}
                              >
                                {[...Array(24).keys()].map((hour) => (
                                  <option key={hour + 1} value={hour + 1}>
                                    {hour + 1} 시간
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={worker.work_notes}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const newWorkerList = workerList.map((w, i) =>
                                    i === index
                                      ? { ...w, work_notes: value }
                                      : w
                                  );
                                  setWorkerList(newWorkerList);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4 gap-2">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={() => {
                  saveDiary();
                }}
              >
                저장
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-lg"
                onClick={() => {
                  navigate(
                    `/project-diary-view/${projectId}/${projectDiaryId}`
                  );
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <WorkerSearchModal
          onSelect={(worker) => {
            setWorkerList([
              ...workerList,
              {
                worker_id: worker.worker_id,
                worker_name: worker.worker_name,
                work_hours: 8,
                work_notes: "",
              },
            ]);
            setShowModal(false);
          }}
          onClose={() => setShowModal(false)}
        />
      )}
    </section>
  );
}

export default ProjectDiaryEdit;
