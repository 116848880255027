import React, { useState, useEffect, useRef } from "react";
import {
  $get,
  $post,
  $dateTimezone,
  $convertMarkdownToHtml,
  $toDate,
} from "../utils/common";
import Swal from "sweetalert2";

function MessageModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const fileRef = useRef(null);
  const [message, setMessage] = useState("");

  const sendMessage = async () => {
    if (props.originMessage === undefined) return;

    const parentId =
      props.originMessage.level === 1
        ? props.originMessage.project_diary_message_id
        : props.originMessage.parent_id;

    const res = await $post(`/api/diary/message`, {
      project_diary_id: props.originMessage.project_diary_id,
      project_id: props.originMessage.project_id,
      parent_id: parentId,
      message: message,
      level: 2,
      message_owner: props.originMessage.created_by,
    });
    if (res.status === 200) {
      setMessage("");
      props.onList();
      btnCloseRef.current.click();
    }
  };

  useEffect(() => {
    btnRef.current.click();
  }, [props.originMessage]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#collaboratorModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="collaboratorModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">댓글 작성</h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">{props.originMessage.message}</div>
              <div className="mb-3">
                {/* <label htmlFor="message" className="form-label">
                  Message
                </label> */}
                <textarea
                  className="form-control"
                  id="message"
                  rows="4"
                  placeholder="댓글을 입력하세요"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (e.nativeEvent.isComposing) {
                        return;
                      }

                      sendMessage();
                    }
                  }}
                ></textarea>
                {/* 첨부파일 */}
                {/* <div className="d-flex align-items-center mt-3">
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => {
                      fileRef.current.click();
                    }}
                  >
                    <i className="fa-solid fa-paperclip me-2"></i> 파일 첨부
                  </button>
                  <input type="file" className="d-none" ref={fileRef} />
                </div> */}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  sendMessage();
                }}
              >
                <i className="fa-regular fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageModal;
