import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import Chart from "react-apexcharts";

import ReportSidebar from "../component/ReportSidebar";

function UserStatistics() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userCountLast12Months, setUserCountLast12Months] = useState(null);
  const [userCountTotal, setUserCountTotal] = useState(null);

  const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getUserCountLast12Months = async () => {
    const res = await $get("/api/report/user/count/last-12-months");
    if (res.status === 200) {
      setUserCountLast12Months({
        series: [
          {
            name: "월별 사용자 수",
            // type: "line",
            data: res.data.map((item) => item.count),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            curve: "straight",
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          // labels: res.data.map((item) => item.date),
          xaxis: { categories: res.data.map((item) => item.month) },
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "사용자 수",
              },
            },
          ],
        },
      });
    }
  };

  const getUserCountTotal = async () => {
    const res = await $get("/api/report/user/count/total");
    if (res.status === 200) {
      setUserCountTotal(res.data.count);
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }

    getUserCountLast12Months();
    getUserCountTotal();
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <ReportSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">사용자 통계</h1>
            </div>
            <div className="mb-3">
              {/* 총 사용자 수 */}
              <h3 className="h5">총 사용자 수 : {userCountTotal}</h3>
              <div className="row">
                {userCountLast12Months != null && (
                  <div className="col-12">
                    <div className="card mt-4">
                      <div className="card-body">
                        <h3 className="h5">월별 신규 사용자 추이</h3>
                        <Chart
                          options={userCountLast12Months.options}
                          series={userCountLast12Months.series}
                          type="line"
                          height={350}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserStatistics;
