import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import PlaceHoderCard from "../component/PlacehoderCard";
import SystemSidebar from "../component/MasterSidebar";
import WorkerModal from "../component/WorkerModal";
import ResourceSidebar from "../component/ResourceSidebar";
import WorkerReviewModal from "../component/WorkerReviewModal";

function Worker() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [workerList, setWorkerList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [workerTypeId, setWorkerTypeId] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [activeWorker, setActiveWorker] = useState(null);
  const [workerTypeList, setWorkerTypeList] = useState([]);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const deleteCategory = async (clientId) => {
    Swal.fire({
      title: "삭제 확인",
      text: "정말 삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/client/${clientId}`);
        if (res.status === 200) {
          getList();
        } else {
          Swal.fire("Error", "삭제에 실패했습니다.", "error");
        }
      }
    });
  };

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    console.log("workerTypeId", workerTypeId);
    const url = workerTypeId
      ? `/api/worker/list/${tempPageNo}/${pageLimit}?workerTypeId=${workerTypeId}&search=${encodeURIComponent(
          searchText
        )}`
      : `/api/worker/list/${tempPageNo}/${pageLimit}?search=${encodeURIComponent(
          searchText
        )}`;
    // const url = searchText
    //   ? `/api/worker/list/${tempPageNo}/${pageLimit}?search=${encodeURIComponent(
    //       searchText
    //     )}`
    //   : `/api/worker/list/${tempPageNo}/${pageLimit}`;
    const res = await $get(url);
    if (res.status === 200) {
      setWorkerList(res.data.workerList);

      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  const getWorkerTypeList = async () => {
    const res = await $get("/api/masterdata/worker-type");
    if (res.status === 200) {
      setWorkerTypeList(res.data);
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    getWorkerTypeList();
  }, []);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* <ResourceSidebar /> */}
        {/* <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0"> */}
        <div className="col-12 offset-lg-1 pb-5 mb-lg-2 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">인력 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <div className="input-group">
                <select
                  className="form-select"
                  id="workerType"
                  style={{ maxWidth: "200px" }}
                  value={workerTypeId}
                  onChange={(e) => setWorkerTypeId(e.target.value)}
                >
                  <option value="">전체</option>
                  {workerTypeList.map((workerType) => (
                    <option
                      key={workerType.worker_type_id}
                      value={workerType.worker_type_id}
                    >
                      {workerType.worker_type}
                    </option>
                  ))}
                </select>
                <input
                  type="search"
                  className="form-control"
                  placeholder="이름을 입력하세요"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (e.nativeEvent.isComposing) {
                        return;
                      }

                      getList();
                    }
                  }}
                />
                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => getList()}
                >
                  <i className="fa fa-solid fa-search"></i>
                </button>
              </div>

              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setActiveWorker(null);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                추가
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-hover align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">이름</th>
                    <th className="border-top-0">역할</th>
                    <th className="border-top-0">전화번호</th>
                    <th className="border-top-0">이메일</th>
                    <th className="border-top-0">주소</th>
                    <th className="border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {workerList.map((worker, index) => (
                    <tr key={index}>
                      <td>{worker.worker_name}</td>
                      <td>{worker.worker_type}</td>
                      <td>{worker.phone_number}</td>
                      <td>{worker.email}</td>
                      <td>{worker.address}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary me-2"
                          onClick={() => {
                            setActiveWorker(worker);
                            setShowModal(true);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-info me-2"
                          data-tooltip-id="btn-view-tooltip"
                          onClick={() => {
                            setActiveWorker(worker);
                            setShowReviewModal(true);
                          }}
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => deleteCategory(worker.worker_id)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          href="#"
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="bx bx-chevron-left ms-n1 me-1" />
                          Prev
                        </a>
                      </li>
                    )}
                    {pageRange.map((page) => (
                      <li
                        key={page}
                        className={
                          pageNo === page
                            ? "page-item d-none d-sm-block active"
                            : "page-item d-none d-sm-block"
                        }
                      >
                        <a
                          onClick={(e) => setPageNo(page)}
                          className="page-link"
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          onClick={(e) => setPageNo(pageNo + 1)}
                          className="page-link"
                        >
                          Next
                          <i className="bx bx-chevron-right me-n1 ms-1" />
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <WorkerModal
          worker={activeWorker}
          onClose={() => setShowModal(false)}
          onList={() => {
            getList();
            setActiveWorker(null);
            setShowModal(false);
          }}
        />
      )}

      {showReviewModal && (
        <WorkerReviewModal
          worker={activeWorker}
          onClose={() => setShowReviewModal(false)}
        />
      )}
    </section>
  );
}

export default Worker;
