import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function UserModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [companyList, setCompanyList] = useState([]);

  const doSave = async () => {
    if (!user.company_code) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "회사를 선택하세요.",
      });
      return;
    }

    if (!user.role_level) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "권한을 선택하세요.",
      });
      return;
    }

    // if (!user.active_yn) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "실패",
    //     text: "활성화 여부를 선택하세요.",
    //   });
    //   return;
    // }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "사용자 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/user/${user.email}`, {
          role_level: user.role_level,
          company_code: user.company_code,
          // active_yn: user.active_yn,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "사용자 정보가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "사용자 정보 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  const getCompanyList = async () => {
    const res = await $get("/api/company");
    if (res.status === 200) {
      setCompanyList(res.data);
    }
  };

  useEffect(() => {
    if (props.user) {
      getCompanyList();
      setUser(props.user);
    }
    btnRef.current.click();
  }, [props.user]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#userModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="userModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">사용자 정보 수정</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {user && (
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="user_name" className="form-label">
                    사용자 이름
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="user_name"
                    value={user.name}
                    disabled
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone_number" className="form-label">
                    연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone_number"
                    value={user.phone}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    이메일
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={user.email}
                    disabled
                  />
                </div>
                {/* 회사 선택 추가 */}
                <div className="mb-3">
                  <label htmlFor="company_id" className="form-label">
                    건축회사
                  </label>
                  <select
                    className="form-select"
                    id="company_id"
                    value={user.company_code}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        company_code: e.target.value,
                      });
                    }}
                  >
                    <option value="">선택하세요.</option>
                    {companyList.map((company) => (
                      <option
                        key={company.company_code}
                        value={company.company_code}
                      >
                        {company.company_name}
                      </option>
                    ))}
                  </select>
                </div>
                {user.company_code !== "" && (
                  <div className="mb-3">
                    <label htmlFor="role_level" className="form-label">
                      역할
                    </label>
                    <select
                      className="form-select"
                      id="role_level"
                      value={user.role_level}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          role_level: e.target.value,
                        });
                      }}
                    >
                      <option value="">선택하세요.</option>
                      <option value="client">고객</option>
                      <option value="company_admin">회사관리자</option>
                      <option value="system_admin">시스템 관리자</option>
                    </select>
                  </div>
                )}

                {/* <div className="mb-3">
                  <label htmlFor="active_yn" className="form-label">
                    활성화
                  </label>
                  <select
                    className="form-select"
                    id="active_yn"
                    value={user.active_yn}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        active_yn: e.target.value,
                      });
                    }}
                  >
                    <option value="Y">활성화</option>
                    <option value="N">비활성화</option>
                  </select>
                </div> */}
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  doSave();
                }}
              >
                수정
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserModal;
