import React, { useState, useEffect, useRef } from "react";
import { $get, $upload } from "../utils/common";
import Swal from "sweetalert2";

function DocumentUploadModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [categoryList, setCategoryList] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [selectedTypeId, setSelectedTypeId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState({
    document_category_id: -1,
    document_type_id: -1,
    notes: "",
  });
  const [documentFile, setDocumentFile] = useState(null);
  const documentFileRef = useRef(null);

  const getCategoryList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/document-category");
    if (res.status === 200) {
      setCategoryList(res.data);
      setDocument({
        ...document,
        document_category_id: res.data[0].document_category_id,
      });
    } else {
      Swal.fire("Error", res.msg, "error");
    }
  };

  const getDocumentTypeList = async () => {
    setLoading(true);

    const res = await $get(
      `/api/masterdata/document-type/${document.document_category_id}`
    );
    if (res.status === 200) {
      setDocumentTypeList(res.data);
      setDocument({
        ...document,
        document_type_id: res.data[0].document_type_id,
      });
    } else {
      Swal.fire("Error", res.msg, "error");
    }
  };

  const uploadDocument = async () => {
    const res = await $upload("/api/document", documentFile, {
      project_id: props.projectId,
      document_category_id: document.document_category_id,
      document_type_id: document.document_type_id,
      notes: document.notes,
    });

    if (res.status === 200) {
      Swal.fire("Success", "문서가 업로드 되었습니다.", "success");
      props.onList();
      btnCloseRef.current.click();
    }
  };

  useEffect(() => {
    if (document.document_category_id !== -1) {
      getDocumentTypeList();
    }
  }, [document.document_category_id]);

  useEffect(() => {
    getCategoryList();
    btnRef.current.click();
  }, [props.projectId]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#collaboratorModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="collaboratorModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">문서 업로드</h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="documentCategory" className="form-label">
                  문서 분류
                </label>
                <select
                  className="form-select"
                  id="documentCategory"
                  onChange={(e) =>
                    setDocument({
                      ...document,
                      document_category_id: e.target.value,
                    })
                  }
                >
                  {categoryList.map((category, index) => (
                    <option
                      key={category.document_category_id}
                      value={category.document_category_id}
                    >
                      {category.document_category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="documentType" className="form-label">
                  문서 종류
                </label>
                <select
                  className="form-select"
                  id="documentType"
                  onChange={(e) =>
                    setDocument({
                      ...document,
                      document_type_id: e.target.value,
                    })
                  }
                >
                  {documentTypeList.map((documentType, index) => (
                    <option
                      key={documentType.document_type_id}
                      value={documentType.document_type_id}
                    >
                      {documentType.document_type_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="notes" className="form-label">
                  비고
                </label>
                <textarea
                  className="form-control"
                  id="notes"
                  rows={3}
                  placeholder="비고를 입력하세요."
                  value={document.notes}
                  onChange={(e) =>
                    setDocument({ ...document, notes: e.target.value })
                  }
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="documentFile" className="form-label">
                  파일
                </label>
                <button
                  className="btn d-flex btn-outline-secondary"
                  type="button"
                  onClick={() => {
                    documentFileRef.current.click();
                  }}
                >
                  <i className="fa fa-solid fa-upload me-2"></i>
                  파일 선택
                </button>
                <input
                  className="form-control"
                  type="file"
                  ref={documentFileRef}
                  id="documentFile"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setDocumentFile(e.target.files[0]);
                  }}
                />
                <div>
                  {documentFile !== null && (
                    <span>
                      <i className="fa fa-solid fa-file me-2"></i>{" "}
                      {documentFile.name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  uploadDocument();
                }}
              >
                업로드
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentUploadModal;
