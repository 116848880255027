import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function PartnerModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState(null);

  const doCreate = async () => {
    if (!partner.partner_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "협력업체명을 입력하세요.",
      });
      return;
    }

    if (!partner.partner_type) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "협력업체 구분을 선택하세요.",
      });
      return;
    }

    if (!partner.partner_speciality) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "협력업체 전문분야를 선택하세요.",
      });
      return;
    }

    if (!partner.phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "연락처를 입력하세요.",
      });
      return;
    }

    // if (!partner.email) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "실패",
    //     text: "이메일을 입력하세요.",
    //   });
    //   return;
    // }

    if (!partner.address) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "주소를 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "협력업체 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/partner", partner);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "협력업체가 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "협력업체 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!partner.partner_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "협력업체명을 입력하세요.",
      });
      return;
    }

    if (!partner.partner_type) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "협력업체 구분을 선택하세요.",
      });
      return;
    }

    if (!partner.partner_speciality) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "협력업체 전문분야를 선택하세요.",
      });
      return;
    }

    if (!partner.phone_number) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "연락처를 입력하세요.",
      });
      return;
    }

    // if (!partner.email) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "실패",
    //     text: "이메일을 입력하세요.",
    //   });
    //   return;
    // }

    if (!partner.address) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "주소를 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "협력업체 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const partnerInfo = { ...partner };
        delete partnerInfo.partner_id;
        delete partnerInfo.create_datetime;
        delete partnerInfo.update_datetime;

        const res = await $put(
          `/api/partner/${partner.partner_id}`,
          partnerInfo
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "협력업체가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "협력업체 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  const getPartner = async (partnerId) => {
    const res = await $get(`/api/partner/${partnerId}`);
    if (res.status === 200) {
      setPartner(res.data);
    }
  };

  useEffect(() => {
    if (props.partner) {
      getPartner(props.partner.partner_id);
    } else {
      setPartner({
        partner_type: "개인",
        partner_name: "",
        phone_number: "",
        email: "",
        address: "",
        notes: "",
        identity_number: "",
        bank_name: "",
        bank_account_number: "",
      });
    }
    btnRef.current.click();
  }, [props.partner]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#partnerModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="partnerModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">협력업체</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {partner && (
              <div className="modal-body">
                {/* select - 개인, 법인, 기관 */}
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <label
                      htmlFor="partner_name"
                      className="form-label required"
                    >
                      협력업체명
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="partner_name"
                      value={partner.partner_name}
                      onChange={(e) => {
                        setPartner({
                          ...partner,
                          partner_name: e.target.value,
                        });
                      }}
                      placeholder="협력업체명을 입력하세요."
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="partner" className="form-label required">
                      협력업체 구분
                    </label>
                    <select
                      className="form-select"
                      id="partner"
                      value={partner.partner_type}
                      onChange={(e) => {
                        setPartner({
                          ...partner,
                          partner_type: e.target.value,
                        });
                      }}
                    >
                      <option value="">선택하세요.</option>
                      <option value="법인">법인</option>
                      <option value="개인">개인</option>
                      <option value="기관">기관</option>
                    </select>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="partner" className="form-label required">
                      협력업체 전문분야
                    </label>
                    <select
                      className="form-select"
                      id="partner"
                      value={partner.partner_speciality}
                      onChange={(e) => {
                        setPartner({
                          ...partner,
                          partner_speciality: e.target.value,
                        });
                      }}
                    >
                      <option value="">선택하세요.</option>
                      <option value="설계">설계</option>
                      <option value="시공">시공</option>
                      <option value="자재공급">자재공급</option>
                      <option value="철거">철거</option>
                      <option value="청소">청소</option>
                      <option value="청소">법률</option>
                      <option value="청소">인허가</option>
                      <option value="기타">기타</option>
                    </select>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label
                      htmlFor="phone_number"
                      className="form-label required"
                    >
                      연락처
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone_number"
                      value={partner.phone_number}
                      onChange={(e) => {
                        setPartner({
                          ...partner,
                          phone_number: e.target.value,
                        });
                      }}
                      placeholder="연락처를 입력하세요."
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      이메일
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={partner.email}
                      onChange={(e) => {
                        setPartner({
                          ...partner,
                          email: e.target.value,
                        });
                      }}
                      placeholder="이메일을 입력하세요."
                    />
                  </div>
                  <div className="col-sm-12 mb-3">
                    <label htmlFor="partner_address" className="form-label">
                      주소
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="partner_address"
                      value={partner.address}
                      onChange={(e) => {
                        setPartner({
                          ...partner,
                          address: e.target.value,
                        });
                      }}
                      placeholder="주소를 입력하세요."
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="identity_number" className="form-label">
                      사업자번호
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="identity_number"
                      value={partner.identity_number}
                      onChange={(e) => {
                        setPartner({
                          ...partner,
                          identity_number: e.target.value,
                        });
                      }}
                      placeholder="사업자번호를 입력하세요."
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="bank" className="form-label">
                      계좌정보
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="bank"
                        style={{ maxWidth: "120px" }}
                        value={partner.bank_name}
                        onChange={(e) => {
                          setPartner({
                            ...partner,
                            bank_name: e.target.value,
                          });
                        }}
                        placeholder="은행명"
                      />
                      <input
                        type="text"
                        className="form-control"
                        id="bank_account_number"
                        value={partner.bank_account_number}
                        onChange={(e) => {
                          setPartner({
                            ...partner,
                            bank_account_number: e.target.value,
                          });
                        }}
                        placeholder="계좌번호를 입력하세요."
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mb-3">
                    <label htmlFor="note" className="form-label">
                      비고
                    </label>
                    <textarea
                      className="form-control"
                      id="note"
                      value={partner.notes}
                      onChange={(e) => {
                        setPartner({
                          ...partner,
                          notes: e.target.value,
                        });
                      }}
                      placeholder="비고를 입력하세요.(업체 관련 정보, 담당자 이름 및 연락처 등)"
                    ></textarea>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (partner && partner.partner_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {partner && partner.partner_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerModal;
