import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import Chart from "react-apexcharts";

import ReportSidebar from "../component/ReportSidebar";

function AIQuoteStatistics() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [quoteCountLast12Months, setQuoteCountLast12Months] = useState(null);
  const [quoteCountHouseExterior, setQuoteCountHouseExterior] = useState(null);
  const [quoteCountHouseStyle, setQuoteCountHouseStyle] = useState(null);

  const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getQuoteCountHouseStyle = async () => {
    const res = await $get("/api/report/quote/count/house-style");
    if (res.status === 200) {
      setQuoteCountHouseStyle({
        series: [
          {
            data: res.data.map((item) => item.count),
          },
        ],
        options: {
          chart: {
            type: "bar",
            // height: 430,
          },
          colors: res.data.map((item) => getRandomColor()),
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: res.data.map((item) => item.house_style),
          },
        },
      });
    }
  };

  const getQuoteCountHouseExterior = async () => {
    const res = await $get("/api/report/quote/count/house-exterior");
    if (res.status === 200) {
      setQuoteCountHouseExterior({
        series: [
          {
            data: res.data.map((item) => item.count),
          },
        ],
        options: {
          chart: {
            type: "bar",
            // height: 430,
          },
          colors: res.data.map((item) => getRandomColor()),
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: res.data.map((item) => item.house_exterior),
          },
        },
      });
    }
  };

  const getQuoteCountLast12Months = async () => {
    const res = await $get("/api/report/quote/count/last-12-months");
    if (res.status === 200) {
      setQuoteCountLast12Months({
        series: [
          {
            name: "월별 AI 견적 수",
            // type: "line",
            data: res.data.map((item) => item.count),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            curve: "straight",
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          // labels: res.data.map((item) => item.date),
          xaxis: { categories: res.data.map((item) => item.month) },
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "AI 견적 수",
              },
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }

    getQuoteCountLast12Months();
    getQuoteCountHouseExterior();
    getQuoteCountHouseStyle();
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <ReportSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">AI 견적 통계</h1>
            </div>
            <div className="mb-3">
              <div className="row">
                {quoteCountLast12Months != null && (
                  <div className="col-12">
                    <div className="card mt-4">
                      <div className="card-body">
                        <h3 className="h5">월별 AI 견적 변화 추이</h3>
                        <Chart
                          options={quoteCountLast12Months.options}
                          series={quoteCountLast12Months.series}
                          type="line"
                          height={350}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {quoteCountHouseExterior != null && (
                  <div className="col-12">
                    <div className="card mt-4">
                      <div className="card-body">
                        <h3 className="h5">외관 재질별 AI 견적 수</h3>
                        <Chart
                          options={quoteCountHouseExterior.options}
                          series={quoteCountHouseExterior.series}
                          type="bar"
                          height={430}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {quoteCountHouseStyle != null && (
                  <div className="col-12">
                    <div className="card mt-4">
                      <div className="card-body">
                        <h3 className="h5">건축 유형별 AI 견적 수</h3>
                        <Chart
                          options={quoteCountHouseStyle.options}
                          series={quoteCountHouseStyle.series}
                          type="bar"
                          height={430}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AIQuoteStatistics;
