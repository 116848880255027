import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function ArchitectureProcessModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [architectureProcess, setArchitectureProcess] = useState(null);

  const doCreate = async () => {
    if (!architectureProcess.architecture_process_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "건축 프로세스를 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "건축 프로세스 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post(
          "/api/masterdata/architecture-process",
          architectureProcess
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "건축 프로세스가 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "건축 프로세스 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!architectureProcess.architecture_process) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "건축 프로세스를 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "건축 프로세스 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/masterdata/architecture-process/${architectureProcess.architecture_process_id}`,
          {
            architecture_process_name:
              architectureProcess.architecture_process_name,
            description: architectureProcess.description,
          }
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "건축 프로세스가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "건축 프로세스 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.architectureProcess) {
      setArchitectureProcess(props.architectureProcess);
    } else {
      setArchitectureProcess({
        architecture_process_name: "",
        description: "",
      });
    }
    btnRef.current.click();
  }, [props.architectureProcess]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#architectureProcessModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="architectureProcessModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">건축 프로세스</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {architectureProcess && (
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="architectureProcess" className="form-label">
                    프로세스명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="architectureProcess"
                    value={architectureProcess.architecture_process_name}
                    onChange={(e) => {
                      setArchitectureProcess({
                        ...architectureProcess,
                        architecture_process_name: e.target.value,
                      });
                    }}
                    placeholder="건축 프로세스명을 입력하세요."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    설명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    value={architectureProcess.description}
                    onChange={(e) => {
                      setArchitectureProcess({
                        ...architectureProcess,
                        description: e.target.value,
                      });
                    }}
                    placeholder="프로세스에 대한 설명을 입력하세요."
                  />
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (
                    architectureProcess &&
                    architectureProcess.architecture_process_id
                  ) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {architectureProcess &&
                architectureProcess.architecture_process_id
                  ? "수정"
                  : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArchitectureProcessModal;
