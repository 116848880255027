import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function WorkerSearchModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [workerRecentList, setWorkerRecentList] = useState([]);
  const [workerList, setWorkerList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [workerTypeList, setWorkerTypeList] = useState([]);
  const [workerTypeId, setWorkerTypeId] = useState("");
  const [pageLimit, setPageLimit] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    console.log("workerTypeId", workerTypeId);
    const url = workerTypeId
      ? `/api/worker/list/${tempPageNo}/${pageLimit}?workerTypeId=${workerTypeId}&search=${encodeURIComponent(
          searchText
        )}`
      : `/api/worker/list/${tempPageNo}/${pageLimit}?search=${encodeURIComponent(
          searchText
        )}`;

    const res = await $get(url);
    if (res.status === 200) {
      setWorkerList(res.data.workerList);

      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  const getWorkerAll = async () => {
    const res = await $get(`/api/diary/worker-all/${props.projectId}`);
    if (res.status === 200) {
      // 중복(worker_id) 제거하고 최대 10개만 가져오기
      const uniqueWorkerList = res.data.filter(
        (worker, index, self) =>
          index === self.findIndex((t) => t.worker_id === worker.worker_id)
      );
      const limitedWorkerList = uniqueWorkerList.slice(
        0,
        uniqueWorkerList.length > 5 ? 5 : uniqueWorkerList.length
      );
      setWorkerRecentList(limitedWorkerList);
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  const getWorkerTypeList = async () => {
    const res = await $get("/api/masterdata/worker-type");
    if (res.status === 200) {
      setWorkerTypeList(res.data);
    }
  };

  useEffect(() => {
    if (props.projectId) {
      getWorkerAll();
    }
    btnRef.current.click();
  }, []);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#workerSearchModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="workerSearchModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">인력</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center gap-2 mb-4">
                <div className="input-group">
                  <select
                    className="form-select"
                    id="workerType"
                    style={{ maxWidth: "200px" }}
                    value={workerTypeId}
                    onChange={(e) => setWorkerTypeId(e.target.value)}
                  >
                    <option value="">전체</option>
                    {workerTypeList.map((workerType) => (
                      <option
                        key={workerType.worker_type_id}
                        value={workerType.worker_type_id}
                      >
                        {workerType.worker_type}
                      </option>
                    ))}
                  </select>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="이름을 입력하세요"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (e.nativeEvent.isComposing) {
                          return;
                        }

                        getList();
                      }
                    }}
                  />
                  <button
                    className="btn btn-dark"
                    type="button"
                    onClick={() => getList()}
                  >
                    <i className="fa fa-solid fa-search"></i>
                  </button>
                </div>
              </div>
              {/* workerRecentList에서 인력 이름을 버튼으로 만들어서 클릭하면 선택되도록 */}
              {workerRecentList.map((worker) => (
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={() => {
                    props.onSelect(worker);
                    btnCloseRef.current.click();
                  }}
                  key={worker.worker_id}
                >
                  {worker.worker_name}
                </button>
              ))}

              <div className="table-responsive">
                <table className="table table-hover align-middle text-nowrap">
                  <thead>
                    <tr>
                      <th className="border-top-0">이름</th>
                      <th className="border-top-0">역할</th>
                      <th className="border-top-0">전화번호</th>
                      <th className="border-top-0">이메일</th>
                      <th className="border-top-0">주소</th>
                      <th className="border-top-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {workerList.map((worker, index) => (
                      <tr key={index}>
                        <td>{worker.worker_name}</td>
                        <td>{worker.worker_type}</td>
                        <td>{worker.phone_number}</td>
                        <td>{worker.address}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary me-2"
                            onClick={() => {
                              props.onSelect(worker);
                              btnCloseRef.current.click();
                            }}
                          >
                            선택
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPage > 1 && (
                <div className="d-flex justify-content-center align-items-center">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {pageNo > 5 && (
                        <li className="page-item">
                          <a
                            href="#"
                            className="page-link"
                            onClick={(e) => setPageNo(pageNo - 1)}
                          >
                            <i className="bx bx-chevron-left ms-n1 me-1" />
                            Prev
                          </a>
                        </li>
                      )}
                      {pageRange.map((page) => (
                        <li
                          key={page}
                          className={
                            pageNo === page
                              ? "page-item d-none d-sm-block active"
                              : "page-item d-none d-sm-block"
                          }
                        >
                          <a
                            onClick={(e) => setPageNo(page)}
                            className="page-link"
                          >
                            {page}
                          </a>
                        </li>
                      ))}

                      {lastPage < totalPage && (
                        <li className="page-item">
                          <a
                            onClick={(e) => setPageNo(pageNo + 1)}
                            className="page-link"
                          >
                            Next
                            <i className="bx bx-chevron-right me-n1 ms-1" />
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkerSearchModal;
