import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";

function DocumentVersionModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);

  useEffect(() => {
    btnRef.current.click();
  }, []);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#collaboratorModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="collaboratorModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">문서 버전</h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>버전</th>
                    <th>작성자</th>
                    <th>작성일</th>
                    <th>파일</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.0</td>
                    <td>홍길동</td>
                    <td>2024-08-01</td>
                    <td>건축계획서.pdf</td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm me-2"
                        onClick={() => {
                          btnCloseRef.current.click();
                        }}
                      >
                        <i className="fa-solid fa-code-pull-request me-2"></i>이
                        버전으로 바꾸기
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => {
                          btnCloseRef.current.click();
                        }}
                      >
                        <i className="fas fa-download me-2"></i>
                        다운로드
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentVersionModal;
