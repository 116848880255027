import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put, $convertNumberFormat } from "../utils/common";
import Swal from "sweetalert2";

function QuoteModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [worker, setWorker] = useState(null);
  const [workerTypeList, setWorkerTypeList] = useState([]);
  const [quoteCost, setQuoteCost] = useState(0);
  const [quoteTax, setQuoteTax] = useState(0);
  const [quote, setQuote] = useState(null);
  const [quoteImage, setQuoteImage] = useState(null);
  const [systemInfo, setSystemInfo] = useState(null);
  const getQuoteImage = async () => {
    const res = await $get(
      `/api/quote/image/${props.quote.house_style_id}/${props.quote.house_exterior_id}/${props.quote.house_roof_id}/${props.quote.house_floor}`
    );
    if (res.status === 200) {
      setQuoteImage(res.data);
    }
  };

  const getSystemInfo = async () => {
    const res = await $get("/api/system/info");
    if (res.status === 200) {
      setSystemInfo(res.data);
    }
  };

  useEffect(() => {
    if (props.quote) {
      getQuoteImage();
      getSystemInfo();
      const tempQuote = props.quote;
      tempQuote.quote_result = JSON.parse(tempQuote.quote_result).quoteResult;
      tempQuote.quote_no = props.quote.quote_history_id
        .toString()
        .padStart(12, "0");
      const tempQuoteResult = tempQuote.quote_result;
      // total cost_category_quote_price
      const quoteCost = tempQuoteResult.reduce(
        (acc, cur) => acc + parseInt(cur.cost_category_quote_price),
        0
      );
      setQuoteCost(quoteCost);
      setQuoteTax(quoteCost * 0.1);
      setQuote(tempQuote);
      btnRef.current.click();
    }
  }, [props.quote]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#workerModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="workerModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">AI 견적</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {quote && (
              <div className="modal-body d-flex justify-content-center">
                <div
                  className="invoice"
                  style={{ maxWidth: "1024px", border: "1px solid #ddd" }}
                >
                  {quoteImage && quoteImage.img_src && (
                    <div className="invoice-image ps-5 pe-5">
                      <img
                        src={`${serverUrl}/static/images//${quoteImage.img_src
                          .split("/")
                          .pop()}`}
                        alt="견적 이미지"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  )}
                  {/* <div className="invoice-company d-flex justify-content-between">
                    예상 견적
                    <div className="d-flex gap-2"></div>
                  </div> */}

                  <div className="invoice-header">
                    <div className="invoice-from">
                      <small>from</small>
                      <address className="mt-1 mb-1">
                        <strong className="text-dark">인생홀딩스</strong>
                        {/* <br />
                    Street Address
                    <br />
                    City, Zip Code
                    <br />
                    Phone: (123) 456-7890
                    <br />
                    Fax: (123) 456-7890 */}
                      </address>
                    </div>
                    <div className="invoice-to">
                      <small>to</small>
                      <address className="mt-1 mb-1">
                        <strong className="text-dark">
                          {props.quote.name}
                        </strong>
                        {/* <br />
                    Street Address
                    <br />
                    City, Zip Code
                    <br />
                    Phone: (123) 456-7890
                    <br />
                    Fax: (123) 456-7890 */}
                      </address>
                    </div>
                    <div className="invoice-date">
                      <div className="date text-dark mt-1">
                        {new Date().toISOString().substring(0, 10)}
                      </div>
                      <div className="invoice-detail">
                        #{props.quote.quote_no}
                      </div>
                    </div>
                  </div>

                  <div className="invoice-requirement">
                    {/* 사용자 선택한 건축 정보 표기: 모던하우스, 외관:벽돌, 주택형태:단층주택, 추가옵션:없음, 주택층수:1층, 연면적: 42평 */}
                    선택하신 건축 정보를 바탕으로 예상 견적을 제공합니다.
                    <br />
                    (건축스타일:
                    {props.quote.house_style}, 외장재질:
                    {props.quote.house_exterior}, 지붕재질:
                    {props.quote.house_roof}, 추가옵션:
                    {props.quote.house_option}, 주택층수:{" "}
                    {props.quote.house_floor}, 연면적:
                    {props.quote.total_floor_area}평)
                  </div>

                  <div className="invoice-content">
                    <div className="table-responsive">
                      <table className="table table-invoice">
                        <thead>
                          <tr>
                            <th>비용 항목</th>
                            <th className="text-end" width="30%">
                              예상 비용
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {quote.quote_result.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span className="text-dark">
                                  {item.cost_category}
                                </span>
                                <br />
                                <small>{item.cost_description}</small>
                                {item.option && (
                                  <div style={{ fontSize: "0.9rem" }}>
                                    외장 재질별 비용 차이:
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.option,
                                      }}
                                    />
                                  </div>
                                )}
                              </td>
                              <td className="text-end">
                                {$convertNumberFormat(
                                  Math.floor(
                                    item.cost_category_quote_price / 10000
                                  )
                                )}
                                만원
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="invoice-price">
                      <div className="invoice-price-left">
                        <div className="invoice-price-row">
                          <div className="sub-price">
                            <small>예상건축비용</small>
                            <span className="text-dark">
                              {$convertNumberFormat(quoteCost / 10000)}
                              만원
                            </span>
                          </div>
                          <div className="sub-price">
                            <i className="fa fa-plus text-muted" />
                          </div>
                          <div className="sub-price">
                            <small>부가세 (10%)</small>
                            <span className="text-dark">
                              {$convertNumberFormat(quoteTax / 10000)}
                              만원
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-price-right">
                        <small>합계</small>{" "}
                        <span className="fw-bold">
                          {$convertNumberFormat((quoteCost + quoteTax) / 10000)}
                          만원
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-note">
                    <strong>
                      * 예상 견적은 유사 규모의 건축 비용 데이터를 토대로 계산된
                      AI 예측 비용으로 실제 견적과 다를 수 있습니다.
                    </strong>
                    <br />
                    <strong>
                      * 자재 비용 및 인건비 등 물가 변동에 따라 견적 비용이
                      변동될 수 있습니다.
                    </strong>
                    <br />
                    <strong>
                      * 본 견적서는 참고용이며, 실제 계약과 관련된 법적 책임은
                      서비스 제공자에게 없습니다.
                    </strong>
                    <br />
                    <strong>* 상담을 통해 정확한 견적을 받아보세요.</strong>
                  </div>
                  <div className="invoice-footer">
                    <p className="text-center mb-1 fw-bold">
                      건축주와 함께 나만의 인생주택을 만들어갑니다.
                    </p>
                    {systemInfo && (
                      <p className="text-center">
                        <span className="me-2">
                          <i className="fa fa-fw fa-lg fa-globe" />{" "}
                          {systemInfo.website}
                        </span>
                        <span className="me-2">
                          <i className="fa fa-fw fa-lg fa-phone-volume" /> T:{" "}
                          {systemInfo.contact_phone}
                        </span>
                        <span className="me-2">
                          <i className="fa fa-fw fa-lg fa-envelope" />{" "}
                          {systemInfo.contact_email}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuoteModal;
