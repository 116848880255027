import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import PlaceHoderCard from "../component/PlacehoderCard";
import SystemSidebar from "../component/MasterSidebar";

function ExteriorType() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [exteriorList, setExteriorList] = useState([]);

  const getExteriorList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/exterior-type");
    if (res.status === 200) {
      setExteriorList(res.data);
      console.log(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getExteriorList();
  }, []);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <SystemSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">건축 외장 유형 관리</h1>
              <button type="button" className="btn btn-primary">
                <i className="fa-solid fa-plus me-2"></i>
                추가
              </button>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-lg-4">
              {loading &&
                Array.from({ length: 12 }, (_, index) => (
                  <div className="col pb-lg-3 mb-4" key={`placehoder-${index}`}>
                    <PlaceHoderCard type="lg" />
                  </div>
                ))}
              {!loading &&
                exteriorList.map((exterior, index) => (
                  <div className="col pb-lg-3 mb-4" key={index}>
                    <div className="card">
                      {exterior.img ? (
                        <img
                          src="assets/img/landing/financial/services/01.jpg"
                          className="card-img-top"
                          alt="Card image"
                        />
                      ) : (
                        // image placehoder
                        <div
                          className="card-img-top bg-light d-flex justify-content-center align-items-center"
                          style={{ height: "160px" }}
                        >
                          <i className="fas fa-image fa-5x text-muted"></i>
                        </div>
                      )}
                      <div className="card-body">
                        <h5 className="card-title">{exterior.type}</h5>
                        <p className="card-text fs-sm">
                          {exterior.description}
                        </p>
                        <div className="d-flex justify-content-start gap-1">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                          >
                            <i className="fas fa-edit me-2"></i>
                            수정
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                          >
                            <i className="fas fa-trash-alt me-2"></i>
                            삭제
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExteriorType;
