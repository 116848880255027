import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $getLocalDateTime,
  $toDate,
  $convertNumberFormat,
  $convertDateKoreanFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ClientSearchModal from "../component/ClientSearchModal";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { RowsPhotoAlbum, MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import "react-photo-album/masonry.css";
import ContactModal from "../component/ContactModal";
const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function AIQuote() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [houseQuoteList, setHouseQuoteList] = useState([]);
  const [index, setIndex] = useState(-1);
  const [slides, setSlides] = useState([]);
  const [activeHouseQuote, setActiveHouseQuote] = useState(null);
  const [systemInfo, setSystemInfo] = useState(null);

  // state - project_id
  const { projectId } = location.state || { projectId: null };
  const [quote, setQuote] = useState(null);
  const [quoteCost, setQuoteCost] = useState(0);
  const [quoteTax, setQuoteTax] = useState(0);
  const [showClientSearchModal, setShowClientSearchModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [selectedClient, setSelectedClient] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [houseStyleList, setHouseStyleList] = useState([]);
  const [houseTypeList, setHouseTypeList] = useState([]);
  const [houseSiteList, setHouseSiteList] = useState([]);
  const [houseOptionList, setHouseOptionList] = useState([]);
  const [houseFuncList, setHouseFuncList] = useState([]);
  const [houseExteriorList, setHouseExteriorList] = useState([]);
  const [houseRoofList, setHouseRooftList] = useState([]);
  const [project, setProject] = useState({
    project_name: "",
    project_address: "",
    project_budget: "",
    estimated_start_date: "",
    estimated_end_date: "",
    notes: "",
    client_id: "",
    house_style_id: "",
    house_type_id: "",
    house_site_id: "",
    house_option_id: "",
    house_func_id: "",
    house_exterior_id: "",
    house_roof_id: "",
    house_floor: "",
    house_site_size: "",
    house_size: "",
    total_floor_area: "",
    building_to_land_ratio: "",
    floor_area_ratio: "",
  });

  const [quoteOptions, setQuoteOptions] = useState({
    house_style_id: "",
    house_exterior_id: "",
    house_roof_id: "",
    house_func_id: "",
    house_option_id: "",
    house_floor: "",
    total_floor_area: "",
  });

  const [quoteResult, setQuoteResult] = useState([]);
  const [quoteNo, setQuoteNo] = useState("");
  const [showContactModal, setShowContactModal] = useState(false);
  const printRef = useRef();

  const requestQuote = async () => {
    if (!quoteOptions.house_option_id) {
      Swal.fire({
        icon: "error",
        title: "추가 옵션을 선택해주세요.",
      });
      return;
    }

    if (!quoteOptions.total_floor_area) {
      Swal.fire({
        icon: "error",
        title: "연면적을 입력해주세요.",
      });
      return;
    }

    if (!quoteOptions.house_region) {
      Swal.fire({
        icon: "error",
        title: "지역을 선택해주세요.",
      });
      return;
    }

    if (!quoteOptions.planned_construction_years) {
      Swal.fire({
        icon: "error",
        title: "건축 예정 (몇년 이내)을 선택해주세요.",
      });
      return;
    }

    // 프로젝트를 저장하고 프로젝트 생성 상태를 완료로 변경하시겠습니까?
    Swal.fire({
      title: "실시간 견적 요청",
      text: "견적을 요청하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "요청",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // swal loading

        Swal.fire({
          title: "견적 요청 중...",
          text: "잠시만 기다려주세요.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const res = await $post("/api/quote", quoteOptions);
        if (res.status === 200) {
          console.log(res.data);
          const tempQuoteResult = res.data.quoteResult;
          setQuoteResult(tempQuoteResult);
          setQuoteNo(res.data.quoteNo);
          const quoteCost = tempQuoteResult.reduce(
            (acc, cur) => acc + cur.cost_category_quote_price,
            0
          );
          setQuoteCost(quoteCost);
          setQuoteTax(quoteCost * 0.1);

          Swal.fire({
            icon: "success",
            title: "견적 요청이 완료되었습니다.",
          });

          console.log(quoteResult);
          setActiveStep(3);
        } else {
          Swal.fire({
            icon: "error",
            title: "견적 요청에 실패했습니다.",
          });
        }

        setLoading(false);
      }
    });
  };

  const getSystemInfo = async () => {
    const res = await $get("/api/system/info");
    if (res.status === 200) {
      setSystemInfo(res.data);
    }
  };

  const getList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-quote");
    if (res.status === 200) {
      const tempHouseQuoteList = res.data.filter((hq) => hq.active_yn === "Y");
      setHouseQuoteList(tempHouseQuoteList);
      setSlides(
        tempHouseQuoteList.map((hq) => ({
          src: hq.img_src
            ? `${serverUrl}/static/images/${hq.img_src.split("/").pop()}`
            : "assets/images/bg/house_blank2.png",
          title: `${hq.house_style}, ${hq.house_exterior}, ${hq.house_roof}, ${hq.house_floor}`,
          id: hq.house_quote_id,
          width: hq.img_width,
          height: hq.img_height,
          srcSet: breakpoints.map((bp) => ({
            src: `${serverUrl}/static/images/${hq.img_src.split("/").pop()}`,
            width: bp,
            height: Math.round((hq.img_height / hq.img_width) * bp),
          })),
        }))
      );
    }

    setLoading(false);
  };

  const getHouseStyleList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-style");
    if (res.status === 200) {
      setHouseStyleList(res.data);
    }
    setLoading(false);
  };

  const getHouseTypeList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-type");
    if (res.status === 200) {
      setHouseTypeList(res.data);
    }
    setLoading(false);
  };

  const getHouseSiteList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-site");
    if (res.status === 200) {
      setHouseSiteList(res.data);
    }
    setLoading(false);
  };

  const getHouseOptionList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-option");
    if (res.status === 200) {
      setHouseOptionList(res.data);
    }
    setLoading(false);
  };

  const getHouseFuncList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-func");
    if (res.status === 200) {
      setHouseFuncList(res.data);
    }
    setLoading(false);
  };

  const getHouseExteriorList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-exterior");
    if (res.status === 200) {
      setHouseExteriorList(res.data);
    }
    setLoading(false);
  };

  const getRoofingMaterialList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-roof");
    if (res.status === 200) {
      setHouseRooftList(res.data);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   console.log(quoteOptions);
  // }, [quoteOptions]);

  useEffect(() => {
    getList();
    getHouseStyleList();
    getHouseTypeList();
    getHouseSiteList();
    getHouseOptionList();
    getHouseFuncList();
    getHouseExteriorList();
    getRoofingMaterialList();
    getSystemInfo();
  }, []);

  // useEffect(() => {
  //   if (!userData.phone) {
  //     // Swal - AI 견적을 받으려면 이름과 연락처를 등록해야 합니다. 등록하시겠습니까?
  //     Swal.fire({
  //       title: "AI 견적을 받으려면 이름과 연락처를 등록해야 합니다.",
  //       text: "등록하시겠습니까?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "등록",
  //       cancelButtonText: "취소",
  //       allowOutsideClick: false,
  //       allowEscapeKey: false,
  //       allowEnterKey: false,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         navigate("/my-profile");
  //       } else {
  //         navigate("/");
  //       }
  //     });
  //   }
  // }, [userData]);

  return (
    <section className="container pt-5">
      <div className="pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
        <h1 className="h2 pt-xl-1 pb-3">AI 견적</h1>
        <div className="steps steps-center steps-sm steps-horizontal-sm">
          {/* Step */}
          <div className="step">
            {activeStep === 1 ? (
              <div className="step-number bg-light">
                <span className="position-absolute top-0 start-0 w-100 h-100 rounded-circle bg-faded-success"></span>
                <div className="step-number-inner bg-success text-white">1</div>
              </div>
            ) : (
              <div className="step-number">
                <div className="step-number-inner">1</div>
              </div>
            )}
            <div className="step-body">
              <h5 className="mb-2">스타일 선택</h5>
            </div>
          </div>
          {/* Step */}
          <div className="step">
            {activeStep === 2 ? (
              <div className="step-number bg-light">
                <span className="position-absolute top-0 start-0 w-100 h-100 rounded-circle bg-faded-success"></span>
                <div className="step-number-inner bg-success text-white">2</div>
              </div>
            ) : (
              <div className="step-number">
                <div className="step-number-inner">2</div>
              </div>
            )}
            <div className="step-body">
              <h5 className="mb-2">건축 정보</h5>
            </div>
          </div>
          {/* Step */}
          <div className="step">
            {activeStep === 3 ? (
              <div className="step-number bg-light">
                <span className="position-absolute top-0 start-0 w-100 h-100 rounded-circle bg-faded-success"></span>
                <div className="step-number-inner bg-success text-white">3</div>
              </div>
            ) : (
              <div className="step-number">
                <div className="step-number-inner">3</div>
              </div>
            )}
            <div className="step-body">
              <h5 className="mb-2">AI 견적</h5>
            </div>
          </div>
        </div>
        {activeStep === 1 && (
          <div className="mt-5">
            <div className="">
              {slides.length > 0 && (
                <RowsPhotoAlbum
                  photos={slides}
                  render={{
                    extras: (_, { photo, index }) => (
                      <button
                        className={`btn btn-sm position-absolute top-0 end-0 ${
                          activeHouseQuote && photo.id === activeHouseQuote.id
                            ? "btn-primary"
                            : "btn-danger"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveHouseQuote(photo);

                          const houseQuote = houseQuoteList.find(
                            (quote) => quote.house_quote_id === photo.id
                          );
                          setQuoteOptions({
                            ...quoteOptions,
                            house_style_id: houseQuote.house_style_id,
                            house_exterior_id: houseQuote.house_exterior_id,
                            house_roof_id: houseQuote.house_roof_id,
                            house_floor: houseQuote.house_floor,
                          });
                          setActiveStep(2);
                        }}
                      >
                        {activeHouseQuote &&
                        photo.id === activeHouseQuote.id ? (
                          <span>
                            <i className="fa fa-solid fa-check me-2"></i>선택
                          </span>
                        ) : (
                          <span>선택</span>
                        )}
                      </button>
                    ),
                  }}
                  onClick={({ index: current }) => setIndex(current)}
                />
              )}

              {slides.length > 0 && (
                <Lightbox
                  index={index}
                  slides={slides}
                  open={index >= 0}
                  plugins={[
                    Fullscreen,
                    Slideshow,
                    Captions,
                    Video,
                    Zoom,
                    Thumbnails,
                  ]}
                  close={() => setIndex(-1)}
                  render={{
                    slide: ({ slide }) => (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={slide.src}
                          alt="Slide"
                          style={{
                            maxHeight: "90%",
                            maxWidth: "90%",
                            objectFit: "contain",
                          }}
                        />
                        <button
                          className="btn btn-primary mt-3"
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveHouseQuote(slide);
                            const houseQuote = houseQuoteList.find(
                              (quote) => quote.house_quote_id === slide.id
                            );
                            setQuoteOptions({
                              ...quoteOptions,
                              house_style_id: houseQuote.house_style_id,
                              house_exterior_id: houseQuote.house_exterior_id,
                              house_roof_id: houseQuote.house_roof_id,
                              house_floor: houseQuote.house_floor,
                            });
                            setIndex(-1);
                            setActiveStep(2);
                          }}
                        >
                          선택하기
                        </button>
                      </div>
                    ),
                  }}
                />
              )}
            </div>
            <div className="d-flex gap-2 mb-3 mt-3">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  if (!activeHouseQuote) {
                    Swal.fire({
                      icon: "error",
                      title: "스타일을 선택해주세요.",
                    });
                    return;
                  }

                  const houseQuote = houseQuoteList.find(
                    (quote) => quote.house_quote_id === activeHouseQuote.id
                  );
                  setQuoteOptions({
                    ...quoteOptions,
                    house_style_id: houseQuote.house_style_id,
                    house_exterior_id: houseQuote.house_exterior_id,
                    house_roof_id: houseQuote.house_roof_id,
                    house_floor: houseQuote.house_floor,
                  });
                  setActiveStep(2);
                }}
              >
                <i className="fa fa-solid fa-arrow-right me-2"></i>
                다음
              </button>
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div className="mt-5">
            <div className="needs-validation pb-2 pb-lg-4" noValidate="">
              <div className="row pb-2">
                <div className="col-md-6 mb-4">
                  <label
                    htmlFor="house-option"
                    className="form-label fs-base required"
                  >
                    추가 옵션
                  </label>
                  <select
                    id="house-option"
                    className="form-select form-select-lg"
                    value={quoteOptions.house_option_id}
                    onChange={(e) =>
                      setQuoteOptions({
                        ...quoteOptions,
                        house_option_id: parseInt(e.target.value),
                      })
                    }
                  >
                    <option value="">선택하세요</option>
                    {houseOptionList.map((option, index) => (
                      <option
                        key={option.house_option_id}
                        value={option.house_option_id}
                      >
                        {option.house_option}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 mb-4">
                  <label
                    htmlFor="house-floor"
                    className="form-label fs-base required"
                  >
                    주택 층수
                  </label>
                  <select
                    id="house-floor"
                    className="form-select form-select-lg"
                    value={quoteOptions.house_floor}
                    disabled
                  >
                    <option value="">선택하세요</option>
                    <option value="1">1층</option>
                    <option value="2">2층</option>
                    <option value="3">3층</option>
                    <option value="4">4층</option>
                    <option value="5">5층</option>
                    <option value="6">6층</option>
                    <option value="7">7층</option>
                    <option value="8">8층</option>
                    <option value="9">9층</option>
                    <option value="10">10층</option>
                  </select>
                </div>

                <div className="col-md-6 mb-4">
                  <label
                    htmlFor="total-floor-area"
                    className="form-label fs-base required"
                  >
                    연면적(평수)
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="total-floor-area"
                      className="form-control form-control-lg"
                      value={quoteOptions.total_floor_area}
                      onChange={(e) =>
                        setQuoteOptions({
                          ...quoteOptions,
                          total_floor_area: e.target.value,
                        })
                      }
                      placeholder="연면적(모든 층에 대한 바닥면적 합)을 입력하세요."
                    />
                    <span className="input-group-text">평</span>
                  </div>
                </div>
                {/* 지역 선택 */}
                <div className="col-md-6 mb-4">
                  <label
                    htmlFor="house-city"
                    className="form-label fs-base required"
                  >
                    지역 선택
                  </label>
                  <select
                    id="house-city"
                    className="form-select form-select-lg"
                    value={quoteOptions.house_region}
                    onChange={(e) =>
                      setQuoteOptions({
                        ...quoteOptions,
                        house_region: e.target.value,
                      })
                    }
                  >
                    <option value="">선택하세요</option>
                    <option value="02">서울</option>
                    <option value="031">경기</option>
                    <option value="032">인천</option>
                    <option value="033">강원</option>
                    <option value="043">충북</option>
                    <option value="041">충남</option>
                    <option value="042">대전</option>
                    <option value="054">경북</option>
                    <option value="055">경남</option>
                    <option value="053">대구</option>
                    <option value="052">울산</option>
                    <option value="051">부산</option>
                    <option value="063">전북</option>
                    <option value="061">전남</option>
                    <option value="062">광주</option>
                    <option value="064">제주</option>
                  </select>
                </div>
                <div className="col-md-6 mb-4">
                  <label
                    htmlFor="planned_construction_years"
                    className="form-label fs-base required"
                  >
                    건축 예정 (몇년 이내)
                  </label>
                  <select
                    id="planned_construction_years"
                    className="form-select form-select-lg"
                    value={quoteOptions.planned_construction_years}
                    onChange={(e) => {
                      setQuoteOptions({
                        ...quoteOptions,
                        planned_construction_years: e.target.value,
                      });
                    }}
                  >
                    <option value="">선택</option>
                    <option value="1">1년 이내</option>
                    <option value="3">3년 이내</option>
                    <option value="5">5년 이내</option>
                    <option value="10">10년 이내</option>
                    <option value="0">계획없음</option>
                  </select>
                </div>
                {/* <div className="col-md-6 mb-4">
                  <label htmlFor="demolition_yn" className="form-label fs-base">
                    철거여부
                  </label>
                  <select
                    id="demolition_yn"
                    className="form-select form-select-lg"
                    value={project.demolition_yn}
                    onChange={(e) =>
                      setProject({
                        ...project,
                        demolition_yn: e.target.value,
                      })
                    }
                  >
                    <option value="N">없음</option>
                    <option value="Y">철거</option>
                  </select>
                </div>
                {project.demolition_yn === "Y" && (
                  <div className="col-md-6 mb-4">
                    <label
                      htmlFor="demolition-area"
                      className="form-label fs-base"
                    >
                      철거 평수
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="demolition-area"
                        className="form-control form-control-lg"
                        value={project.demolition_area}
                        onChange={(e) =>
                          setProject({
                            ...project,
                            demolition_area: e.target.value,
                          })
                        }
                        placeholder="철거 평수를 입력하세요."
                      />
                      <span className="input-group-text">평</span>
                    </div>
                  </div>
                )} */}

                <div className="d-flex gap-2 mb-3">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setActiveStep(1);
                    }}
                  >
                    <i className="fa fa-solid fa-arrow-left me-2"></i>
                    이전
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => {
                      requestQuote();
                    }}
                  >
                    <i className="fa fa-solid fa-check me-2"></i>
                    AI 견적 받기
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeStep === 3 && (
          <>
            <div className="mt-5 d-flex justify-content-center">
              <div className="invoice" ref={printRef}>
                <div className="invoice-company d-flex justify-content-between">
                  예상 견적
                  <div className="d-flex gap-2">
                    <a
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <i className="fa-solid fa-print me-2" /> 인쇄
                    </a>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setActiveStep(1);
                      }}
                    >
                      다시 견적 내기
                    </button>
                  </div>
                </div>
                <div className="invoice-header">
                  <div className="invoice-from">
                    <small>from</small>
                    <address className="mt-1 mb-1">
                      <strong className="text-dark">인생홀딩스</strong>
                    </address>
                  </div>
                  <div className="invoice-to">
                    <small>to</small>
                    <address className="mt-1 mb-1">
                      <strong className="text-dark">{userData.name}</strong>
                    </address>
                  </div>
                  <div className="invoice-date">
                    <div className="date text-dark mt-1">
                      {new Date().toISOString().substring(0, 10)}
                    </div>
                    <div className="invoice-detail">#{quoteNo}</div>
                  </div>
                </div>

                <div className="invoice-requirement">
                  선택하신 건축 정보를 바탕으로 예상 견적을 제공합니다.
                  <br />
                  (건축스타일:
                  {
                    houseStyleList.find(
                      (style) =>
                        style.house_style_id === quoteOptions.house_style_id
                    )?.house_style
                  }
                  , 외장재질:
                  {
                    houseExteriorList.find(
                      (exterior) =>
                        exterior.house_exterior_id ===
                        quoteOptions.house_exterior_id
                    )?.house_exterior
                  }
                  , 지붕재질:
                  {
                    houseRoofList.find(
                      (roof) =>
                        roof.house_roof_id === quoteOptions.house_roof_id
                    )?.house_roof
                  }
                  , 추가옵션:
                  {
                    houseOptionList.find(
                      (option) =>
                        option.house_option_id ===
                        parseInt(quoteOptions.house_option_id)
                    )?.house_option
                  }
                  , 주택층수: {quoteOptions.house_floor}, 연면적:
                  {quoteOptions.total_floor_area}평)
                </div>

                <div className="invoice-content">
                  <div className="table-responsive">
                    <table className="table table-invoice">
                      <thead>
                        <tr>
                          <th>비용 항목</th>
                          <th className="text-end" width="30%">
                            예상 비용
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {quoteResult.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span className="text-dark">
                                {item.cost_category}
                              </span>
                              <br />
                              <small>{item.cost_description}</small>
                              {item.option && (
                                <div style={{ fontSize: "0.9rem" }}>
                                  외장 재질별 비용 차이:
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.option,
                                    }}
                                  />
                                </div>
                              )}
                            </td>
                            <td className="text-end">
                              {$convertNumberFormat(
                                Math.floor(
                                  item.cost_category_quote_price / 10000
                                )
                              )}
                              만원
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="invoice-price">
                    <div className="invoice-price-left">
                      <div className="invoice-price-row">
                        <div className="sub-price">
                          <small>예상건축비용</small>
                          <span className="text-dark">
                            {$convertNumberFormat(quoteCost / 10000)}
                            만원
                          </span>
                        </div>
                        <div className="sub-price">
                          <i className="fa fa-plus text-muted" />
                        </div>
                        <div className="sub-price">
                          <small>부가세 (10%)</small>
                          <span className="text-dark">
                            {$convertNumberFormat(quoteTax / 10000)}
                            만원
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-price-right">
                      <small>합계</small>{" "}
                      <span className="fw-bold">
                        {$convertNumberFormat((quoteCost + quoteTax) / 10000)}
                        만원
                      </span>
                    </div>
                  </div>
                </div>
                <div className="invoice-note">
                  <strong>
                    * 예상 견적은 유사 규모의 건축 비용 데이터를 토대로 계산된
                    AI 예측 비용으로 실제 견적과 다를 수 있습니다.
                  </strong>
                  <br />
                  <strong>
                    * 자재 비용 및 인건비 등 물가 변동에 따라 견적 비용이 변동될
                    수 있습니다.
                  </strong>
                  <br />
                  <strong>
                    * 본 견적서는 참고용이며, 실제 계약과 관련된 법적 책임은
                    서비스 제공자에게 없습니다.
                  </strong>
                  <br />
                  <strong>* 상담을 통해 정확한 견적을 받아보세요.</strong>
                </div>
                <div className="invoice-footer">
                  <p className="text-center mb-1 fw-bold">
                    건축주와 함께 나만의 인생주택을 만들어갑니다.
                  </p>
                  <p className="text-center">
                    <span className="me-2">
                      <i className="fa fa-fw fa-lg fa-globe" />{" "}
                      {systemInfo.website}
                    </span>
                    <span className="me-2">
                      <i className="fa fa-fw fa-lg fa-phone-volume" /> T:{" "}
                      {systemInfo.contact_phone}
                    </span>
                    <span className="me-2">
                      <i className="fa fa-fw fa-lg fa-envelope" />{" "}
                      {systemInfo.contact_email}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btn-primary btn-lg"
                onClick={() => setShowContactModal(true)}
              >
                {/* 상담 문의 */}
                <i className="fa fa-paper-plane me-2" />
                상담 문의
              </button>
            </div>
          </>
        )}
      </div>
      {showContactModal && (
        <ContactModal
          onClose={() => setShowContactModal(false)}
          user={userData}
          quoteNo={quoteNo}
        />
      )}
    </section>
  );
}

export default AIQuote;
