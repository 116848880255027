import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import MasterCostSidebar from "../component/MasterCostSidebar";
import CostCategoryModal from "../component/CostCategoryModal";

function CostCategory() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  // const [searchText, setSearchText] = useState("");
  // const [pageLimit, setPageLimit] = useState(10);
  // const [pageNo, setPageNo] = useState(1);
  // const [totalPage, setTotalPage] = useState(1);
  // const [pageRange, setPageRange] = useState([]);
  // const [lastPage, setLastPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [activeCostCategory, setActiveCostCategory] = useState(null);
  const [showSubModal, setShowSubModal] = useState(false);

  const deleteCategory = async (categoryId) => {
    Swal.fire({
      title: "삭제 확인",
      text: "정말 삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(
          `/api/masterdata/cost-category/${categoryId}`
        );
        if (res.status === 200) {
          if (res.data.status === 400) {
            Swal.fire("Error", res.data.message, "error");
            return;
          } else {
            getList();
          }
        } else {
          Swal.fire("Error", "삭제에 실패했습니다.", "error");
        }
      }
    });
  };

  const upSequence = async (id) => {
    // Swal 순서를 바꾸시겠습니까?
    Swal.fire({
      title: "순서를 바꾸시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // id를 찾아서 sequence_number가 1을 더해야 함
        const currentCategory = categoryList.find(
          (category) => category.cost_category_id === id
        );
        const res = await $put(`/api/masterdata/cost-category/${id}`, {
          sequence_number: currentCategory.sequence_number - 1,
        });

        const prevCategory = categoryList.find(
          (category) =>
            category.sequence_number === currentCategory.sequence_number - 1
        );
        const res2 = await $put(
          `/api/masterdata/cost-category/${prevCategory.cost_category_id}`,
          {
            sequence_number: currentCategory.sequence_number,
          }
        );

        getList();
        Swal.fire({
          icon: "success",
          title: "성공",
          text: "순서가 변경되었습니다.",
        });
      }
    });
  };

  const downSequence = async (id) => {
    // Swal 순서를 바꾸시겠습니까?
    Swal.fire({
      title: "순서를 바꾸시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // id를 찾아서 sequence_number가 1을 더해야 함
        const currentCategory = categoryList.find(
          (category) => category.cost_category_id === id
        );
        const res = await $put(`/api/masterdata/cost-category/${id}`, {
          sequence_number: currentCategory.sequence_number + 1,
        });

        const nextCategory = categoryList.find(
          (category) =>
            category.sequence_number === currentCategory.sequence_number + 1
        );
        const res2 = await $put(
          `/api/masterdata/cost-category/${nextCategory.cost_category_id}`,
          {
            sequence_number: currentCategory.sequence_number,
          }
        );

        getList();
        Swal.fire({
          icon: "success",
          title: "성공",
          text: "순서가 변경되었습니다.",
        });
      }
    });
  };

  // const paging = () => {
  //   const pages = [];

  //   const pageFirst =
  //     pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
  //   let pageLast = pageFirst + 4;

  //   if (pageLast > totalPage) pageLast = totalPage;
  //   setLastPage(pageLast);

  //   for (let i = pageFirst; i <= pageLast; i++) {
  //     pages.push(i);
  //   }
  //   setPageRange(pages);
  // };

  // const changePagination = async () => {
  //   getList(true);
  // };

  // const getList = async (isPaging) => {
  //   setLoading(true);
  //   const tempPageNo = isPaging ? pageNo : 1;
  //   const url = searchText
  //     ? `/api/masterdata/cost-category/${tempPageNo}/${pageLimit}?search=${encodeURIComponent(
  //         searchText
  //       )}`
  //     : `/api/masterdata/cost-category/${tempPageNo}/${pageLimit}`;
  //   const res = await $get(url);
  //   if (res.status === 200) {
  //     setCategoryList(res.data.costCategoryList);
  //     setTotalPage(Math.ceil(res.data.total_count / pageLimit));
  //     paging();
  //   }

  //   setLoading(false);
  // };

  const getList = async () => {
    const res = await $get(`/api/masterdata/cost-category/all`);
    if (res.status === 200) {
      setCategoryList(res.data);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }
  }, [userData]);

  // useEffect(() => {
  //   paging();
  // }, [totalPage]);

  // useEffect(() => {
  //   changePagination(pageNo);
  // }, [pageNo]);

  return (
    <section className="container pt-3">
      <div className="row">
        <MasterCostSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">비용 항목 관리</h1>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setActiveCostCategory(null);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                비용 항목 분류 추가
              </button>
            </div>
            {/* <div className="d-flex align-items-center gap-2 mb-4">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="비용 항목 분류를 입력하세요."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (e.nativeEvent.isComposing) {
                        return;
                      }

                      getList();
                    }
                  }}
                />
                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => getList()}
                >
                  <i className="fa fa-solid fa-search"></i>
                </button>
              </div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setActiveCostCategory(null);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-solid fa-plus me-2"></i>
                비용 항목 분류 추가
              </button>
            </div> */}
            <div className="table-responsive">
              <table className="table table-hover align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">비용 항목 분류</th>
                    {/* <th className="border-top-0">등록인</th> */}
                    <th className="border-top-0">등록일</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {categoryList.map((category, index) => (
                    <tr key={index}>
                      <td>{category.cost_category_name}</td>
                      {/* <td>{category.user}</td> */}
                      <td>{$dateTimezone(category.create_datetime)}</td>
                      <td>
                        {/* 수정 */}
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary me-2"
                          onClick={() => {
                            setActiveCostCategory(category);
                            setShowModal(true);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        {/* 삭제 */}
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger me-2"
                          onClick={() => {
                            deleteCategory(category.cost_category_id);
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                        {/* 순서 변경 */}
                        {index < categoryList.length - 1 && (
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-dark me-1"
                            onClick={() => {
                              downSequence(category.cost_category_id);
                            }}
                          >
                            <i className="fa-solid fa-chevron-down"></i>
                          </button>
                        )}
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-dark"
                            onClick={() => {
                              upSequence(category.cost_category_id);
                            }}
                          >
                            <i className="fa-solid fa-chevron-up"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <CostCategoryModal
          onClose={() => {
            setShowModal(false);
          }}
          onList={() => {
            getList();
            setActiveCostCategory(null);
            setShowModal(false);
          }}
          costCategory={activeCostCategory}
        />
      )}
    </section>
  );
}

export default CostCategory;
